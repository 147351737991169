import { useSelector, useDispatch } from "react-redux";

import { getToastMessage } from "../../redux/general/generalSelectors";
import generalActions from "../../redux/general/generalActions";

import styles from "./Toast.module.css";

const Toast = () => {
  const toastMessage = useSelector(getToastMessage);
  const dispatch = useDispatch();
  const { unsetToast } = generalActions;

  const onClose = () => {
    dispatch(unsetToast());
  };

  return (
    <div
      className={`${styles.toastContainer} ${
        toastMessage.shouldRender ? styles.activeToastContainer : ""
      }`}
    >
      <div className={styles.toast}>
        <div className={styles.closure} onClick={onClose} />
        <div className={styles.header}>{toastMessage.header}</div>
        <div className={styles.body}>{toastMessage.body}</div>
      </div>
    </div>
  );
};

export default Toast;
