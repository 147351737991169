import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation signupUser($user: SignupInput!) {
    signup(in: $user) {
      accessToken
      user {
        id
        first_name
        last_name
        email
        locale
        bikes {
          id
          data
          boughtAt
          batteryNumber
          frameNumber
        }
        insurances {
          id
        }
      }
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation createAddress($in: AddressInput!) {
    createAddress(in: $in) {
      id
      streetname
      housenumber
      postcode
      city
      country
    }
  }
`;

export const SET_USER_ADDRESS = gql`
  mutation setUserAddress($user_id: ID!, $address_id: ID!) {
    setUserAddress(user_id: $user_id, address_id: $address_id)
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($id: ID!, $in: AddressInput!) {
    updateAddress(id: $id, in: $in) {
      id
      streetname
      housenumber
      postcode
      city
      country
    }
  }
`;

export const CREATE_BIKE = gql`
  mutation createBike($bike: BikeInput!) {
    createBike(bike: $bike) {
      id
      data
      coverImage {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
        isUserUploaded
      }
      images {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
        isUserUploaded
      }
      bikePassInvoice {
        id
        name
        readUrl
        uploadedAt
      }
      dealer {
        id
        name
        email
        phone
        website
        address {
          id
          streetname
          housenumber
          postcode
          city
          country
        }
        location
        mapsLink
        openingHours
        socialMedia
        isUserDefined
        isOnlineOnly
        metadata
      }
      insurance {
        id
        startDate
        expiryDate
        policyId
        documentUrl
        provider
        otherProviderName
        policyName
        quoteId
        paymentAmountInCents
        paymentFrequency
      }
      nextCheckBookDueDate
    }
  }
`;

export const CREATE_CHECKBOOK = gql`
  mutation createCheckBook($data: CreateCheckBookInput!) {
    createCheckBook(in: $data) {
      id
      bikeID
      eventType
      date
      notes
      documentID
      dealerID
      nextDue
    }
  }
`;

export const UPDATE_CHECKBOOK = gql`
  mutation updateCheckBook($id: ID!, $in: UpdateCheckBookInput!) {
    updateCheckBook(id: $id, in: $in) {
      id
      bikeID
      eventType
      date
      notes
      documentID
      dealerID
      nextDue
    }
  }
`;

export const LOGIN = gql`
  mutation login($credentials: LoginInput!) {
    login(in: $credentials) {
      accessToken
      user {
        id
        email
        permission
        first_name
        last_name
        date_of_birth
        title
        nationality
        locale
        phone
        bikes {
          id
          data
          boughtAt
          batteryNumber
          frameNumber
          coverImage {
            documentId
            originalReadUrl
            webReadUrl
            thumbnailReadUrl
            isUserUploaded
          }
          images {
            documentId
            originalReadUrl
            webReadUrl
            thumbnailReadUrl
            isUserUploaded
          }
          bikePassInvoice {
            id
            name
            readUrl
            uploadedAt
          }
          dealer {
            id
            name
            email
            alias
            phone
            website
            address {
              id
              streetname
              housenumber
              postcode
              city
              country
            }
            location
            mapsLink
            openingHours
            socialMedia
            isUserDefined
            isOnlineOnly
            metadata
          }
          insurance {
            id
            startDate
            expiryDate
            policyId
            documentUrl
            provider
            otherProviderName
            policyName
            quoteId
            paymentAmountInCents
            paymentFrequency
          }
          nextCheckBookDueDate
        }
        address {
          id
          streetname
          housenumber
          postcode
          city
          country
        }
        metadata
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $user: UpdateUserInput!) {
    updateUser(id: $id, in: $user) {
      id
      email
      permission
      first_name
      last_name
      date_of_birth

      title
      nationality
      locale
      phone
      bikes {
        id
        data
        boughtAt
        batteryNumber
        frameNumber
        coverImage {
          documentId
          originalReadUrl
          webReadUrl
          thumbnailReadUrl
          isUserUploaded
        }
        images {
          documentId
          originalReadUrl
          webReadUrl
          thumbnailReadUrl
          isUserUploaded
        }
        bikePassInvoice {
          id
          name
          readUrl
          uploadedAt
        }
        dealer {
          id
          name
          email
          alias
          phone
          website
          address {
            id
            streetname
            housenumber
            postcode
            city
            country
          }
          location
          mapsLink
          openingHours
          socialMedia
          isUserDefined
          isOnlineOnly
          metadata
        }
        insurance {
          id
          startDate
          expiryDate
          policyId
          documentUrl
          provider
          otherProviderName
          policyName
          quoteId
          paymentAmountInCents
          paymentFrequency
        }
        nextCheckBookDueDate
      }
      address {
        id
        streetname
        housenumber
        postcode
        city
        country
      }
      metadata
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation sendForgotPasswordEmail($email: String!) {
    sendForgotPasswordEmail(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation forgotPassword($linkId: ID!, $newPassword: String!) {
    forgotPassword(linkId: $linkId, newPassword: $newPassword) {
      accessToken
      user {
        id
        email
        permission
        first_name
        last_name
        date_of_birth
        title
        nationality
        locale
        phone
        bikes {
          id
          data
          boughtAt
          batteryNumber
          frameNumber
          coverImage {
            documentId
            originalReadUrl
            webReadUrl
            thumbnailReadUrl
            isUserUploaded
          }
          images {
            documentId
            originalReadUrl
            webReadUrl
            thumbnailReadUrl
            isUserUploaded
          }
          bikePassInvoice {
            id
            name
            readUrl
            uploadedAt
          }
          dealer {
            id
            name
            email
            alias
            phone
            website
            address {
              id
              streetname
              housenumber
              postcode
              city
              country
            }
            location
            mapsLink
            openingHours
            socialMedia
            isUserDefined
            isOnlineOnly
            metadata
          }
          insurance {
            id
            startDate
            expiryDate
            policyId
            documentUrl
            provider
            otherProviderName
            policyName
            quoteId
            paymentAmountInCents
            paymentFrequency
          }
          nextCheckBookDueDate
        }
        address {
          id
          streetname
          housenumber
          postcode
          city
          country
        }
        metadata
      }
    }
  }
`;

export const UPDATE_BIKE = gql`
  mutation updateBike($id: ID!, $bike: UpdateBikeInput!) {
    updateBike(id: $id, bike: $bike) {
      id
      data
      boughtAt
      batteryNumber
      frameNumber
      coverImage {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
        isUserUploaded
      }
      images {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
        isUserUploaded
      }
      bikePassInvoice {
        id
        name
        readUrl
        uploadedAt
      }
      dealer {
        id
        name
        email
        alias
        phone
        website
        address {
          id
          streetname
          housenumber
          postcode
          city
          country
        }
        location
        mapsLink
        openingHours
        socialMedia
        isUserDefined
        isOnlineOnly
        metadata
      }
      insurance {
        id
        startDate
        expiryDate
        policyId
        documentUrl
        provider
        otherProviderName
        policyName
        quoteId
        paymentAmountInCents
        paymentFrequency
      }
      nextCheckBookDueDate
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadBikeImage($id: ID!, $uploadFile: Upload!) {
    addBikeImage(id: $id, upload: $uploadFile) {
      id
      data
      boughtAt
      batteryNumber
      frameNumber
      coverImage {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
      }
      images {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
      }
      dealer {
        id
        name
        email
        alias
        phone
        website
        address {
          id
          streetname
          housenumber
          postcode
          city
          country
        }
        location
        mapsLink
        openingHours
        socialMedia
        isUserDefined
        isOnlineOnly
        metadata
      }
      insurance {
        id
        startDate
        expiryDate
        policyId
        documentUrl
        provider
        otherProviderName
        policyName
        quoteId
        paymentAmountInCents
        paymentFrequency
      }
      nextCheckBookDueDate
    }
  }
`;

export const UPLOAD_INVOICE = gql`
  mutation uploadBikePassInvoice($in: uploadBikeInvoiceInput!) {
    uploadBikePassInvoice(in: $in) {
      id
      name
      readUrl
      uploadedAt
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation removeBikePassInvoice($bikeID: ID!, $docID: ID!) {
    removeBikePassInvoice(bikeID: $bikeID, docID: $docID)
  }
`;

export const EXPORT_BIKE_PASS_PDF = gql`
  mutation exportBikePassPDF($in: BikePassInfoInput!) {
    exportBikePassPDF(in: $in) {
      name
      Content
      uploadedAt
    }
  }
`;

export const REMOVE_IMAGE = gql`
  mutation removeBikeImage($id: ID!, $bikeId: ID!) {
    deleteBikeImage(documentId: $id, bikeID: $bikeId) {
      id
      data
      boughtAt
      coverImage {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
      }
      images {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
      }
      dealer {
        id
        name
        email
        alias
        phone
        website
        address {
          id
          streetname
          housenumber
          postcode
          city
          country
        }
        location
        mapsLink
        openingHours
        socialMedia
        isUserDefined
        isOnlineOnly
        metadata
      }
      insurance {
        id
        startDate
        expiryDate
        policyId
        documentUrl
        provider
        otherProviderName
        policyName
        quoteId
        paymentAmountInCents
        paymentFrequency
      }
      nextCheckBookDueDate
    }
  }
`;

export const ADD_EMAIL_CIRCULAR_NEWSLETTER = gql`
  mutation addEmailToCircularNewsletter($email: String!) {
    addEmailToCircularNewsletter(email: $email)
  }
`;
