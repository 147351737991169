import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { Helmet } from "react-helmet";

import FormLabel from "../../../components/FormLabel/FormLabel";
import ButtonWithoutProgressBar from "../../../components/ButtonWithoutProgressBar/ButtonWithoutProgressBar";
import DesktopView from "../../../components/DesktopView";
import insuranceActions from "../../../redux/insurance/insuranceActions";
import { useMixpanel } from "../../../utils/mixpanel";
import {
  getUserFirstName,
  getUserLastName,
  getUserEmail,
} from "../../../redux/auth/authSelectors";
import bikeActions from "../../../redux/bike/bikeActions";
import { getStepInsurance } from "../../../redux/bike/bikeSelectors";
import { validationCustomer } from "../../../services/apiRequest";
import normalizeDateForBE from "../../../assets/helpers/normalizeDateForBE";
import { errorHandlerBE } from "../../../assets/helpers/errorHandlerBE";
import { INSURANCE_PAYMENT, WELCOME } from "../../../constants/routes";
import InsuranceInterface from "../components/InsuranceInterface";

import styles from "./InsuranceProfile.module.css";

const InsuranceProfile = () => {
  const { t } = useTranslation();
  const [birthDate, setBirthDate] = useState("");
  const [dateErrorMessage, setDateErrorMessage] = useState("");
  const { addInsuranceData } = insuranceActions;
  const dispatch = useDispatch();
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userEmail = useSelector(getUserEmail);
  const [validationError, setValidationError] = useState("");
  const { firstStepComplete } = useSelector(getStepInsurance);
  const navigate = useNavigate();
  const mixpanel = useMixpanel();

  useEffect(() => {
    !firstStepComplete && navigate(WELCOME);
  });

  const nextPage = () => {
    toast.dismiss();
    dispatch(
      bikeActions.addStepInsurance({
        secondStepComplete: true,
      })
    );
    navigate(INSURANCE_PAYMENT);
  };

  const maxDate = new Date();

  const schema = yup.object().shape({
    city: yup
      .string()
      .required(t("onboarding.legacy.errorRequiredCity"))
      .matches(/^([^0-9]*)$/, t("onboarding.legacy.errorNoNumbers")),
    houseNumber: yup
      .string()
      .required(t("onboarding.legacy.errorRequiredHouseNumber")),
    postalCode: yup
      .string()
      .min(5, t("onboarding.legacy.errorPostalCode"))
      .max(5, t("onboarding.legacy.errorPostalCode"))
      .required("onboarding.errorRequiredPostalCode"),
    street: yup.string().required(t("onboarding.legacy.errorRequiredStreet")),
    firstName: yup
      .string()
      .required(t("onboarding.legacy.errorRequiredFirstName"))
      .min(3, t("onboarding.legacy.errorMin3")),
    lastName: yup
      .string()
      .required(t("onboarding.legacy.errorRequiredLastName"))
      .min(3, t("onboarding.legacy.errorMin3")),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      city: "",
      houseNumber: "",
      postalCode: "",
      street: "",
      firstName: userFirstName,
      lastName: userLastName,
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleChange = (date, e) => {
    e.preventDefault();
    setBirthDate(date);
    setDateErrorMessage("");
  };

  const onSubmit = async (submitData) => {
    await validationCustomer({
      firstName: submitData.firstName,
      lastName: submitData.lastName,
      gender: "unknown",
      street_name: submitData.street,
      street_number: submitData.houseNumber,
      postcode: submitData.postalCode,
      city: submitData.city,
      country: "Germany",
      nationality: "DE",
      email: userEmail,
      date_of_birth: normalizeDateForBE(birthDate),
    }).then((data) => {
      if (data?.status === 400) {
        data.json().then((value) => {
          errorHandlerBE(value, toast, t);
        });
      }
      if (data.status === 200) {
        mixpanel.track("onboarding_profile_added", {
          platform: "frontend",
        });
        mixpanel.people.set({
          user_age: new Date().getFullYear() - birthDate.getFullYear(),
        });
        dispatch(
          addInsuranceData({
            birthDate,
            street: submitData.street,
            firstName: submitData.firstName,
            lastName: submitData.lastName,
            houseNumber: submitData.houseNumber,
            postalCode: submitData.postalCode,
            city: submitData.city,
          })
        );
        nextPage();
      } else {
        setValidationError(t("onboarding.legacy.validationError"));
      }
    });

    !birthDate &&
      setDateErrorMessage(t("onboarding.legacy.errorRequiredBirthDate"));
  };

  return (
    <DesktopView>
      <Helmet titleTemplate="%s">
        <title>{t("insuranceProfilePageTitle")}</title>
        <meta name="theme-color" content="#f0f2f5" />
      </Helmet>
      <InsuranceInterface page="profile" />
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.wrapper} ${styles.nameWrapper}`}>
          <FormLabel className={styles.label}>
            {t("onboarding.legacy.firstName")}
            <input
              className={styles.inputName}
              type="text"
              name="firstName"
              id="firstName"
              {...register("firstName")}
            />
            {errors?.firstName && (
              <p className={styles.errorMessage}>{errors.firstName.message}</p>
            )}
          </FormLabel>
          <FormLabel className={styles.labelLastName}>
            {t("onboarding.legacy.lastName")}
            <input
              className={styles.inputName}
              type="text"
              name="lastName"
              id="lastName"
              {...register("lastName")}
            />
            {errors?.lastName && (
              <p className={styles.errorMessage}>{errors.lastName.message}</p>
            )}
          </FormLabel>
        </div>
        <FormLabel className={styles.labelDatePicker}>
          {t("onboarding.legacy.birthday")}
          <DatePicker
            selected={birthDate && new Date(birthDate)}
            onChange={(date, event) => handleChange(date, event)}
            className={styles.inputLarge}
            dateFormat="dd.MM.yyyy"
            placeholderText={t("datePlaceholder")}
            maxDate={maxDate.setDate(maxDate.getDate() - 6574)}
            withPortal
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            onFocus={(e) => e.target.blur()}
          />
        </FormLabel>
        {dateErrorMessage && (
          <p className={styles.errorMessage}>{dateErrorMessage}</p>
        )}
        <div className={styles.wrapper}>
          <FormLabel className={styles.label}>
            {t("onboarding.legacy.street")}
            <input
              className={styles.inputName}
              type="text"
              name="street"
              id="street"
              {...register("street")}
            />
            {errors?.street && (
              <p className={styles.errorMessage}>{errors.street.message}</p>
            )}
          </FormLabel>
          <FormLabel className={styles.labelLastName}>
            {t("onboarding.legacy.houseNumber")}
            <input
              className={styles.inputName}
              type="text"
              name="houseNumber"
              id="houseNumber"
              {...register("houseNumber")}
            />
            {errors?.houseNumber && (
              <p className={styles.errorMessage}>
                {errors.houseNumber.message}
              </p>
            )}
          </FormLabel>
        </div>
        <div className={styles.wrapper}>
          <FormLabel className={styles.label}>
            {t("onboarding.legacy.postalCode")}
            <input
              className={styles.inputName}
              type="number"
              name="postalCode"
              id="postalCode"
              {...register("postalCode")}
            />
            {errors?.postalCode && (
              <p className={styles.errorMessage}>{errors.postalCode.message}</p>
            )}
          </FormLabel>
          <FormLabel className={styles.labelLastName}>
            {t("onboarding.legacy.city")}
            <input
              className={styles.inputName}
              type="text"
              name="city"
              id="city"
              {...register("city")}
            />
            {errors?.city && (
              <p className={styles.errorMessage}>{errors.city.message}</p>
            )}
          </FormLabel>
        </div>
        {validationError && (
          <p className={styles.errorMessage}>{validationError}</p>
        )}
        <ButtonWithoutProgressBar
          value={t("onboarding.insuranceBikeDetails.continue")}
        />
      </form>
      <Toaster
        position="top-center"
        toastOptions={{
          className: styles.toast,
          duration: Infinity,
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ message }) => (
              <>
                {t.type !== "loading" && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className={styles.toastButton}
                  ></button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </DesktopView>
  );
};

export default InsuranceProfile;
