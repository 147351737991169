import { useEffect, useMemo } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./ImagesCarousel.css";
import styles from "./ImagesCarousel.module.css";

const ImagesCarousel = ({ images, imitialPhotoId, onClose }) => {
  useEffect(() => {
    document.body.style.position = "fixed";
    return () => {
      document.body.style.position = "";
    };
  });

  const initialImageIndex = useMemo(() => {
    return (
      imitialPhotoId &&
      images.findIndex((image) => image.documentId === imitialPhotoId)
    );
  }, [images, imitialPhotoId]);

  const onKeyDown = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.close} onClick={onClose} />
      <Carousel
        className={styles.carousel}
        thumbWidth={48}
        selectedItem={initialImageIndex}
      >
        {images.map((image) => (
          <div key={image.documentId} className={styles.itemWrapper}>
            <img
              src={image.webReadUrl}
              alt="Thumbnail preview"
              className="hiddenImage"
            />
            <img
              src={image.originalReadUrl}
              className={styles.carouseImage}
              alt="Bike"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImagesCarousel;
