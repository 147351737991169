import AsideImg from "../../assets/images/onboardingPhoto.png";

import styles from "./DesktopView.module.css";

export default function DesktopView({
  children,
  img = AsideImg,
  asideClassName,
}) {
  return (
    <div className={styles.desktop}>
      <div className={styles.page}>
        <div />
        <div className={styles.wrapper}>{children}</div>
        <div />
      </div>
      <div
        className={`${styles.aside} ${asideClassName ? asideClassName : ""}`}
      >
        <img className={styles.asideImg} src={img} alt="aside" />
      </div>
    </div>
  );
}
