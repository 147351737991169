export const getUser = (state) => state.auth.user;
export const getUserId = (state) => state.auth.user?.id;
export const getUserFirstName = (state) => state?.auth?.user?.first_name;
export const getUserLastName = (state) => state?.auth?.user?.last_name;
export const getUserEmail = (state) => state?.auth?.user?.email;
export const getBikes = (state) =>
  state.auth.user?.bikes?.map((bike) => ({
    ...JSON.parse(bike.data),
    id: bike.id,
  }));
export const getRedirectLink = (state) => state.auth.redirectLink;
export const getIsInInsuranceProcess = (state) =>
  state.auth.isInInsuranceProcess;
  export const getIsSignedPreviously = (state) => state.auth.isSignedPreviously;
