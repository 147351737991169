import Select from 'react-select';
import React from 'react';

import styles from './Select.module.css';

const selectStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#5E7387',
  }),
  control: (provided) => ({
    ...provided,
    position: 'relative',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#b5bfcc',
    padding: '5px',
    height: '48px',
    background: '#FFFFFF',
    boxShadow: '0px 6px 12px -2px rgba(0, 0, 0, 0.1)',
    borderRadius: '48px',
    border: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#b5bfcc',
  }),
};

const DropdownIndicator = () => {
  return <div className={styles.dropdownIndicator}></div>;
};

const SelectInput = ({value, onChange, options, placeholder}) => {
  return (
    <Select
      styles={selectStyles}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      components={{DropdownIndicator}}
    />
  );
};
export default SelectInput;
