import styles from "./Spinner.module.css";

const Spinner = ({ color, width = 100 }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.spinnerWrapper}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={width} stroke={color} speed="1" role="status" class={styles.spinner}>
          <polyline points="24,12 24,4" width="3" transform="rotate(0, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(30, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(60, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(90, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(120, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(150, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(180, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(210, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(240, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(270, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(300, 24, 24)" class={styles.spinnerLine}></polyline>
          <polyline points="24,12 24,4" width="3" transform="rotate(330, 24, 24)" class={styles.spinnerLine}></polyline>
        </svg>
      </div>
    </div>
  );
};

export default Spinner;
