const sortStringsByFirstLetter = (array) => {
  const newArray = [];
  array.forEach((item) => {
    const existingSection = newArray.find(
      (section) => section.title[0] === item.name?.[0] || item[0]
    );
    if (!!existingSection) {
      existingSection.items.push(item);
    } else {
      newArray.push({ title: item.name?.[0] || item[0], items: [item] });
    }
  });
  return newArray;
};

export default sortStringsByFirstLetter;
