import { useTranslation } from "react-i18next";

const useBikeCategories = () => {
  const { t } = useTranslation();

  return [
    {
      value: "Mountainbike",
      label: t("myBike.mountainbike"),
      subcategories: [
        { value: "Fully", label: t("myBike.fully") },
        { value: "Hardtail", label: t("myBike.hardtail") },
      ],
    },
    {
      value: "Rennrad",
      label: t("myBike.rennrad"),
      subcategories: [
        { value: "Straßenrad", label: t("myBike.straßenrad") },
        { value: "Triathlonräder", label: t("myBike.triathlonräder") },
        { value: "Gravel", label: t("myBike.gravel") },
        {
          value: "Special Female Frame",
          label: t("myBike.specialFemaleFrame"),
        },
      ],
    },
    {
      value: "City Bike",
      label: t("myBike.cityBike"),
      subcategories: [
        { value: "Hollandrad", label: t("myBike.hollandrad") },
        { value: "Singlespeed", label: t("myBike.singlespeed") },
        { value: "Urban Bike", label: t("myBike.urbanBike") },
        { value: "Faltrad", label: t("myBike.faltrad") },
        { value: "Tiefeinsteiger", label: t("myBike.tiefeinsteiger") },
      ],
    },
    {
      value: "Trekkingrad",
      label: t("myBike.trekkingrad"),
      subcategories: [
        { value: "Trekkingrad", label: t("myBike.trekkingrad") },
        { value: "Crossrad", label: t("myBike.crossrad") },
        { value: "Reiserad", label: t("myBike.reiserad") },
      ],
    },
    {
      value: "Special Bike",
      label: t("myBike.specialBike"),
      subcategories: [
        { value: "BMX", label: t("myBike.BMX") },
        { value: "Tandem", label: t("myBike.tandem") },
        { value: "Cargo", label: t("myBike.cargo") },
        { value: "Beachcruiser", label: t("myBike.beachcruiser") },
        { value: "Fatbike", label: t("myBike.fatbike") },
      ],
    },
    {
      value: "kinderfahrrad",
      label: t("myBike.kinderfahrrad"),
      subcategories: [
        { value: "Kinderfahrrad", label: t("myBike.kinderfahrrad") },
        { value: "Kinder Mountainbike", label: t("myBike.kinderMountainbike") },
      ],
    },
  ];
};

export default useBikeCategories;
