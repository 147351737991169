import { useTranslation } from "react-i18next";

const useDescriptionConfig = (bikeData, additional) => {
  const { t } = useTranslation();

  return {
    title: t("myBike.description"),
    editPageName: "edit-description",
    data: [
      {
        fieldName: "description",
        title: "",
        value: "bike_description",
        shouldRender: true,
        inputType: "textarea",
      },
    ],
  };
};

export default useDescriptionConfig;
