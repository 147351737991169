import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Tooltip from "../../../../components/Tooltip";

import pagesStyles from "./pagesStyles";

import styles from "./InsuranceInterface.module.css";

const InsuranceInterface = ({ page, isBackButton, isTags }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.insuranceInterface}>
      <Helmet>
        <title>{t("insurancePageTitle")}</title>
        <meta name="theme-color" content="#f0f2f5" />
      </Helmet>
      {isBackButton && (
        <div className={styles.backButton} onClick={onBack}></div>
      )}
      <h2 className={styles.mainPageTitle}>
        {t("onboarding.insurance.interfaceTitle")}
      </h2>
      {page && (
        <>
          <div className={styles.wrapper}>
            {pagesStyles[page].map((item, index) => (
              <span key={index + item} className={item}></span>
            ))}
          </div>
          <ul className={styles.list}>
            <li className={styles.firstItem}>
              <p className={styles.text}>{t("onboarding.insurance.tariff")}</p>
            </li>
            <li className={styles.secondItem}>
              <p className={page === "bike" ? styles.activeText : styles.text}>
                {t("onboarding.insurance.bicycle")}
              </p>
            </li>
            <li className={styles.thirdItem}>
              <p
                className={page === "profile" ? styles.activeText : styles.text}
              >
                {t("onboarding.insurance.profile")}
              </p>
            </li>
            <li className={styles.fourthItem}>
              <p
                className={page === "payment" ? styles.activeText : styles.text}
              >
                {t("onboarding.insurance.payment")}
              </p>
            </li>
            <li>
              <p
                className={
                  page === "overview" ? styles.activeText : styles.text
                }
              >
                {t("onboarding.insurance.overview")}
              </p>
            </li>
          </ul>
        </>
      )}
      {isTags && (
        <div className={styles.offerWrapper}>
          <span className={styles.offerText}>
            {t("onboarding.legacy.noDeductible")}
          </span>
          <span className={styles.offerText}>
            {t("onboarding.legacy.cancellableDaily")}
            <Tooltip
              text={t("onboarding.legacy.cancellableDailyHint")}
              className={styles.tooltip}
            />
          </span>
        </div>
      )}
    </div>
  );
};
export default InsuranceInterface;
