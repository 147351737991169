import getFieldValue from "./getFieldValue";

const getStateFromConfig = (config, bikeData) =>
  config.reduce(
    (obj, item) =>
      item.isSubtitle
        ? obj
        : {
            ...obj,
            [item.fieldName]: getFieldValue(item.value, bikeData) || "",
          },
    {}
  );

export default getStateFromConfig;
