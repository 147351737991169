import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import insuranceActions from "./insuranceActions";

import { loadState } from "../storeSerializer";

const previousData = loadState("insurance");

const initialState = {
  data: {
    frameNumber: "",
    gender: "male",
    birthDate: "",
    street: "",
    houseNumber: "",
    mobileNumber: "",
    postalCode: "",
    city: "",
    IBAN: "",
    bankName: "",
    startDateInsurance: "",
    agreement: false,
    insuranceRate: [],
    selectedInsuranceRate: {},
    voucherValue: "",
    policy: {},
  },
  isLoading: false,
  ...previousData,
};

const data = createReducer(initialState.data, {
  [insuranceActions.addInsuranceData]: (state, { payload }) => {
    return { ...state, ...payload };
  },
});

const isLoading = createReducer(initialState.isLoading, {
  [insuranceActions.addInsuranceData]: () => false,
});

export default combineReducers({
  data,
  isLoading,
});
