export default function getBikeShedURIFromHost() {
  let protocol = "https";
  let apiUrl = document.location.host;
  if (process.env.REACT_APP_PROTOCOL) {
    protocol = process.env.REACT_APP_PROTOCOL;
  }
  if (process.env.REACT_APP_API_URL) {
    apiUrl = process.env.REACT_APP_API_URL;
  } else if (document.URL.includes("localhost")) {
    return `${window.location.origin}/garage/api/query`;
  }
  return `${protocol}://${apiUrl}/bikeshed/api/document`;
}
