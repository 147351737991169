import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      email
      permission
      first_name
      last_name
      date_of_birth
      title
      nationality
      locale
      phone
      bikes {
        id
        data
        boughtAt
        batteryNumber
        frameNumber
        coverImage {
          documentId
          originalReadUrl
          webReadUrl
          thumbnailReadUrl
          isUserUploaded
        }
        images {
          documentId
          originalReadUrl
          webReadUrl
          thumbnailReadUrl
          isUserUploaded
        }
        bikePassInvoice {
          id
          name
          readUrl
          uploadedAt
        }
        dealer {
          id
          name
          email
          alias
          phone
          website
          address {
            id
            streetname
            housenumber
            postcode
            city
            country
          }
          location
          mapsLink
          openingHours
          socialMedia
          isUserDefined
          isOnlineOnly
          metadata
        }
        insurance {
          id
          startDate
          expiryDate
          policyId
          documentUrl
          provider
          otherProviderName
          policyName
          quoteId
          paymentAmountInCents
          paymentFrequency
        }
        nextCheckBookDueDate
      }
      address {
        id
        streetname
        housenumber
        postcode
        city
        country
      }
      metadata
    }
  }
`;

export const GET_BIKE = gql`
  query getBike($id: ID!) {
    bike(id: $id) {
      id
      data
      coverImage {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
        isUserUploaded
      }
      images {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
        isUserUploaded
      }
      bikePassInvoice {
        id
        name
        readUrl
        uploadedAt
      }
      dealer {
        id
        name
        email
        alias
        phone
        website
        address {
          id
          streetname
          housenumber
          postcode
          city
          country
        }
        location
        mapsLink
        openingHours
        socialMedia
        isUserDefined
        isOnlineOnly
        metadata
      }
      insurance {
        id
        startDate
        expiryDate
        policyId
        documentUrl
        provider
        otherProviderName
        policyName
        quoteId
        paymentAmountInCents
        paymentFrequency
      }
      nextCheckBookDueDate
    }
  }
`;

export const GET_FILE = gql`
  query getFile($id: ID!) {
    file(id: $id) {
      id
      name
      readUrl
    }
  }
`;

export const GET_ANALYTICS_TOKEN = gql`
  query {
    analyticsToken
  }
`;

export const GET_ALL_CHECKBOOK = gql`
  query getCheckbooks($bike_id: ID!, $limit: Int) {
    checkBooksForBike(bike_id: $bike_id, limit: $limit) {
      id
      bikeID
      eventType
      date
      notes
      documentID
      dealerID
      nextDue
    }
  }
`;

export const GET_CHECKBOOK = gql`
  query getCheckBook($id: ID!) {
    checkBook(id: $id) {
      id
      bikeID
      eventType
      date
      notes
      documentID
      dealerID
      nextDue
    }
  }
`;

export const GET_NEXT_DUE_DATE = gql`
  query getNextDueDate($bike_id: ID!) {
    checkBookNextDueDate(bike_id: $bike_id)
  }
`;

export const GET_BIKE_MANUFACTURERS = gql`
  query getBikeManufacturers($filter: bikeManufacturersFilter) {
    bikeManufacturers(filter: $filter)
  }
`;

export const GET_BIKE_MODELS = gql`
  query getBikeModels(
    $manufactureName: String!
    $filter: bikeManufacturerModelsFilter
  ) {
    bikeManufacturerModels(manufactureName: $manufactureName, filter: $filter)
  }
`;

export const GET_BIKE_MODELS_WITH_IMAGES = gql`
  query getBikeModelsWithImages(
    $manufactureName: String!
    $filter: bikeManufacturerModelsFilter
  ) {
    bikeManufacturerModelsWithImages(
      manufactureName: $manufactureName
      filter: $filter
    ) {
      name
      image {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
        isUserUploaded
        source
      }
    }
  }
`;

export const GET_BIKE_SHRINK_MODELS_WITH_IMAGES = gql`
  query bikeManufacturerShrinkModelsWithImages(
    $manufactureName: String!
    $filter: bikeManufacturerModelsFilter
  ) {
    bikeManufacturerShrinkModelsWithImages(
      manufactureName: $manufactureName
      filter: $filter
    ) {
      name
      image {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
        isUserUploaded
        source
      }
    }
  }
`;

export const GET_BIKE_TEMPLATE = gql`
  query getBikeTemplate($manufacturer: String!, $extended_model: String!) {
    bikeTemplateByManufacturerAndModel(
      manufacturer: $manufacturer
      extended_model: $extended_model
    ) {
      id
      bikeData
      version
      manufacturer
      model
      extended_model
      images {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
      }
    }
  }
`;

export const GET_BIKE_SHRINK_TEMPLATE = gql`
  query getBikeTemplateByShrinkModel(
    $in: BikeTemplateByManufacturerAndShrinkModelInput!
  ) {
    bikeTemplateByManufacturerAndShrinkModel(in: $in) {
      id
      bikeData
      version
      manufacturer
      model
      extended_model
      images {
        documentId
        originalReadUrl
        webReadUrl
        thumbnailReadUrl
      }
    }
  }
`;

export const GET_DEALER = gql`
  query getDealer($dealerRequest: DealerRequest!) {
    dealer(req: $dealerRequest) {
      id
      name
    }
  }
`;

export const GET_INSURANCE_DOCUMENTS = gql`
  query insuranceDocuments($bike_id: ID!) {
    insuranceDocuments(bike_id: $bike_id) {
      docurl
    }
  }
`;

export const GET_YEARS = gql`
  query bikeYears($filter: BikeYearsFilter!) {
    bikeYears(filter: $filter)
  }
`;

export const GET_COLORS = gql`
  query bikeColors($filter: BikeColorsFilter!) {
    bikeColors(filter: $filter)
  }
`;

export const BIKE_TEMPLATES = gql`
  query bikeTemplates($in: BikeTemplatesInput!) {
    bikeTemplates(in: $in) {
      list {
        id
        bikeData
        version
        manufacturer
        model
        extended_model
        images {
          documentId
          originalReadUrl
          webReadUrl
          thumbnailReadUrl
          isUserUploaded
          source
        }
      }
    }
  }
`;
