import { useTranslation } from "react-i18next";

const useSpecialSpecsConfig = (bikeData, additional) => {
  const { t } = useTranslation();

  return {
    title: t("myBike.specialSpecs"),
    editPageName: "edit-special-specs",
    data: [
      { isSubtitle: true, title: t("myBike.engine") },
      {
        fieldName: "enginePerformance",
        title: t("myBike.enginePerformance"),
        value: "engine_performance",
        shouldRender: true,
      },
      {
        fieldName: "engineBrand",
        title: t("myBike.engineBrand"),
        value: "engine_brand",
        shouldRender: true,
      },
      {
        fieldName: "engineModel",
        title: t("myBike.engineModel"),
        value: "engine_model",
        shouldRender: true,
      },
      {
        fieldName: "engineType",
        title: t("myBike.engineType"),
        value: "engine_type",
        shouldRender: true,
      },
      {
        fieldName: "engineDescription",
        title: t("myBike.engineDescription"),
        value: "engine_description",
        shouldRender: bikeData?.engine_description,
      },
      { isSubtitle: true, title: t("myBike.battery") },
      {
        fieldName: "batteryReach",
        title: t("myBike.batteryReach"),
        value: "battery_reach",
        shouldRender: true,
      },
      {
        fieldName: "batteryModel",
        title: t("myBike.batteryModel"),
        value: "battery_model",
        shouldRender: true,
      },
      {
        fieldName: "batteryType",
        title: t("myBike.batteryType"),
        value: "battery_type",
        shouldRender: true,
      },
      {
        fieldName: "batteryEnergyStorage",
        title: t("myBike.batteryEnergyStorage"),
        value: "battery_energy_storage",
        shouldRender: true,
      },
      {
        fieldName: "batteryMounting",
        title: t("myBike.batteryMounting"),
        value: "battery_mounting",
        shouldRender: true,
      },
      {
        fieldName: "batteryDescription",
        title: t("myBike.batteryDescription"),
        value: "battery_description",
        shouldRender: bikeData?.battery_description,
      },
      {
        fieldName: "batteryCharger",
        title: t("myBike.charger"),
        value: "charger",
        shouldRender: true,
      },
      { isSubtitle: true, title: t("myBike.display") },
      {
        fieldName: "display",
        title: t("myBike.display"),
        value: "display",
        shouldRender: true,
      },
    ],
  };
};

export default useSpecialSpecsConfig;
