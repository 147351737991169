import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { INSURANCE_GARAGE, MY_BIKE, PHOTOS } from "../../../constants/routes";
import Sharing from "../../../components/Sharing/Sharing";

import styles from "./ActionsPanel.module.css";

const ActionsPanel = ({ bike }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirectToDetails = () => {
    navigate(`${MY_BIKE}/${bike?.id}`);
  };

  const redirectToPhoto = () => {
    navigate(`${MY_BIKE}/${bike?.id}${PHOTOS}`);
  };

  const redirectToInsurance = () => {
    navigate(INSURANCE_GARAGE);
  };

  return (
    <div className={styles.actionsPanel}>
      <div className={styles.action} onClick={redirectToDetails}>
        <div className={`${styles.actionIcon} ${styles.actionDetails}`}></div>
        <div className={styles.actionText}>{t("garage.details")}</div>
      </div>
      <div className={styles.action} onClick={redirectToPhoto}>
        <div className={`${styles.actionIcon} ${styles.actionPhoto}`}></div>
        <div className={styles.actionText}>{t("garage.photo")}</div>
      </div>
      <Sharing
        containerClassName={styles.actionContainer}
        buttonClassName={styles.action}
        title={t("myBike.shareBike")}
        text={t("myBike.shareBikeDescription")}
        url={`${window.location.href
          .split("/")
          .slice(0, -1)
          .join("/")}${MY_BIKE}/${bike?.id}`}
      >
        <div className={`${styles.actionIcon} ${styles.actionShare}`}></div>
        <div className={styles.actionText}>{t("garage.split")}</div>
      </Sharing>
      {!!bike?.insurance && (
        <div className={styles.action} onClick={redirectToInsurance}>
          <div className={`${styles.actionIcon} ${styles.actionDamage}`}></div>
          <div className={styles.actionText}>{t("garage.damage")}</div>
        </div>
      )}
    </div>
  );
};

export default ActionsPanel;
