import { useState, useEffect, useRef } from "react";
import SectionFooter from "./SectionFooter";

import styles from "./CollapsibleSection.module.css";

const CollapsibleSection = ({
  children,
  className,
  headerClassName = "",
  contentClassName = "",
  onClick,
  title,
  isCollapsible = false,
  isCollapsedByDefault = false,
  onEdit,
  editText,
  flex = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedByDefault);
  const [height, setHeight] = useState();

  const ref = useRef(null);

  useEffect(() => {
    if (isCollapsed) {
      setHeight(0);
    } else {
      // otherwise the height is calculation is wrong, haven't found other solution, should be fixed
      setTimeout(() => {
        setHeight(ref.current?.getBoundingClientRect().height);
      });
    }
  }, [isCollapsed, onEdit, children]);

  const onToggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <section className={`${className} ${styles.section}`} onClick={onClick}>
      {(title || isCollapsible) && (
        <div
          className={`${styles.header} ${headerClassName} ${
            isCollapsed ? "" : styles.headerBottomPadding
          }`}
          onClick={onToggleCollapsed}
        >
          <div className={styles.title}>{title}</div>
          {isCollapsible && (
            <div
              className={`${styles.chevron} ${
                isCollapsed ? "" : styles.chevronReversed
              }`}
            ></div>
          )}
        </div>
      )}
      <div
        className={`${styles.content} ${contentClassName}`}
        style={isCollapsible ? { height } : null}
      >
        <div ref={ref}>
          {children}
          {onEdit && <SectionFooter onClick={onEdit} editText={editText} />}
        </div>
      </div>
    </section>
  );
};

export default CollapsibleSection;
