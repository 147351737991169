const popularBrandsList = [
  { value: "CUBE", type: "both" },
  { value: "Haibike", type: "E_BIKE" },
  { value: "KTM", type: "both" },
  { value: "Kalkhoff", type: "E_BIKE" },
  { value: "BULLS", type: "both" },
  { value: "PEGASUS", type: "both" },
  { value: "FISCHER", type: "E_BIKE" },
  { value: "Giant", type: "both" },
  { value: "Riese & Müller", type: "E_BIKE" },
  { value: "Gazelle", type: "E_BIKE" },
  { value: "Canyon", type: "BIKE" },
  { value: "Storck", type: "BIKE" },
  { value: "STEVENS", type: "BIKE" },
  { value: "ROSE", type: "BIKE" },
  { value: "SCOTT", type: "BIKE" },
];

export default popularBrandsList;
