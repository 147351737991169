import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import bikeActions from "../../redux/bike/bikeActions";

import { GET_DEALER } from "../../services/query";
import { WELCOME } from "../../constants/routes";

const DealerAlias = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { dealerAlias } = params;
  const { setDealerData } = bikeActions;
  const { data: dealerData, error } = useQuery(GET_DEALER, {
    variables: {
      dealerRequest: {
        value: dealerAlias,
        reqType: "ALIAS",
      }
    },
  });

  useEffect(() => {
    if (dealerData) {
      dispatch(setDealerData(dealerData.dealer));
    }
  }, [dealerData, error, dispatch, setDealerData]);

  return error || dealerData ? (
    <Navigate replace to={WELCOME} />
  ) : (
    <div/>
  );
};

export default DealerAlias;

