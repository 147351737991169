import { useTranslation } from "react-i18next";

const useFullSpecsConfigsSet = (bikeData, additional) => {
  const { t } = useTranslation();

  return [
    {
      title: t("myBike.general"),
      editPageName: "edit-general",
      data: [
        {
          fieldName: "color",
          title: t("myBike.color"),
          value: "color",
          shouldRender: true,
        },
        {
          fieldName: "year",
          title: t("myBike.year"),
          value: "year",
          shouldRender: true,
        },
        {
          fieldName: "weightLimit",
          title: t("myBike.weightLimit"),
          value: "weight_limit",
          shouldRender: bikeData?.weight_limit,
        },
      ],
    },
    {
      title: t("myBike.frame"),
      editPageName: "edit-frame",
      data: [
        {
          fieldName: "gender",
          title: t("myBike.gender"),
          value: "gender",
          shouldRender: true,
        },
        {
          fieldName: "frameDescription",
          title: t("myBike.frameDescription"),
          value: "frame_description",
          shouldRender: bikeData?.frame_description,
        },
      ],
    },
    {
      title: t("myBike.shock"),
      editPageName: "edit-shock",
      isDisabled: bikeData?.category !== "Mountainbike",
      data: [
        {
          fieldName: "shockBrand",
          title: t("myBike.shockBrand"),
          value: "shock_brand",
          shouldRender: true,
        },
        {
          fieldName: "shockTravel",
          title: t("myBike.shockTravel"),
          value: "shock_travel",
          shouldRender: true,
        },
        {
          fieldName: "shockDescription",
          title: t("myBike.shockDescription"),
          value: "shock_description",
          shouldRender: true,
        },
      ],
    },
    {
      title: t("myBike.fork"),
      editPageName: "edit-fork",
      data: [
        {
          fieldName: "forkSystem",
          title: t("myBike.forkSystem"),
          value: "fork_system",
          shouldRender: true,
        },
        {
          fieldName: "forkBrand",
          title: t("myBike.forkBrand"),
          value: "fork_brand",
          shouldRender: true,
        },
        {
          fieldName: "forkType",
          title: t("myBike.forkType"),
          value: "fork_type",
          shouldRender: bikeData?.fork_type,
        },
        {
          fieldName: "forkTravels",
          title: t("myBike.forkTravels"),
          value: "fork_travel",
          shouldRender: true,
        },
        {
          fieldName: "forkDescription",
          title: t("myBike.forkDescription"),
          value: "fork_description",
          shouldRender: bikeData?.fork_description,
        },
      ],
    },
    {
      title: t("myBike.headset"),
      editPageName: "edit-headset",
      data: [
        {
          fieldName: "headsetSystem",
          title: t("myBike.headsetSystem"),
          value: "headset_system",
          shouldRender: true,
        },
        {
          fieldName: "headsetModel",
          title: t("myBike.headsetModel"),
          value: "headset_model",
          shouldRender: true,
        },
        {
          fieldName: "headsetMaterial",
          title: t("myBike.headsetMaterial"),
          value: "headset_material",
          shouldRender: true,
        },
        {
          fieldName: "headsetStem",
          title: t("myBike.headsetStem"),
          value: "handlebars_stem",
          shouldRender: true,
        },
        {
          fieldName: "headsetDescription",
          title: t("myBike.headsetDescription"),
          value: "headset_description",
          shouldRender: bikeData?.headset_description,
        },
      ],
    },
    {
      title: t("myBike.handlebar"),
      editPageName: "edit-handlebar",
      data: [
        {
          fieldName: "handlebarSystem",
          title: t("myBike.handlebarSystem"),
          value: "handlebars_system",
          shouldRender: true,
        },
        {
          fieldName: "handlebarMaterial",
          title: t("myBike.handlebarMaterial"),
          value: "handlebars_material",
          shouldRender: true,
        },
        {
          fieldName: "handlebarModel",
          title: t("myBike.handlebarModel"),
          value: "handlebars_model",
          shouldRender: true,
        },
        {
          fieldName: "grips",
          title: t("myBike.grips"),
          value: "grips",
          shouldRender: true,
        },
        {
          fieldName: "barTape",
          title: t("myBike.barTape"),
          value: "handlebars_tape",
          shouldRender: bikeData?.handlebars_tape,
        },
        {
          fieldName: "bell",
          title: t("myBike.bell"),
          value: "bell",
          shouldRender: bikeData?.bell,
        },
        {
          fieldName: "handlebarDescription",
          title: t("myBike.handlebarDescription"),
          value: "handlebars_description",
          shouldRender: true,
        },
      ],
    },
    {
      title: t("myBike.brakeSet"),
      editPageName: "edit-brake-set",
      data: [
        {
          fieldName: "brakeLevers",
          title: t("myBike.brakeLevers"),
          value: "brake_levers",
          shouldRender: bikeData?.brake_levers,
        },
        {
          fieldName: "frontBrakeSystem",
          title: t("myBike.frontBrakeSystem"),
          value: "front_brake_system",
          shouldRender: true,
        },
        {
          fieldName: "frontBrakeBrand",
          title: t("myBike.frontBrakeBrand"),
          value: "front_brake_brand",
          shouldRender: true,
        },
        {
          fieldName: "frontBrakeModel",
          title: t("myBike.frontBrakeModel"),
          value: "front_brake_model",
          shouldRender: true,
        },
        {
          fieldName: "frontBrakeDiscDiameter",
          title: t("myBike.frontBrakeDiscDiameter"),
          value: "front_brake_disc_diameter",
          shouldRender: bikeData?.front_brake_disc_diameter,
        },
        {
          fieldName: "rearBrakeSystem",
          title: t("myBike.rearBrakeSystem"),
          value: "rear_brake_system",
          shouldRender: true,
        },
        {
          fieldName: "rearBrakeBrand",
          title: t("myBike.rearBrakeBrand"),
          value: "rear_brake_brand",
          shouldRender: true,
        },
        {
          fieldName: "rearBrakeModel",
          title: t("myBike.rearBrakeModel"),
          value: "rear_brake_model",
          shouldRender: true,
        },
        {
          fieldName: "rearBrakeDiscDiameter",
          title: t("myBike.rearBrakeDiscDiameter"),
          value: "rear_brake_disc_diameter",
          shouldRender: bikeData?.rear_brake_disc_diameter,
        },
        {
          fieldName: "brakeDescription",
          title: t("myBike.brakeDescription"),
          value: "brakeset_description",
          shouldRender: bikeData?.brakeset_description,
        },
      ],
    },
    {
      title: t("myBike.light"),
      editPageName: "edit-light",
      data: [
        {
          fieldName: "frontLight",
          title: t("myBike.frontLight"),
          value: "front_light",
          shouldRender: true,
        },
        {
          fieldName: "rearLight",
          title: t("myBike.rearLight"),
          value: "rear_light",
          shouldRender: true,
        },
        {
          fieldName: "dynamo",
          title: t("myBike.dynamo"),
          value: "dynamo",
          shouldRender: bikeData?.dynamo,
        },
        {
          fieldName: "lightDescription",
          title: t("myBike.lightDescription"),
          value: "lights_description",
          shouldRender: bikeData?.lights_description,
        },
      ],
    },
    {
      title: t("myBike.drivetrain"),
      editPageName: "edit-drivetrain",
      data: [
        {
          fieldName: "gearType",
          title: t("myBike.gearType"),
          value: "gear_type",
          shouldRender: true,
        },
        {
          fieldName: "numberOfGears",
          title: t("myBike.numberOfGears"),
          value: "number_of_gears",
          shouldRender: true,
        },
        {
          fieldName: "cassette",
          title: t("myBike.cassette"),
          value: "cassette",
          shouldRender: true,
        },
        {
          fieldName: "rearDerailleur",
          title: t("myBike.rearDerailleur"),
          value: "rear_derailleur",
          shouldRender: true,
        },
        {
          fieldName: "frontDerailleur",
          title: t("myBike.frontDerailleur"),
          value: "front_derailleur",
          shouldRender: true,
        },
        {
          fieldName: "gearBrand",
          title: t("myBike.gearBrand"),
          value: "gear_brand",
          shouldRender: true,
        },
        {
          fieldName: "shifter",
          title: t("myBike.shifter"),
          value: "front_shifter",
          shouldRender: true,
        },
        {
          fieldName: "chainring",
          title: t("myBike.chainring"),
          value: "chainring",
          shouldRender: true,
        },
        {
          fieldName: "chain",
          title: t("myBike.chain"),
          value: "chain",
          shouldRender: true,
        },
        {
          fieldName: "bottomBracket",
          title: t("myBike.bottomBracket"),
          value: "bottom_bracket",
          shouldRender: true,
        },
        {
          fieldName: "crank",
          title: t("myBike.crank"),
          value: "crank",
          shouldRender: true,
        },
        {
          fieldName: "pedals",
          title: t("myBike.pedals"),
          value: "pedals",
          shouldRender: true,
        },
        {
          fieldName: "drivetrainDescription",
          title: t("myBike.drivetrainDescription"),
          value: "drivetrain_description",
          shouldRender: bikeData?.drivetrain_description,
        },
      ],
    },
    {
      title: t("myBike.wheelset"),
      editPageName: "edit-wheelset",
      data: [
        {
          fieldName: "wheelSize",
          title: t("myBike.wheelSize"),
          value: "wheel_size",
          shouldRender: true,
        },
        {
          fieldName: "tireSize",
          title: t("myBike.tireSize"),
          value: "tire_size",
          shouldRender: true,
        },
        {
          fieldName: "frontTire",
          title: t("myBike.frontTire"),
          value: "front_tire",
          shouldRender: true,
        },
        {
          fieldName: "frontRim",
          title: t("myBike.frontRim"),
          value: "front_rim",
          shouldRender: true,
        },
        {
          fieldName: "frontHub",
          title: t("myBike.frontHub"),
          value: "front_hub",
          shouldRender: true,
        },
        {
          fieldName: "frontSpokes",
          title: t("myBike.frontSpokes"),
          value: "front_spoke",
          shouldRender: bikeData?.front_spoke,
        },
        {
          fieldName: "rearTire",
          title: t("myBike.rearTire"),
          value: "rear_tire",
          shouldRender: true,
        },
        {
          fieldName: "rearRim",
          title: t("myBike.rearRim"),
          value: "rear_rim",
          shouldRender: bikeData?.rear_rim,
        },
        {
          fieldName: "rearHub",
          title: t("myBike.rearHub"),
          value: "rear_hub",
          shouldRender: true,
        },
        {
          fieldName: "rearSpokes",
          title: t("myBike.rearSpokes"),
          value: "rear_spoke",
          shouldRender: bikeData?.rear_spoke,
        },
        {
          fieldName: "wheelsetDescription",
          title: t("myBike.wheelsetDescription"),
          value: "wheelset_description",
          shouldRender: bikeData?.wheelset_description,
        },
      ],
    },
    {
      title: t("myBike.others"),
      editPageName: "edit-others",
      data: [
        {
          fieldName: "kickstand",
          title: t("myBike.kickstand"),
          value: "kickstand",
          shouldRender: bikeData?.kickstand,
        },
        {
          fieldName: "saddle",
          title: t("myBike.saddle"),
          value: "saddle",
          shouldRender: true,
        },
        {
          fieldName: "seatPostType",
          title: t("myBike.seatPostType"),
          value: "seat_post_type",
          shouldRender: true,
        },
        {
          fieldName: "seatClamp",
          title: t("myBike.seatClamp"),
          value: "seat_post_clamp",
          shouldRender: bikeData?.seat_post_clamp,
        },
        {
          fieldName: "seatPostDiameter",
          title: t("myBike.seatPostDiameter"),
          value: "seat_post_diameter",
          shouldRender: bikeData?.seat_post_diameter,
        },
        {
          fieldName: "seatPostMaterial",
          title: t("myBike.seatPostMaterial"),
          value: "seat_post_description",
          shouldRender: bikeData?.seat_post_description,
        },
        {
          fieldName: "rearCarrier",
          title: t("myBike.rearCarrier"),
          value: "rear_carrier",
          shouldRender: bikeData?.rear_carrier,
        },
        {
          fieldName: "frontCarrier",
          title: t("myBike.frontCarrier"),
          value: "front_carrier",
          shouldRender: bikeData?.front_carrier,
        },
        {
          fieldName: "chainGuard",
          title: t("myBike.chainGuard"),
          value: "chain_guard",
          shouldRender: bikeData?.chain_guard,
        },
        {
          fieldName: "mudguards",
          title: t("myBike.mudguards"),
          value: "mudguards",
          shouldRender: bikeData?.mudguards,
        },
      ],
    },
  ];
};

export default useFullSpecsConfigsSet;
