import { useTranslation } from "react-i18next";

const useConfig = (styles, yearsData, colorsData, templatesData) => {
  const { t } = useTranslation();

  return {
    year: {
      progressAmount: styles.progressAmountSmall,
      listOptions: yearsData?.bikeYears,
      description: t(`activateBike.descriptionYear`),
      listTitle: t(`activateBike.listTitleYear`),
      submitText: t(`activateBike.continue`),
    },
    color: {
      progressAmount: styles.progressAmountMedium,
      listOptions: colorsData?.bikeColors,
      description: t(`activateBike.descriptionColor`),
      listTitle: t(`activateBike.listTitleColor`),
      submitText: t(`activateBike.continue`),
    },
    size: {
      progressAmount: styles.progressAmountBig,
      listOptions: templatesData?.bikeTemplates?.list?.map(
        (bike) => JSON.parse(bike?.bikeData || {})?.frame_size
      ),
      description: t(`activateBike.descriptionSize`),
      listTitle: t(`activateBike.listTitleSize`),
      submitText: t(`activateBike.complete`),
    },
    complete: {
      progressAmount: styles.progressAmountMax,
      listOptions: null,
      description: "",
      listTitle: "",
      submitText: t(`activateBike.toBike`),
    },
  };
};

export default useConfig;
