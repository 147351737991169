import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";

import DesktopView from "../../../components/DesktopView";
import TextInput from "../../../components/Inputs/Text";
import Label from "../../../components/Label/Label";
import { CREATE_USER, CREATE_BIKE } from "../../../services/mutation";
import { GET_USER } from "../../../services/query";
import authActions from "../../../redux/auth/authActions";
import { getBike, dealerInfo } from "../../../redux/bike/bikeSelectors";
import { ONBOARDING_PRICE } from "../../../constants/routes";
import { mixpanelGender, useMixpanel } from "../../../utils/mixpanel";
import normalizeDateForBE from "../../../assets/helpers/normalizeDateForBE";
import bikeActions from "../../../redux/bike/bikeActions";

import styles from "./CreateProfile.module.css";

const CreateProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [newUser] = useMutation(CREATE_USER);
  const [newBike] = useMutation(CREATE_BIKE);
  const [getUserQuery] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });
  const { signInSuccess, getCurrentUserSuccess } = authActions;
  const mixpanel = useMixpanel();
  const focusedBikeData = useSelector(getBike);
  const dealerInState = useSelector(dealerInfo);
  const { addFocusedBikeData } = bikeActions;

  const onBack = () => {
    navigate(-1);
  };

  const onSubmitClick = () => {
    setError("");
    newUser({
      variables: {
        user: {
          email,
          password,
          acceptMarketingMaterials: true,
        },
      },
    })
      .then((response) => {
        const data = response.data.signup;
        dispatch(signInSuccess({ user: data.user }));
        const bikeDocument = JSON.parse(focusedBikeData.bikeData);
        bikeDocument.is_used = focusedBikeData.isUsed;
        bikeDocument.price = null;
        bikeDocument.year = null;
        bikeDocument.color = null;
        bikeDocument.frame_size = null;
        if (focusedBikeData.id) {
          bikeDocument.bike_id = focusedBikeData.id;
        }
        if (!bikeDocument.manufacturer) {
          bikeDocument.manufacturer = focusedBikeData.manufacturer;
        }
        if (!bikeDocument.model_name) {
          bikeDocument.model_name = focusedBikeData.model;
        }
        if (!bikeDocument.boughtAt) {
          bikeDocument.boughtAt = normalizeDateForBE(
            focusedBikeData?.boughtAt?.value
          );
        }
        const bike = {
          data: JSON.stringify(bikeDocument),
        };

        if (focusedBikeData.id) {
          bike.template_id = focusedBikeData.id;
        }
        if (dealerInState.id) {
          bike.dealerID = dealerInState.id;
        }
        newBike({
          variables: { bike },
        })
          .then((createdBikeRes) => {
            return getUserQuery({
              variables: { id: data.user.id },
            }).then((userRes) => {
              dispatch(
                addFocusedBikeData({
                  ...createdBikeRes.data.createBike,
                })
              );
              dispatch(getCurrentUserSuccess(userRes.data.user));
            });
          })
          .then(() => {
            mixpanel.identify(data.user.id);
            mixpanel.alias(data.user_id);
            mixpanel.track("onboarding_signup_completed", {
              user_id: data.user.id,
              user_selected_insurance: false,
              platform: "frontend",
            });
            mixpanel.people.set({
              user_has_bikes_amount: 1,
              user_language: data?.user.locale,
              user_has_insurance: false,
              user_gender: mixpanelGender(data?.user_gender),
            });
            navigate(ONBOARDING_PRICE);
          });
      })
      .catch((err) => {
        console.log("failed because:", err);
        setError(t(`onboarding.createAccount.error`));
        throw err;
      });
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmitClick();
    }
  };

  return (
    <DesktopView>
      <Helmet>
        <title>{t("createAccountPageTitle")}</title>
        <meta name="theme-color" content="#F3F3F3" />
      </Helmet>
      <div className={styles.createAccount}>
        <div className={styles.header}>
          <div className={styles.backButton} onClick={onBack}></div>
          <div className={styles.pageTitle}>
            {t(`onboarding.createAccount.pageTitle`)}
          </div>
        </div>
        <Label
          text={t(`onboarding.createAccount.email`)}
          className={styles.label}
        />
        <div className={styles.mailInputContainer}>
          <TextInput
            value={email}
            onChange={setEmail}
            className={styles.textInput}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={styles.error}>{error}</div>
        <Label
          text={t(`onboarding.createAccount.password`)}
          className={styles.label}
        />
        <div className={styles.passwordInputContainer}>
          <TextInput
            type="password"
            value={password}
            onChange={setPassword}
            className={styles.textInput}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={styles.description}>
          {t(`onboarding.createAccount.description1`)}
          <a
            className={styles.descriptionLink}
            href="https://velio.de/dataprivacy"
            target="_blank"
            rel="noreferrer"
          >
            {t(`onboarding.createAccount.dataPrivacy`)}
          </a>
          {t(`onboarding.createAccount.description2`)}
          <a
            className={styles.descriptionLink}
            href="https://velio.de/termsandconditions"
            target="_blank"
            rel="noreferrer"
          >
            {t(`onboarding.createAccount.terms`)}
          </a>
          {t(`onboarding.createAccount.description3`)}
        </div>
        <div className={styles.description}>
          {t(`onboarding.createAccount.description4`)}
        </div>
        <div className={styles.footerFiller} />
        <div className={styles.footer}>
          <div className={styles.submitButton} onClick={onSubmitClick}>
            <div className={styles.submitButtonText}>
              {t(`onboarding.createAccount.register`)}
            </div>
            <div className={styles.submitButtonIcon} />
          </div>
        </div>
      </div>
    </DesktopView>
  );
};

export default CreateProfile;
