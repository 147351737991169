import { useTranslation } from "react-i18next";

import styles from "./SectionFooter.module.css";

const SectionFooter = ({ onClick, className, isExpanded }) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} ${styles.sectionFooter}`} onClick={onClick}>
      <div className={styles.expandText}>
        {isExpanded ? t("myBike.collapse") : t("myBike.expand")}
      </div>
      <div
        className={`${styles.expandSymbol} ${
          isExpanded ? styles.reverseExpandSymbol : ""
        }`}
      ></div>
    </div>
  );
};

export default SectionFooter;
