import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { INVITE_FRIEND } from "../../../constants/routes";

import styles from "./SocialPanel.module.css";

const SocialPanel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [isToggled, setIsToggled] = useState(true);
  const openEmailClient = (e) => {
    window.location.href = "mailto: hallo@velio.de";
    e.preventDefault();
  };

  const handleRedirect = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const LinkToInstagram = () =>
    handleRedirect("https://www.instagram.com/velio.de/");

  return (
    <div className={styles.socialPanel}>
      <div className={styles.social}>
        <div onClick={openEmailClient} className={styles.contact}>
          {t("garage.contact")}
        </div>
        <div
          className={styles.invite}
          onClick={() => {
            navigate(INVITE_FRIEND);
          }}
        >
          {t("garage.inviteFriends")}
        </div>
        <div onClick={LinkToInstagram} className={styles.instagram}></div>
      </div>
      {/* Removing newsletter just for MVP release */}
      {/* <div className={styles.subscription}>
        <div className={styles.subscriptionLeftGroup}>
          <div className={styles.emailIcon}></div>
          <div className={styles.subscriptionText}>
            {t("garage.newsletterSignUp")}
          </div>
        </div>
        <div className={styles.toggleContainer}>
          <Toggle
            onToggle={() => {
              setIsToggled(!isToggled);
            }}
            isTrue={isToggled}
          />
        </div>
      </div> */}
    </div>
  );
};

export default SocialPanel;
