import { useTranslation } from "react-i18next";

import styles from "./Header.module.css";

export default function Header({ xDealerName, showCross }) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.logoWrapper}>
        <div className={styles.logo}></div>
        {showCross && <span className={styles.iconCross}></span>}
        {xDealerName && (
          <span className={styles.dealerName}>{xDealerName}</span>
        )}
      </div>
      <p className={styles.subtitle}>{t("onboarding.legacy.subtitle")}</p>
      <h2 className={styles.mainTitle}>{t("onboarding.legacy.headerTitle")}</h2>
      <p className={styles.descr}>{t("onboarding.legacy.description")}</p>
    </div>
  );
}
