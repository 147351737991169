import CancelButton from "../../../../components/Buttons/CancelButton";
import SaveButton from "../../../../components/Buttons/SaveButton";

import styles from "./Buttons.module.css";

const Buttons = ({ onCancel, onSave }) => (
  <>
    <div className={styles.buttonsFiller}></div>
    <div className={styles.buttonsContainer}>
      <CancelButton onClick={onCancel} buttonClassName={styles.button} />
      <SaveButton onClick={onSave} buttonClassName={styles.button} />
    </div>
  </>
);

export default Buttons;
