import { Link } from "react-router-dom";
import styles from "./LinkWithArrow.module.css";

const LinkWithArrow = ({ value, to }) => {
  return (
    <div className={styles.linkWrapper}>
      <div className={styles.actionSection}>
        <div className={styles.stub} />
        <Link to={to} className={styles.link}>
          {value}
          <span className={styles.arrow}></span>
        </Link>
        <div className={styles.stub} />
      </div>
    </div>
  );
};

export default LinkWithArrow;
