import Text from "../../../../components/Inputs/Text";

import styles from "./TextInput.module.css";

const TextInput = ({ title, value, onChange }) => {
  return (
    <div className={styles.parameter}>
      {title && <div className={styles.parameterTitle}>{title}</div>}
      <Text value={value} onChange={onChange} />
    </div>
  );
};

export default TextInput;
