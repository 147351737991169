const baseURL = getInsuranceURLFromHost();
const urlencoded = new URLSearchParams();
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

function getInsuranceURLFromHost() {
  let protocol = "https";
  let apiUrl = document.location.host;
  if (process.env.REACT_APP_PROTOCOL) {
    protocol = process.env.REACT_APP_PROTOCOL;
  }
  if (process.env.REACT_APP_API_URL) {
    apiUrl = process.env.REACT_APP_API_URL;
  } else if (document.URL.includes("localhost")) {
    apiUrl = "dev.velio.dev";
  }
  return `${protocol}://${apiUrl}/garage/api`;
}

export async function getInsurancePrice(price, type, id, voucherCode) {
  urlencoded.append("bike_price", price);
  urlencoded.append("bike_type", type);
  id && urlencoded.append("bike_id", id);
  voucherCode && urlencoded.append("voucher_code", voucherCode);
  const response = await fetch(baseURL + "/insurance/quote", {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  });
  urlencoded.delete("bike_price");
  urlencoded.delete("bike_type");
  id && urlencoded.delete("bike_id");
  voucherCode && urlencoded.delete("voucher_code");
  return await response.json();
}

export async function validationCustomer(data) {
  urlencoded.append("first_name", data.firstName);
  urlencoded.append("last_name", data.lastName);
  urlencoded.append("email", data.email);
  urlencoded.append("title", data.title);
  urlencoded.append("nationality", "DE");
  urlencoded.append(
    "address",
    `{"street_name":"${data.street_name}",
    "street_number":"${data.street_number}",
    "postcode":"${data.postcode}",
    "city":"${data.city}",
    "country":"${data.country}"}`
  );
  urlencoded.append("date_of_birth", data.date_of_birth);

  const response = await fetch(baseURL + "/insurance/validate_customer", {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  });
  urlencoded.delete("first_name");
  urlencoded.delete("last_name");
  urlencoded.delete("email");
  urlencoded.delete("title");
  urlencoded.delete("nationality");
  urlencoded.delete("address");
  urlencoded.delete("date_of_birth");

  return response;
}

export async function validateIBAN(data) {
  const response = await fetch(
    baseURL + `/insurance/bank_name?iban="${data}"`,
    {
      method: "POST",
      redirect: "follow",
    }
  );
  return response.json();
}

export async function validatePolicy(data) {
  urlencoded.append("quote_id", data.quote_id);
  urlencoded.append("start_date", data.start_date);
  urlencoded.append("bike_manufacturer", data.bike_manufacturer);
  urlencoded.append("bike_model", data.bike_model);
  urlencoded.append("original_sale_date", data.original_sale_date);
  urlencoded.append("serial_number", data.serial_number);
  urlencoded.append("is_used", data.is_used);
  urlencoded.append("account_holder", data.account_holder);
  urlencoded.append("IBAN", data.IBAN);
  urlencoded.append("bike_id", data.bike_id);
  urlencoded.append("bike_type", data.bike_type);
  const response = await fetch(baseURL + `/insurance/validate_policy`, {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  });
  urlencoded.delete("quote_id");
  urlencoded.delete("start_date");
  urlencoded.delete("bike_manufacturer");
  urlencoded.delete("bike_model");
  urlencoded.delete("original_sale_date");
  urlencoded.delete("serial_number");
  urlencoded.delete("is_used");
  urlencoded.delete("account_holder");
  urlencoded.delete("IBAN");
  urlencoded.delete("bike_id");
  urlencoded.delete("bike_type");

  return response;
}

export async function createPolicy(data) {
  urlencoded.append("quote_id", data.quote_id);
  urlencoded.append("start_date", data.start_date);
  urlencoded.append("bike_manufacturer", data.bike_manufacturer);
  urlencoded.append("bike_model", data.bike_model);
  urlencoded.append("original_sale_date", data.original_sale_date);
  urlencoded.append("serial_number", data.serial_number);
  urlencoded.append("is_used", data.is_used);
  urlencoded.append("account_holder", data.account_holder);
  urlencoded.append("IBAN", data.IBAN);
  urlencoded.append("bike_id", data.bike_id);
  urlencoded.append("bike_type", data.bike_type);
  urlencoded.append("first_name", data.first_name);
  urlencoded.append("last_name", data.last_name);
  urlencoded.append("email", data.email);
  urlencoded.append("phone", data.phone);
  urlencoded.append("title", data.title);
  urlencoded.append("nationality", "DE");
  urlencoded.append(
    "address",
    `{"street_name":"${data.street_name}",
    "street_number":"${data.street_number}",
    "postcode":"${data.postcode}",
    "city":"${data.city}",
    "country":"${data.country}"}`
  );
  urlencoded.append("date_of_birth", data.date_of_birth);
  urlencoded.append("variant", data.variant);
  const response = await fetch(baseURL + `/insurance/create_policy`, {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  });
  urlencoded.delete("quote_id");
  urlencoded.delete("start_date");
  urlencoded.delete("bike_manufacturer");
  urlencoded.delete("bike_model");
  urlencoded.delete("original_sale_date");
  urlencoded.delete("serial_number");
  urlencoded.delete("is_used");
  urlencoded.delete("account_holder");
  urlencoded.delete("IBAN");
  urlencoded.delete("bike_id");
  urlencoded.delete("bike_type");
  urlencoded.delete("first_name");
  urlencoded.delete("last_name");
  urlencoded.delete("email");
  urlencoded.delete("phone");
  urlencoded.delete("title");
  urlencoded.delete("nationality");
  urlencoded.delete("address");
  urlencoded.delete("date_of_birth");
  urlencoded.delete("variant");

  return response;
}
