import styles from "./ImageViewModal.module.css";

const ImageViewModal = ({ isOpen, onClose, src }) => {
  return (
    isOpen && (
      <div className={styles.modal}>
        <div className={styles.closeButton} onClick={onClose}></div>
        <div className={styles.imageContainer}>
          <img className={styles.image} alt="bike" src={src} />
        </div>
      </div>
    )
  );
};
export default ImageViewModal;
