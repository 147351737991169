import { useNavigate } from "react-router-dom";

import InsuranceStatus from "../../../components/InsuranceStatus";
import { MY_BIKE, ACTIVATE_BIKE } from "../../../constants/routes";
import { useWindowWidth } from "../../../hooks/useWindowWidth";

import styles from "./Bike.module.css";

const Bike = ({
  transitionFactor,
  bike,
  isChosen,
  setIsChosen,
  isBikeActivated,
}) => {
  const navigate = useNavigate();
  const isInsured = !!bike?.insurance;
  const isDesktop = useWindowWidth() > 1280;

  const onBikeClick = () => {
    if (isDesktop && !isChosen) {
      setIsChosen();
    } else if (isBikeActivated) {
      navigate(`${MY_BIKE}/${bike?.id}`);
    } else {
      navigate(`${ACTIVATE_BIKE}/${bike?.id}`);
    }
  };

  return (
    <div
      className={`${styles.bike} ${
        isDesktop && isChosen ? styles.chosenBike : ""
      }`}
      style={{ transform: `translateX(${transitionFactor})` }}
      onClick={onBikeClick}
    >
      <div className={styles.logo}></div>
      <InsuranceStatus
        isInsured={isInsured}
        className={styles.insuranceStatus}
      />
      <img
        className={styles.bikePhoto}
        alt="bike"
        src={bike?.coverImage?.webReadUrl}
      />
      <div className={styles.firm}>{bike?.data?.manufacturer}</div>
      <div className={styles.actionSection}>
        <div className={styles.model}>{bike?.data?.model_name}</div>
      </div>
    </div>
  );
};

export default Bike;
