import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import DesktopView from "../../../components/DesktopView";
import TextInput from "../../../components/Inputs/Text";
import { BIKE_OPTIONS, MODEL, CREATE_PROFILE } from "../../../constants/routes";
import bikeActions from "../../../redux/bike/bikeActions";

import styles from "./AddBikeOption.module.css";

const AddBikeOption = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const mode = location.pathname.split("/")[2];
  const [optionValue, setOptionValue] = useState(null);
  const { addFocusedBikeData } = bikeActions;

  const onBack = () => {
    navigate(-1);
  };

  const onKeyDown = (e) => {
    if (optionValue?.length >= 3 && e.key === "Enter") {
      onSubmitClick();
    }
  };

  const onSubmitClick = () => {
    if (mode === "manufacturer") {
      dispatch(
        addFocusedBikeData({
          manufacturer: optionValue,
        })
      );
      navigate(`${BIKE_OPTIONS}${MODEL}`);
    } else {
      dispatch(
        addFocusedBikeData({
          model: optionValue,
        })
      );
      navigate(`${CREATE_PROFILE}`);
    }
    setOptionValue("");
  };

  return (
    <DesktopView>
      <Helmet>
        <title>{t("bikeOptionsPageTitle")}</title>
        <meta name="theme-color" content="#F3F3F3" />
      </Helmet>
      <div className={styles.addBikeOption}>
        <div className={styles.header}>
          <div className={styles.backButton} onClick={onBack}></div>
          <div className={styles.pageTitle}>
            {t(`onboarding.bikeOptions.${mode}.addOptionTitle`)}
          </div>
        </div>
        <div className={styles.label}>
          {t(`onboarding.bikeOptions.${mode}.addOptionLabel`)}
        </div>
        <TextInput
          className={styles.input}
          value={optionValue}
          onChange={setOptionValue}
          placeholder={t(`onboarding.bikeOptions.${mode}.addOptionPlaceholder`)}
          onKeyDown={onKeyDown}
        />
        <div className={styles.footerFiller} />
        {optionValue?.length >= 3 && (
          <div className={styles.footer}>
            <div className={styles.submitButton} onClick={onSubmitClick}>
              <div className={styles.submitButtonText}>
                {t(`onboarding.bikeOptions.saveInput`)}
              </div>
              <div className={styles.submitButtonIcon} />
            </div>
          </div>
        )}
      </div>
    </DesktopView>
  );
};

export default AddBikeOption;
