import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import generalActions from "./generalActions";

const initialState = {
  toastMessage: {
    header: "",
    body: "",
    shouldRender: false,
  },
};

const toastMessage = createReducer(initialState.toastMessage, {
  [generalActions.setToast]: (_, { payload }) => payload,
  [generalActions.unsetToast]: () => ({ ...initialState.toastMessage }),
});

export default combineReducers({
  toastMessage,
});
