import {useTranslation} from 'react-i18next';

import BasicButton from '../BasicButton';

import styles from './SaveButton.module.css';

const SaveButton = ({onClick, buttonClassName}) => {
  const {t} = useTranslation();

  return (
    <BasicButton
      text={t('save')}
      onClick={onClick}
      buttonClassName={`${buttonClassName} ${styles.button}`}
      textClassName={styles.text}
      iconClassName={styles.icon}
    />
  );
};
export default SaveButton;
