import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { GET_BIKE } from "../../services/query";
import Section from "./Section";
import CollapsibleSection from "../../components/CollapsibleSection";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import useMainSpecsConfig from "./hooks/useMainSpecsConfig";
import useSpecialSpecsConfig from "./hooks/useSpecialSpecsConfig";
import useDescriptionConfig from "./hooks/useDescriptionConfig";
import useFullSpecsConfigsSet from "./hooks/useFullSpecsConfigsSet";
import { getRegisterStatus } from "../../services/api";
import ShareButton from "./ShareButton";
import BikeImages from "./BikeImages";
import { GARAGE, PHOTOS } from "../../constants/routes";
import { getUser } from "../../redux/auth/authSelectors";

import styles from "./MyBike.module.css";

const MyBike = () => {
  const user = useSelector(getUser);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const isDesktop = useWindowWidth() > 1280;
  const { id: bikeId } = useParams();
  const { data: bikeData } = useQuery(GET_BIKE, {
    variables: { id: bikeId },
    skip: !bikeId,
  });
  const [isSharing, setIsSharing] = useState(false);
  const [bike, setBike] = useState(null);
  const mainSpecsConfig = useMainSpecsConfig(bike?.data);
  const specialSpecsConfig = useSpecialSpecsConfig(bike?.data);
  const descriptionConfig = useDescriptionConfig(bike?.data);
  const fullSpecsConfigsSet = useFullSpecsConfigsSet(bike?.data);

  useEffect(() => {
    const userBikes = user?.bikes?.map((bikeOfUser) => ({
      ...bikeOfUser,
      data: {
        ...JSON.parse(bikeOfUser?.data || ""),
      },
    }));
    const userBike = userBikes?.find((bikeOfUser) => bikeOfUser?.id === bikeId);
    if (userBike) {
      setBike(userBike);
      setIsSharing(false);
    } else {
      setBike({
        ...bikeData?.bike,
        data: JSON.parse(bikeData?.bike?.data || null),
      });
      setIsSharing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, bikeData]);

  const onEditPhotos = useCallback(() => {
    navigate(`${pathname}${PHOTOS}`);
  }, [navigate, pathname]);

  return (
    <DesktopViewWithHeader
      footer={
        <ShareButton
          inFooter
          isSharing={isSharing}
          isUser={!!user?.id}
          isRegistered={getRegisterStatus()?.isRegistered}
        />
      }
      isDisabled={isSharing}
    >
      <div className={styles.myBike}>
        <Helmet>
          <title>{t("garagePageTitle")}</title>
        </Helmet>

        {isSharing ? (
          <div className={styles.sharingHeader}>
            <div className={styles.velioLogo}></div>
          </div>
        ) : (
          <div className={styles.header}>
            <div
              className={styles.backButton}
              onClick={() => {
                navigate(GARAGE);
              }}
            ></div>
            <div className={styles.pageTitle}>{t("myBike.pageTitle")}</div>
          </div>
        )}

        {isSharing ? (
          <CollapsibleSection className={styles.section}>
            <div className={styles.brandName}>{bike?.data?.manufacturer}</div>
            <div className={styles.modelName}>{bike?.data?.model_name}</div>
            <BikeImages
              images={bike?.images}
              coverImage={bike?.coverImage}
              isSharing={isSharing}
            />
          </CollapsibleSection>
        ) : (
          <>
            <CollapsibleSection className={styles.section}>
              <div className={styles.brandName}>{bike?.data?.manufacturer}</div>
              <div className={styles.modelName}>{bike?.data?.model_name}</div>
            </CollapsibleSection>
            <CollapsibleSection
              className={styles.section}
              onEdit={onEditPhotos}
              editText={t("myBike.editPhoto")}
            >
              <BikeImages
                images={bike?.images}
                coverImage={bike?.coverImage}
                isSharing={isSharing}
              />
            </CollapsibleSection>
          </>
        )}
        <Section
          bikeData={bike?.data}
          isCollapsible
          sectionConfig={mainSpecsConfig}
          onEdit={
            isSharing
              ? null
              : () => {
                  navigate(`${pathname}/${mainSpecsConfig.editPageName}`);
                }
          }
        />
        {bike?.data?.bike_type?.toLowerCase() === "e-bike" && (
          <Section
            bikeData={bike?.data}
            isCollapsible
            sectionConfig={specialSpecsConfig}
            onEdit={
              isSharing
                ? null
                : () => {
                    navigate(`${pathname}/${specialSpecsConfig.editPageName}`);
                  }
            }
          />
        )}
        <Section
          bikeData={bike?.data}
          isCollapsible
          sectionConfig={descriptionConfig}
          onEdit={
            isSharing
              ? null
              : () => {
                  navigate(`${pathname}/${descriptionConfig.editPageName}`);
                }
          }
        />
        <div className={styles.subHeader}>{t("myBike.fullSpecs")}</div>
        {fullSpecsConfigsSet.map((config) =>
          config.isDisabled ? null : (
            <Section
              key={config.editPageName}
              bikeData={bike?.data}
              isCollapsible
              sectionConfig={config}
              onEdit={
                isSharing
                  ? null
                  : () => {
                      navigate(`${pathname}/${config.editPageName}`);
                    }
              }
              isCollapsedByDefault
            />
          )
        )}
        {!isDesktop && (
          <>
            <div className={styles.shareBikeFiller}></div>
            <ShareButton isSharing={isSharing} isUser={!!user} />
          </>
        )}
      </div>
    </DesktopViewWithHeader>
  );
};

export default MyBike;
