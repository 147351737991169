const filterSortedByString = (array, string) => {
  if (string === "") {
    return array;
  }

  return array
    ?.map((chunk) => {
      const newItems = chunk.items.filter((item) =>
        item.toLowerCase().includes(string.toLowerCase())
      );
      return { ...chunk, items: newItems };
    })
    ?.filter((chunk) => chunk.items.length !== 0);
};

export default filterSortedByString;
