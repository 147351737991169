const getFieldValue = (itemValue, bikeData) => {
  return itemValue.split(".").reduce((value, route) => {
    if (value) {
      return value[route];
    }
    return null;
  }, bikeData);
};

export default getFieldValue;
