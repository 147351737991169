import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";

import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import {
  GET_BIKE,
  GET_YEARS,
  GET_COLORS,
  BIKE_TEMPLATES,
  GET_USER,
} from "../../services/query";
import { GARAGE } from "../../constants/routes";
import { UPDATE_BIKE } from "../../services/mutation";
import authActions from "../../redux/auth/authActions";
import { getUserId } from "../../redux/auth/authSelectors";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import useConfig from "./hooks/useConfig";

import styles from "./ActivateBike.module.css";

const ActivateBike = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useWindowWidth() > 1280;
  const userId = useSelector(getUserId);
  const { id: bikeId } = useParams();
  const { getCurrentUserSuccess } = authActions;
  const [chosenOption, setChosenOption] = useState(null);

  const [updateBike] = useMutation(UPDATE_BIKE);
  const [getUserQuery] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });
  const { data: bikeData } = useQuery(GET_BIKE, {
    variables: { id: bikeId },
  });
  const bikeParsedData = JSON.parse(bikeData?.bike?.data || null);
  const parsedBikeType =
    bikeParsedData?.bike_type?.toLowerCase() === "bike"
      ? "BIKE"
      : bikeParsedData?.bike_type?.toLowerCase() === "e-bike"
      ? "E_BIKE"
      : bikeParsedData?.bike_type;
  const stage = !bikeParsedData?.year
    ? "year"
    : !bikeParsedData?.color
    ? "color"
    : !bikeParsedData?.size
    ? "size"
    : "complete";
  const { data: yearsData } = useQuery(GET_YEARS, {
    variables: {
      filter: {
        bikeType: parsedBikeType,
        manufacture: bikeParsedData?.manufacturer,
        model: bikeParsedData?.model_name,
      },
    },
    skip: stage !== "year",
  });
  const { data: colorsData } = useQuery(GET_COLORS, {
    variables: {
      filter: {
        bikeType: parsedBikeType,
        manufacture: bikeParsedData?.manufacturer,
        model: bikeParsedData?.model_name,
        year: bikeParsedData?.year,
      },
    },
    skip: stage !== "color",
  });
  const { data: templatesData } = useQuery(BIKE_TEMPLATES, {
    variables: {
      in: {
        bikeType: parsedBikeType,
        manufacturer: bikeParsedData?.manufacturer,
        model: bikeParsedData?.model_name,
        year: bikeParsedData?.year?.toString(),
        color: bikeParsedData?.color,
        offset: 0,
        limit: 50,
      },
    },
    skip: stage !== "size",
  });

  const { progressAmount, listOptions, description, listTitle, submitText } =
    useConfig(styles, yearsData, colorsData, templatesData)[stage];

  const onClose = () => {
    navigate(GARAGE);
  };

  const onSubmitClick = async (passedOption) => {
    if (stage === "complete") {
      setChosenOption(null);
      navigate(GARAGE);
    } else {
      let newParsedData = {};
      const rememberedChosenOption =
        typeof passedOption === "number" ? passedOption : chosenOption;
      setChosenOption(null);
      if (stage === "size") {
        newParsedData = JSON.parse(
          templatesData?.bikeTemplates?.list[rememberedChosenOption].bikeData
        );
      }
      await updateBike({
        variables: {
          id: bikeData?.bike?.id,
          bike: {
            data: JSON.stringify({
              ...bikeParsedData,
              ...newParsedData,
              price: bikeParsedData.price,
              [stage]:
                listOptions[rememberedChosenOption] === null
                  ? "unset"
                  : listOptions[rememberedChosenOption],
            }),
            level: "FULL_FILLED",
          },
        },
      });
      const newUserData = await getUserQuery({
        variables: { id: userId },
      });
      dispatch(getCurrentUserSuccess(newUserData.data.user));
    }
  };

  useEffect(() => {
    if (listOptions?.[0] === null) {
      onSubmitClick(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOptions]);

  const SubmitButton = () =>
    chosenOption !== null || stage === "complete" ? (
      <div className={styles.footer}>
        <div className={styles.submitButton} onClick={onSubmitClick}>
          <div className={styles.submitButtonText}>{submitText}</div>
          <div
            className={`${styles.submitButtonIcon} ${
              stage === "complete" ? styles.submitButtonIconComplete : ""
            }`}
          />
        </div>
      </div>
    ) : null;

  return (
    <DesktopViewWithHeader footer={<SubmitButton />}>
      <div className={styles.activateBike}>
        <Helmet>
          <title>Activate Bike</title>
          <meta
            name="description"
            content="Activte Bike. Velio | Everything for your bike."
          />
          <meta name="theme-color" content="#2E3840" />
        </Helmet>
        <div className={styles.closeButton} onClick={onClose}></div>
        <div className={styles.pageTitle}>{t("activateBike.pageTitle")}</div>
        <div className={styles.progressBar}>
          <div className={`${styles.progressAmount} ${progressAmount}`}></div>
        </div>
        <div className={styles.description}>{description}</div>
        <div className={styles.listTitle}>{listTitle}</div>
        <div className={styles.list}>
          {listOptions?.map((option, i) => (
            <div
              className={`${styles.option} ${
                chosenOption === i ? styles.chosenOption : ""
              }`}
              onClick={() => setChosenOption(i)}
              key={option}
            >
              {option}
            </div>
          ))}
        </div>
        {stage === "complete" && (
          <>
            <div className={styles.completeImage}></div>
            <div className={styles.successMessage}>
              {t("activateBike.successMessage")}
            </div>
            <div className={styles.successDescription}>
              {t("activateBike.successDescription")}
            </div>
          </>
        )}
        <div className={styles.footerFiller}></div>
        {!isDesktop && (
          <>
            <div className={styles.submitButtonFiller}></div>
            <SubmitButton />
          </>
        )}
      </div>
    </DesktopViewWithHeader>
  );
};

export default ActivateBike;
