const normalizeDateForBE = (date) => {
  if (!date) {
    return null;
  }

  if (`${date}`.split("/").length === 3) {
    return date;
  }

  date = new Date(date);

  let month;
  let day;
  if (date.getMonth() < 9) {
    month = "0" + (date.getMonth() + 1);
  } else {
    month = date.getMonth() + 1;
  }

  if (date.getDate() < 10) {
    day = "0" + date.getDate();
  } else {
    day = date.getDate();
  }

  return `${day}/${month}/${date.getFullYear()}`;
};

export default normalizeDateForBE;
