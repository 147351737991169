import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import authActions from "./authActions";

const isSignedPreviouslyLocalStorage = localStorage.getItem("isSignedPreviously");

const initialState = {
  user: null,
  isSignedIn: false,
  isSignedPreviously: isSignedPreviouslyLocalStorage || false,
  error: null,
  isAuthLoading: false,
  redirectLink: "",
  isInInsuranceProcess: false,
};

const user = createReducer(initialState.user, {
  [authActions.registrationSuccess]: (_, { payload }) => payload.user,
  [authActions.signInSuccess]: (_, { payload }) => payload.user,
  [authActions.signOutSuccess]: () => initialState.user,
  [authActions.getCurrentUserSuccess]: (_, { payload }) => payload,
});

const isSignedPreviously = createReducer(initialState.isSignedPreviously, {
  [authActions.registrationSuccess]: () => true,
  [authActions.signInSuccess]: () => true,
  [authActions.signOutSuccess]: () => false,
});


const isAuthLoading = createReducer(initialState.isAuthLoading, {
  [authActions.registrationRequest]: () => true,
  [authActions.registrationSuccess]: () => false,
  [authActions.registrationError]: () => false,
  [authActions.signInRequest]: () => true,
  [authActions.signInSuccess]: () => false,
  [authActions.signInError]: () => false,
  [authActions.signOutRequest]: () => true,
  [authActions.signOutSuccess]: () => false,
  [authActions.signOutError]: () => false,
  [authActions.getCurrentUserRequest]: () => true,
  [authActions.getCurrentUserSuccess]: () => false,
  [authActions.getCurrentUserError]: () => false,
});

const setError = (_, { payload }) => payload;

const error = createReducer(initialState.error, {
  [authActions.registrationError]: setError,
  [authActions.signInError]: setError,
  [authActions.signOutError]: setError,
  [authActions.getCurrentUserError]: setError,
});

const isSignedIn = createReducer(initialState.isSignedIn, {
  [authActions.registrationSuccess]: () => true,
  [authActions.signInSuccess]: () => true,
  [authActions.getCurrentUserSuccess]: () => true,
  [authActions.registrationError]: () => false,
  [authActions.signInError]: () => false,
  [authActions.getCurrentUserError]: () => false,
  [authActions.signOutSuccess]: () => false,
});

const redirectLink = createReducer(initialState.redirectLink, {
  [authActions.clearRedirectLink]: () => "",
  [authActions.setRedirectLink]: (_, { payload }) => payload,
});

const isInInsuranceProcess = createReducer(initialState.isInInsuranceProcess, {
  [authActions.setIsInInsuranceProcess]: (_, { payload }) => payload,
});

export default combineReducers({
  user,
  isSignedIn,
  isSignedPreviously,
  error,
  isAuthLoading,
  redirectLink,
  isInInsuranceProcess,
});
