import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { GARAGE, LOGIN, WELCOME } from "../../../constants/routes";
import Sharing from "../../../components/Sharing/Sharing";

import styles from "./ShareButton.module.css";

const ShareButton = ({ isSharing, isUser, isRegistered, inFooter }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!isSharing) {
    return (
      <Sharing
        buttonClassName={
          inFooter ? styles.shareInFooter : styles.shareBikeButton
        }
        title={t("myBike.shareBike")}
        text={t("myBike.shareBikeDescription")}
        url={window.location.href}
      >
        <div className={styles.shareBikeButtonText}>
          {t("myBike.shareBike")}
        </div>
        <div className={styles.shareBikeButtonIcon}></div>
      </Sharing>
    );
  } else if (isUser) {
    return (
      <div
        className={inFooter ? styles.shareInFooter : styles.shareBikeButton}
        onClick={() => {
          navigate(GARAGE);
        }}
      >
        <div className={styles.shareBikeButtonText}>
          {t("myBike.toYourBikes")}
        </div>
        <div className={styles.toRightIcon}></div>
      </div>
    );
  } else if (isRegistered) {
    return (
      <div
        className={inFooter ? styles.shareInFooter : styles.shareBikeButton}
        onClick={() => {
          navigate(LOGIN);
        }}
      >
        <div className={styles.shareBikeButtonText}>
          {t("myBike.toTheLogin")}
        </div>
        <div className={styles.toRightIcon}></div>
      </div>
    );
  }
  return (
    <div
      className={inFooter ? styles.shareInFooter : styles.shareBikeButton}
      onClick={() => {
        navigate(WELCOME);
      }}
    >
      <div className={styles.shareBikeButtonText}>
        {t("myBike.addYourOwnBike")}
      </div>
      <div className={styles.toRightIcon}></div>
    </div>
  );
};

export default ShareButton;
