import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getUser } from "../../redux/auth/authSelectors";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import ExpandableSection from "../../components/ExpandableSection";
import {
  BIKE_PASS,
  BIKE_PASS_EDIT,
  GARAGE,
  MY_BIKE,
} from "../../constants/routes";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import BikeParameter from "./BikeParameter";
import ImageViewModal from "../../components/ImageViewModal";
import FinalizeButton from "./FinalizeButton";

import styles from "./BikePass.module.css";

const BikePass = () => {
  const user = useSelector(getUser) || {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const bikeId = params.id;
  const isDesktop = useWindowWidth() > 1280;
  const [bike, setBike] = useState(null);
  const [isImageViewOpen, setIsImageViewOpen] = useState(false);
  const [isInvoiceViewOpen, setIsInvoiceViewOpen] = useState(false);
  const isCompleted =
    bike?.coverImage?.isUserUploaded &&
    bike?.bikePassInvoice &&
    bike?.frameNumber &&
    bike?.data?.boughtAt &&
    bike?.data?.price;

  useEffect(() => {
    const userBike = user?.bikes?.find(
      (bikeOfUser) => bikeOfUser?.id === bikeId
    );

    if (userBike) {
      setBike({
        ...userBike,
        data: {
          ...JSON.parse(userBike?.data || ""),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, user]);

  const getFinalizeButton = () => (
    <FinalizeButton isCompleted={isCompleted} bike={bike} user={user} />
  );

  const userFullName = user?.first_name
    ? user?.first_name + (user?.last_name ? ` ${user?.last_name}` : "")
    : user?.last_name
    ? user?.last_name
    : "-";

  return (
    <DesktopViewWithHeader footer={getFinalizeButton()}>
      <div className={styles.bikePass}>
        <Helmet>
          <title>Bike Pass</title>
          <meta content="Bike Pass. Velio | Bike Pass details." />
        </Helmet>
        <div className={styles.header}>
          <div
            className={styles.backButton}
            onClick={() => {
              navigate(GARAGE);
            }}
          ></div>
          <div className={styles.pageTitle}>{t("bikePass.pageTitle")}</div>
        </div>

        {!isCompleted && (
          <ExpandableSection className={styles.section}>
            <div className={styles.completionProgress} />
            <div className={styles.subHeader}>
              {t("bikePass.completionRate")}
            </div>
            <div className={styles.paragraph}>
              {t("bikePass.conditionsToCreate")}
            </div>
            <div className={styles.paragraph}>{t("bikePass.description")}</div>
          </ExpandableSection>
        )}
        <ExpandableSection
          className={`${styles.section} ${styles.sectionSmallerBottomPadding}`}
          expandableContent={
            <div className={styles.expandableContent}>
              <BikeParameter
                title={t("bikePass.circuit")}
                value={bike?.data?.circuit}
              />
              <BikeParameter
                title={t("bikePass.derailleur")}
                value={bike?.data?.rear_derailleur}
              />
              <BikeParameter
                title={t("bikePass.numberOfGears")}
                value={bike?.data?.number_of_gears}
              />
              <BikeParameter
                title={t("bikePass.wheelSize")}
                value={bike?.data?.wheel_size}
              />
              <BikeParameter
                title={t("bikePass.gender")}
                value={bike?.data?.gender}
              />
              <BikeParameter
                title={t("bikePass.color")}
                value={bike?.data?.color}
              />
              <BikeParameter
                title={t("bikePass.identificationCode")}
                value={bike?.data?.user_id}
              />
              <BikeParameter
                title={t("bikePass.bikeDescription")}
                value={bike?.data?.user_description}
                className={styles.bikeDescription}
              />
              <div
                className={styles.link}
                onClick={() => {
                  navigate(`${MY_BIKE}/${bike.id}`);
                }}
              >
                <div className={styles.linkText}>
                  {t("bikePass.toBikeDetails")}
                </div>
                <div
                  className={`${styles.linkSymbol} ${styles.linkSymbolNext}`}
                ></div>
              </div>
            </div>
          }
        >
          {bike?.coverImage?.isUserUploaded && (
            <img
              className={styles.bikePhoto}
              alt="bike"
              src={bike?.coverImage?.webReadUrl}
              onClick={() => {
                setIsImageViewOpen(true);
              }}
            />
          )}
          <div className={styles.bikeData}>
            <BikeParameter
              title={t("bikePass.brand")}
              value={bike?.data?.manufacturer}
            />
            <BikeParameter
              title={t("bikePass.bikeType")}
              value={bike?.data?.bike_type}
            />
            <BikeParameter
              title={t("bikePass.model")}
              value={bike?.data?.model_name}
            />
            <BikeParameter
              title={t("bikePass.purchasePrice")}
              value={bike?.data?.price}
            />
            <BikeParameter
              title={t("bikePass.dateOfPurchase")}
              value={new Date(bike?.data.boughtAt).toLocaleDateString("en")}
            />
            <BikeParameter
              title={t("bikePass.frameNumber")}
              value={bike?.frameNumber}
            />
            {bike?.data?.bike_type?.toLowerCase() === "e-bike" && (
              <BikeParameter
                title={t("bikePass.batterySerialNumber")}
                value={bike?.batteryNumber}
              />
            )}
            <BikeParameter title={t("bikePass.owner")} value={userFullName} />
          </div>
        </ExpandableSection>
        {!!bike?.bikePassInvoice && (
          <>
            <div className={styles.invoiceSectionTitle}>
              {t("bikePass.invoiceSectionTitle")}
            </div>
            <div className={styles.invoiceSectionDescription}>
              {t("bikePass.invoiceSectionDescription")}
            </div>
            <div
              className={styles.invoice}
              onClick={() => {
                setIsInvoiceViewOpen(true);
              }}
            >
              <div className={styles.invoiceBody}>
                <div className={styles.invoiceIcon}></div>
                <div className={styles.invoiceInfo}>
                  <div className={styles.invoiceView}>
                    {t("bikePass.invoiceView")}
                  </div>
                  <div className={styles.invoiceUploadTime}>
                    {t("bikePass.wasUploaded", {
                      date: new Date(
                        bike?.bikePassInvoice.uploadedAt
                      ).toLocaleDateString(),
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isCompleted && (
          <div
            className={styles.editCompleted}
            onClick={() => {
              navigate(`${BIKE_PASS}/${bikeId}${BIKE_PASS_EDIT}`);
            }}
          >
            <div className={styles.editCompletedText}>{t("bikePass.edit")}</div>
            <div className={styles.editCompletedIcon}></div>
          </div>
        )}
        {!isDesktop && getFinalizeButton()}
      </div>
      <ImageViewModal
        isOpen={isImageViewOpen}
        onClose={() => {
          setIsImageViewOpen(false);
        }}
        src={bike?.coverImage?.webReadUrl}
      />
      <ImageViewModal
        isOpen={isInvoiceViewOpen}
        onClose={() => {
          setIsInvoiceViewOpen(false);
        }}
        src={bike?.bikePassInvoice?.readUrl}
      />
    </DesktopViewWithHeader>
  );
};
export default BikePass;
