import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { Helmet } from "react-helmet";

import FormLabel from "../../../components/FormLabel/FormLabel";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import ButtonWithoutProgressBar from "../../../components/ButtonWithoutProgressBar/ButtonWithoutProgressBar";
import { useSelector, useDispatch } from "react-redux";
import { getBike, getStepOnboarding } from "../../../redux/bike/bikeSelectors";
import insuranceActions from "../../../redux/insurance/insuranceActions";
import { validateIBAN, validatePolicy } from "../../../services/apiRequest";
import { getInsuranceData } from "../../../redux/insurance/insuranceSelectors";
import Spinner from "../../../components/Spinner/Spinner";
import DesktopView from "../../../components/DesktopView";
import bikeActions from "../../../redux/bike/bikeActions";
import { getStepInsurance } from "../../../redux/bike/bikeSelectors";
import normalizeDateForBE from "../../../assets/helpers/normalizeDateForBE";
import { errorHandlerBE } from "../../../assets/helpers/errorHandlerBE";
import { INSURANCE_OVERVIEW, WELCOME } from "../../../constants/routes";
import InsuranceInterface from "../components/InsuranceInterface";

import styles from "./InsurancePayment.module.css";

const InsurancePayment = () => {
  const { secondStepComplete } = useSelector(getStepInsurance);
  const [startDateInsurance, setStartDateInsurance] = useState();
  const [checkedSEPA, setCheckedSEPA] = useState(false);
  const [dateErrorMessage, setDateErrorMessage] = useState("");
  const focusedBikeData = useSelector(getBike);
  const { addInsuranceData } = insuranceActions;
  const dispatch = useDispatch();
  const [bankName, setBankName] = useState(""); // eslint-disable-next-line
  const [iban, setIban] = useState();
  const [errorIBAN, setErrorIBAN] = useState("");
  const { selectedInsuranceRate, frameNumber, firstName, lastName } =
    useSelector(getInsuranceData);
  const { t } = useTranslation();
  const [checkboxError, setCheckboxError] = useState("");
  const [loading, setLoading] = useState(false);
  const [learnMore, setLearnMore] = useState(false);
  const navigate = useNavigate();
  const { firstStepComplete } = useSelector(getStepOnboarding);
  const owner = `${firstName} ${lastName}`;

  useEffect(() => {
    !secondStepComplete && navigate(WELCOME);
  });

  const nextPage = () => {
    toast.dismiss();
    dispatch(
      bikeActions.addStepInsurance({
        thirdStepComplete: true,
      })
    );
    navigate(INSURANCE_OVERVIEW);
  };

  const { handleSubmit } = useForm();

  const handleChange = (date, e) => {
    e.preventDefault();
    setStartDateInsurance(date);
    setDateErrorMessage("");
  };
  const onCheckBankName = (iban) => {
    if (iban.length > 22 || iban.length < 22) {
      setErrorIBAN(t("onboarding.legacy.ibanLengthError"));
    } else {
      setErrorIBAN("");
    }
    setIban(iban);
    validateIBAN(iban).then((data) => {
      setBankName(data.bankname);
    });
  };

  const minDate = new Date().setDate(new Date().getDate() + 1);

  const onSubmit = async () => {
    if (!checkedSEPA || !startDateInsurance) {
      if (!checkedSEPA) {
        setCheckboxError(t("onboarding.legacy.paymentConfirmationError"));
      }
      if (!startDateInsurance) {
        setDateErrorMessage(t("onboarding.legacy.errorRequiredStartingDate"));
      }
    } else {
      setLoading(true);
      await validatePolicy({
        quote_id: selectedInsuranceRate?.QuoteID,
        start_date: normalizeDateForBE(startDateInsurance),
        bike_manufacturer: focusedBikeData?.manufacturer,
        bike_model: focusedBikeData?.model,
        original_sale_date: firstStepComplete
          ? normalizeDateForBE(focusedBikeData?.boughtAt?.value)
          : focusedBikeData?.boughtAt?.value,

        serial_number: frameNumber,
        is_used: focusedBikeData?.isUsed,
        account_holder: owner,
        IBAN: iban,
        bike_id: selectedInsuranceRate?.BikeID,
        bike_type: focusedBikeData?.type,
      }).then((data) => {
        setLoading(false);
        if (data?.status === 400) {
          data.json().then((value) => {
            errorHandlerBE(value, toast, t);
          });
        }

        if (data?.status === 200) {
          nextPage();
        }
      });
      dispatch(
        addInsuranceData({
          IBAN: iban,
          accountOwner: owner,
          bankName,
          agreement: checkedSEPA,
          startDateInsurance,
        })
      );
    }
  };

  const chosenCheckbox = () => {
    setCheckboxError("");
    setCheckedSEPA(!checkedSEPA);
  };

  const displayLearnMore = () => setLearnMore(!learnMore);

  return (
    <DesktopView>
      <Helmet>
        <title>{t("insurancePaymentDetailsPageTitle")}</title>
        <meta name="theme-color" content="#F3F3F3" />
      </Helmet>
      <InsuranceInterface page="payment" />
      <div className={styles.pageWrapper}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <FormLabel className={styles.label}>
            IBAN
            <input
              className={styles.inputIban}
              type="text"
              name="iban"
              id="iban"
              placeholder={t("IbanPlaceholder")}
              onBlur={(e) => onCheckBankName(e.target.value)}
            />
          </FormLabel>
          {errorIBAN && <p className={styles.errorMessage}>{errorIBAN}</p>}
          <p className={styles.bankName}>Bank: {bankName}</p>
          <FormLabel className={styles.labelDatePicker}>
            {t("onboarding.legacy.insuranceStartdate")}
            <DatePicker
              selected={startDateInsurance && new Date(startDateInsurance)}
              onChange={(date, event) => handleChange(date, event)}
              className={styles.inputPicker}
              dateFormat="dd.MM.yyyy"
              minDate={minDate}
              placeholderText={t("onboarding.legacy.enterStartDate")}
              withPortal
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onFocus={(e) => e.target.blur()}
            />
          </FormLabel>
          {dateErrorMessage && (
            <p className={styles.errorMessage}>{dateErrorMessage}</p>
          )}
          <ButtonWithoutProgressBar
            value={t("onboarding.insuranceBikeDetails.continue")}
          />
        </form>
        <CustomCheckbox
          type="checkbox"
          value={
            <p className={styles.checkboxText}>
              {t("onboarding.legacy.sepainfo")}
            </p>
          }
          id="2"
          onClick={chosenCheckbox}
          error={checkboxError}
        >
          {checkboxError && (
            <p className={styles.errorMessage}>{checkboxError}</p>
          )}
          <p className={styles.firstText} onClick={displayLearnMore}>
            {t("onboarding.legacy.learnMore")}
            <span
              className={learnMore ? styles.chevronUp : styles.chevronDown}
            ></span>
          </p>
          {learnMore && (
            <p className={styles.secondText}>
              {t("onboarding.legacy.sepainfosecond")}
            </p>
          )}
        </CustomCheckbox>
        <p className={styles.note}> {t("onboarding.legacy.note")}</p>
      </div>
      {loading && <Spinner color="#02f596" />}
      <Toaster
        position="top-center"
        toastOptions={{
          className: styles.toast,
          duration: Infinity,
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ message }) => (
              <>
                {t.type !== "loading" && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className={styles.toastButton}
                  ></button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </DesktopView>
  );
};
export default InsurancePayment;
