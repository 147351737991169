import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import CollapsibleSection from "../../components/CollapsibleSection";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { GARAGE } from "../../constants/routes";
import Sharing from "../../components/Sharing/Sharing";

import styles from "./InviteFriend.module.css";

const InviteFriend = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const isDesktop = useWindowWidth() > 1280;

  const getButtons = () => (
    <div className={styles.shareButtons}>
      <div
        className={styles.copyButton}
        onClick={() => {
          navigator.clipboard.writeText(
            `${window.location.href.split("/").slice(0, -1).join("/")}/welcome`
          );
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 3000);
        }}
      >
        <div className={styles.copyUrl}>velio.de/welcome</div>
        <div className={`${styles.copyText} ${isCopied ? styles.copied : ""}`}>
          {isCopied ? t("inviteFriend.copiedLink") : t("inviteFriend.copyLink")}
        </div>
      </div>
      <Sharing
        buttonClassName={styles.shareBikeButton}
        title={t("inviteFriend.inviteFriends")}
        text={t("inviteFriend.inviteFriendsDescription")}
        url={`${window.location.href
          .split("/")
          .slice(0, -1)
          .join("/")}/welcome`}
      >
        <div className={styles.shareBikeButtonText}>
          {t("inviteFriend.inviteFriends")}
        </div>
      </Sharing>
    </div>
  );

  return (
    <DesktopViewWithHeader footer={getButtons()}>
      <div className={styles.routesWrapper}>
        <div className={styles.inviteFriend}>
          <Helmet>
            <title>Invite Friend</title>
            <meta
              name="description"
              content="Invite Friend. Velio | Invite friend."
            />
          </Helmet>
          <div className={styles.header}>
            <div
              className={styles.backButton}
              onClick={() => {
                navigate(GARAGE);
              }}
            ></div>
            <div className={styles.velioLogo}></div>
          </div>
          <div className={styles.title}>{t("inviteFriend.pageTitle")}</div>
          <div className={styles.subTitle}>{t("inviteFriend.subTitle")}</div>
          <CollapsibleSection className={styles.section}>
            <div className={styles.sectionElem}>
              <div className={styles.sectionElemHeader}>
                {t("inviteFriend.yourBike")}
              </div>
              <div className={styles.sectionElemText}>
                {t("inviteFriend.enjoy")}
              </div>
            </div>

            <div className={styles.sectionElem}>
              <div className={styles.sectionElemHeader}>
                {t("inviteFriend.yourBikePass")}
              </div>
              <div className={styles.sectionElemText}>
                {t("inviteFriend.allImportant")}
              </div>
            </div>

            <div className={styles.sectionElem}>
              <div className={styles.sectionElemHeader}>
                {t("inviteFriend.value")}
              </div>
              <div className={styles.sectionElemText}>
                {t("inviteFriend.prove")}
              </div>
            </div>

            <div className={styles.sectionElem}>
              <div className={styles.sectionElemHeader}>
                {t("inviteFriend.insurance")}
              </div>
              <div className={styles.sectionElemText}>
                {t("inviteFriend.alwaysProtected")}
              </div>
            </div>
          </CollapsibleSection>
          <div className={styles.shareButtonsFiller}></div>
          {!isDesktop && getButtons()}
        </div>
      </div>
    </DesktopViewWithHeader>
  );
};

export default InviteFriend;
