import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";
import DatePicker from "react-datepicker";

import { getUser } from "../../redux/auth/authSelectors";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import { BIKE_PASS } from "../../constants/routes";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import ExpandableSection from "../../components/ExpandableSection";
import CancelButton from "../../components/Buttons/CancelButton";
import SaveButton from "../../components/Buttons/SaveButton";
import TextInput from "../../components/Inputs/Text";
import TextAreaInput from "../../components/Inputs/Textarea";
import CollapsibleSection from "../../components/CollapsibleSection";
import {
  UPDATE_BIKE,
  UPLOAD_IMAGE,
  UPLOAD_INVOICE,
  DELETE_INVOICE,
  UPDATE_USER,
} from "../../services/mutation";
import { GET_USER } from "../../services/query";
import authActions from "../../redux/auth/authActions";
import DefaultBike from "../../assets/images/DefaultBike.svg";
import ImageViewModal from "../../components/ImageViewModal";
import {
  getUserFirstName,
  getUserLastName,
} from "../../redux/auth/authSelectors";

import styles from "./BikePassEdit.module.css";

const BikePassEdit = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser) || {};
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const [updateBike] = useMutation(UPDATE_BIKE);
  const [uploadInvoice] = useMutation(UPLOAD_INVOICE);
  const [deleteInvoice] = useMutation(DELETE_INVOICE);
  const [updateUser] = useMutation(UPDATE_USER);
  const [getUserQuery] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const { getCurrentUserSuccess } = authActions;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const bikeId = pathname.split("/")[2];
  const isDesktop = useWindowWidth() > 1280;
  const addInvoiceInput = useRef(null);
  const addPhotoInput = useRef(null);
  const [bike, setBike] = useState(null);
  const [bikeImage, setBikeImage] = useState(null);
  const [batteryNumber, setBatteryNum] = useState(bike?.batteryNumber || "");
  const [frameNumber, setFrameNum] = useState(bike?.frameNumber || "");
  const [idCode, setIdCode] = useState(bike?.data.user_id || "");
  const [invoice, setInvoice] = useState(null);
  const [firstName, setFirstName] = useState(userFirstName || "");
  const [lastName, setLastName] = useState(userLastName || "");
  const [date, setDate] = useState(bike?.data?.boughtAt || "");
  const [price, setPrice] = useState(bike?.data?.price || "");
  const [isInvoiceDeleted, setIsInvoiceDeleted] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState(
    bike?.data?.user_description || ""
  );
  const [isImageViewOpen, setIsImageViewOpen] = useState(false);
  const [isInvoiceViewOpen, setIsInvoiceViewOpen] = useState(false);
  const currentDate = new Date();
  const isInvoiceDisplayed =
    !!invoice || (!!bike?.bikePassInvoice && !isInvoiceDeleted);

  useEffect(() => {
    setFirstName(userFirstName);
    setLastName(userLastName);
  }, [userFirstName, userLastName]);

  useEffect(() => {
    const userBike = user?.bikes?.find(
      (bikeOfUser) => bikeOfUser?.id === bikeId
    );

    if (userBike) {
      const formattedBike = {
        ...userBike,
        data: {
          ...JSON.parse(userBike?.data || ""),
        },
      };

      setBike(formattedBike);
      setBatteryNum(formattedBike?.batteryNumber || "");
      setFrameNum(formattedBike?.frameNumber || "");
      setIdCode(formattedBike?.data?.user_id || "");
      setDescriptionValue(formattedBike?.data?.user_description);
      if (!formattedBike?.data?.boughtAt && !!formattedBike?.boughtAt) {
        const newDateArray = formattedBike?.boughtAt.split("/");
        setDate(
          new Date(`${newDateArray[1]}/${newDateArray[0]}/${newDateArray[2]}`)
        );
      } else {
        setDate(formattedBike?.data?.boughtAt);
      }
      setPrice(
        formattedBike?.data?.price > 12000
          ? formattedBike?.data?.price / 100
          : formattedBike?.data?.price
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, user]);

  const onDescriptionChange = (value) => {
    if (value.length <= 220) {
      setDescriptionValue(value);
    }
  };

  const onSelectFileTrigger = () => {
    addInvoiceInput.current.click();
  };

  const onSelectFile = (e) => {
    setInvoice(e.target.files[0]);
  };

  const onSelectPhotoTrigger = () => {
    addPhotoInput.current.click();
  };

  const onSelectPhoto = (e) => {
    setBikeImage(e.target.files[0]);
  };

  const onClearInvoice = () => {
    if (!!bike?.bikePassInvoice) {
      setIsInvoiceDeleted(true);
    }
    setInvoice(null);
  };

  const onCancel = () => {
    navigate(`${BIKE_PASS}/${bikeId}`);
  };

  const onSave = async () => {
    if (!user.firstName) {
      await updateUser({
        variables: {
          id: user?.id,
          user: {
            firstName,
          },
        },
      });
    }
    if (!user.lastName) {
      await updateUser({
        variables: {
          id: user?.id,
          user: {
            lastName,
          },
        },
      });
    }
    let newImageId;
    if (bikeImage) {
      const uploadImageRes = await uploadImage({
        variables: {
          id: bike.id,
          uploadFile: bikeImage,
        },
      });
      const newImagesIds = uploadImageRes?.data?.addBikeImage?.images?.map(
        (newImage) => newImage.documentId
      );
      const oldImagesIds = bike.images.map((oldImage) => oldImage.documentId);
      newImageId = newImagesIds.reduce((acc, newImageId) => {
        return oldImagesIds.includes(newImageId)
          ? [...acc]
          : [...acc, newImageId];
      }, [])[0];
    }

    await updateBike({
      variables: {
        id: bike.id,
        bike: {
          coverImageDocumentId: !!bikeImage
            ? newImageId
            : bike.coverImage.documentId,
          frameNumber,
          batteryNumber,
          data: JSON.stringify({
            ...bike?.data,
            user_id: idCode,
            user_description: descriptionValue,
            boughtAt: date,
            price,
          }),
          level: "FULL_FILLED",
        },
      },
    });
    if (!!invoice) {
      await uploadInvoice({
        variables: {
          in: { bikeId: bike.id, upload: invoice },
        },
      });
    }
    if (isInvoiceDeleted) {
      await deleteInvoice({
        variables: {
          bikeID: bike.id,
          docID: bike?.bikePassInvoice.id,
        },
      });
    }
    getUserQuery({
      variables: { id: user.id },
    }).then((res) => {
      dispatch(getCurrentUserSuccess(res.data.user));
      navigate(`${BIKE_PASS}/${bikeId}`);
    });
  };

  const Buttons = (
    <>
      <div className={styles.footerFiller}></div>
      <div className={styles.buttonsContainer}>
        <CancelButton onClick={onCancel} />
        <SaveButton onClick={onSave} />
      </div>
    </>
  );

  return (
    <DesktopViewWithHeader footer={Buttons}>
      <div className={styles.bikePassEdit}>
        <Helmet>
          <title>Bike Pass Edit</title>
          <meta content="Bike Pass Edit. Velio | Bike Pass details." />
        </Helmet>
        <div className={styles.pageTitle}>{t("bikePass.pageTitle")}</div>
        {bike?.coverImage?.isUserUploaded || bikeImage ? (
          <>
            <ExpandableSection className={styles.section}>
              <img
                className={styles.bikePhoto}
                alt="bike"
                src={
                  bikeImage
                    ? URL.createObjectURL(bikeImage)
                    : bike?.coverImage?.webReadUrl
                }
                onClick={() => {
                  setIsImageViewOpen(true);
                }}
              />
            </ExpandableSection>
            <div className={styles.replaceImageContainer}>
              <div
                className={styles.replaceImage}
                onClick={onSelectPhotoTrigger}
              >
                <div className={styles.replaceImageText}>
                  {t("bikePass.replaceImage")}
                </div>
                <div className={styles.replaceImageIcon}></div>
              </div>
            </div>
          </>
        ) : (
          <ExpandableSection className={styles.section}>
            <img
              className={styles.bikePhoto}
              alt="bike"
              src={DefaultBike}
              onClick={onSelectPhotoTrigger}
            />
            <div className={styles.addNewImage} onClick={onSelectPhotoTrigger}>
              <div className={styles.addNewImageIcon}></div>
              <div className={styles.addNewImageText}>
                {t("bikePass.addNewImage")}
              </div>
            </div>
          </ExpandableSection>
        )}
        <input
          type="file"
          accept="image/png, image/jpg"
          className={styles.addDocumentInput}
          ref={addPhotoInput}
          onChange={onSelectPhoto}
        />
        <div className={styles.subHeader}>{t("bikePass.bikeDetails")}</div>
        <div className={styles.subDescription}>
          {t("bikePass.bikeDetailsText")}
        </div>
        <div className={styles.mandatoryInputs}>
          {(!user.first_name || !user.last_name) && (
            <div className={styles.username}>
              {!user.first_name && (
                <div>
                  <div className={styles.inputLabel}>
                    {t("bikePass.firstName")}
                  </div>
                  <TextInput value={firstName} onChange={setFirstName} />
                </div>
              )}
              {!user.last_name && (
                <div>
                  <div className={styles.inputLabel}>
                    {t("bikePass.lastName")}
                  </div>
                  <TextInput value={lastName} onChange={setLastName} />
                </div>
              )}
            </div>
          )}
          {bike?.data?.bike_type?.toLowerCase() === "e-bike" && (
            <>
              <div className={styles.inputLabel}>
                {t("bikePass.batterySerialNumber")}
              </div>
              <TextInput value={batteryNumber} onChange={setBatteryNum} />
            </>
          )}
          <div className={styles.inputLabel}>{t("bikePass.frameNumber")}</div>
          <TextInput value={frameNumber} onChange={setFrameNum} />

          <div className={styles.inputLabel}>{t("bikePass.boughtAt")}</div>
          <DatePicker
            selected={Date.parse(date)}
            onChange={(newDate) => setDate(newDate)}
            className={styles.inputPicker}
            dateFormat="dd.MM.yyyy"
            placeholderText={t(
              "onboarding.insuranceBikeDetails.datePlaceholder"
            )}
            withPortal
            maxDate={new Date()}
            minDate={new Date().setDate(new Date().getDate() - 1825)}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            onFocus={(e) => e.target.blur()}
          />

          <div className={styles.inputLabel}>{t("bikePass.price")}</div>
          <TextInput value={price} onChange={setPrice} />
        </div>
        <div className={styles.subHeader}>{t("bikePass.invoice")}</div>
        <div className={styles.subDescription}>
          {invoice ? t("bikePass.invoiceSuccess") : t("bikePass.invoiceText")}
        </div>
        {!isInvoiceDisplayed && (
          <div className={styles.addDocument} onClick={onSelectFileTrigger}>
            <div className={styles.addDocumentText}>
              {t("bikePass.addDocument")}
            </div>
            <div className={styles.addDocumentIcon}></div>
            <input
              type="file"
              accept="image/png, image/jpg, application/pdf"
              className={styles.addDocumentInput}
              ref={addInvoiceInput}
              onChange={onSelectFile}
            />
          </div>
        )}
        {isInvoiceDisplayed && (
          <div className={styles.invoice}>
            <div
              className={styles.invoiceBody}
              onClick={() => {
                setIsInvoiceViewOpen(true);
              }}
            >
              <div className={styles.invoiceIcon}></div>
              <div className={styles.invoiceInfo}>
                <div className={styles.invoiceView}>
                  {t("bikePass.invoiceView")}
                </div>
                <div className={styles.invoiceUploadTime}>
                  {t("bikePass.wasUploaded", {
                    date: invoice
                      ? currentDate.toLocaleDateString()
                      : new Date(
                          bike?.bikePassInvoice.uploadedAt
                        ).toLocaleDateString(),
                  })}
                </div>
              </div>
            </div>
            <div className={styles.invoiceFooter} onClick={onClearInvoice}>
              <div className={styles.invoiceFooterText}>
                {t("bikePass.extinguish")}
              </div>
              <div className={styles.invoiceFooterIcon}></div>
            </div>
          </div>
        )}
        <CollapsibleSection
          isCollapsible
          isCollapsedByDefault
          className={styles.optionalSection}
          title={t("bikePass.optional")}
        >
          <div className={styles.inputLabel}>
            {t("bikePass.identificationCode")}
          </div>
          <TextInput value={idCode} onChange={setIdCode} />
          <div className={styles.inputLabel}>
            {t("bikePass.bikeDescription")}
          </div>
          <div className={styles.subDescription}>
            {t("bikePass.descriptionsDescription")}
          </div>
          <TextAreaInput
            className={styles.descriptionInput}
            value={descriptionValue}
            onChange={onDescriptionChange}
          />
          <div className={styles.subDescription}>
            {t("bikePass.charactersLeft", {
              count: 220 - (descriptionValue?.length || 0),
            })}
          </div>
        </CollapsibleSection>
        {!isDesktop && Buttons}
      </div>
      <ImageViewModal
        isOpen={isImageViewOpen}
        onClose={() => {
          setIsImageViewOpen(false);
        }}
        src={
          bikeImage
            ? URL.createObjectURL(bikeImage)
            : bike?.coverImage?.isUserUploaded
            ? bike?.coverImage?.webReadUrl
            : ""
        }
      />
      <ImageViewModal
        isOpen={isInvoiceViewOpen}
        onClose={() => {
          setIsInvoiceViewOpen(false);
        }}
        src={
          invoice
            ? URL.createObjectURL(invoice)
            : bike?.bikePassInvoice
            ? bike?.bikePassInvoice?.readUrl
            : ""
        }
      />
    </DesktopViewWithHeader>
  );
};
export default BikePassEdit;
