import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getUser } from "../../redux/auth/authSelectors";

import {
  GARAGE,
  MY_BIKE,
  INVITE_FRIEND,
  DEALER,
  BIKE_PASS,
  BIKE_PASS_EDIT,
  INSURANCE_GARAGE,
} from "../../constants/routes";
import { useWindowWidth } from "../../hooks/useWindowWidth";

import styles from "./Navigation.module.css";

const navLinks = [
  [GARAGE, MY_BIKE, INVITE_FRIEND, DEALER, BIKE_PASS, BIKE_PASS_EDIT],
  [INSURANCE_GARAGE],
];

export default function Navigation() {
  const { t } = useTranslation();
  const user = useSelector(getUser) || {};
  const isDesktop = useWindowWidth() > 1280;
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const rootPathname = `/${pathname.split("/")[1]}`;
  const linkOrigin =
    window.location.origin === "https://my.velio.de"
      ? "https://velio.de"
      : "https://circular.velio.dev";

  const getIsActive = (link) =>
    navLinks
      .find((navLinksArray) =>
        navLinksArray.some((navLink) => navLink === link)
      )
      .some((navLink) => navLink === rootPathname);

  const NavigationMenu = () => (
    <>
      <a className={styles.link} href={`${linkOrigin}/shop`}>
        {t("header.shop")}
      </a>
      {user?.bikes?.some((bike) => bike?.insurance) && (
        <div
          className={`${styles.link} ${
            getIsActive(INSURANCE_GARAGE) ? styles.active : ""
          }`}
          onClick={() => navigate(INSURANCE_GARAGE)}
        >
          {t("header.insurance")}
        </div>
      )}

      <div
        className={`${styles.link} ${getIsActive(GARAGE) ? styles.active : ""}`}
        onClick={() => navigate(GARAGE)}
      >
        {t("header.bikeManager")}
      </div>
      <a className={styles.link} href={`${linkOrigin}/sell`}>
        {t("header.toSell")}
      </a>
    </>
  );

  return (
    <>
      <div className={styles.navigation}>
        <div className={styles.navigationTopRow}>
          <div className={styles.navigationGroup}>
            <a className={styles.logo} href={`${linkOrigin}/" alt="logo`}>
              Logo
            </a>

            {isDesktop && <NavigationMenu />}
          </div>
          <div className={styles.navigationGroup}>
            <a
              className={styles.iconLink}
              href={`${linkOrigin}/account`}
              alt="account"
            >
              <div className={`${styles.icon} ${styles.profileIcon}`} />
            </a>
            <a
              className={styles.iconLink}
              href={`${linkOrigin}/wishlist`}
              alt="wishlist"
            >
              <div className={`${styles.icon} ${styles.wishListIcon}`} />
            </a>
            <a
              className={styles.iconLink}
              href={`${linkOrigin}/cart`}
              alt="cart"
            >
              <div className={`${styles.icon} ${styles.cartIcon}`} />
            </a>
          </div>
        </div>
        {!isDesktop && (
          <div className={styles.navigationBottomRow}>
            <NavigationMenu />
          </div>
        )}
      </div>
      <div className={styles.filler} />
    </>
  );
}
