import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";

import DesktopView from "../../../components/DesktopView";
import TextInput from "../../../components/Inputs/Text";
import ExpandableSection from "../../../components/ExpandableSection";
import {
  GET_BIKE_MANUFACTURERS,
  GET_BIKE_SHRINK_MODELS_WITH_IMAGES,
  GET_BIKE_SHRINK_TEMPLATE,
} from "../../../services/query";
import {
  BIKE_OPTIONS,
  MODEL,
  MANUFACTURER,
  ADD_OPTION,
  CREATE_PROFILE,
  ONBOARDING_PRICE,
} from "../../../constants/routes";
import bikeActions from "../../../redux/bike/bikeActions";
import Loader from "../../../components/Loader";
import popularBrandsList from "./constants/popularBrandsList";
import sortStringsByFirstLetter from "./utils/sortStringsByFirstLetter";
import filterSortedByString from "./utils/filterSortedByString";
import { getUserId } from "../../../redux/auth/authSelectors";
import normalizeDateForBE from "../../../assets/helpers/normalizeDateForBE";
import { getBike, dealerInfo } from "../../../redux/bike/bikeSelectors";
import authActions from "../../../redux/auth/authActions";
import { CREATE_BIKE } from "../../../services/mutation";
import { GET_USER } from "../../../services/query";

import styles from "./BikeOptions.module.css";

const BikeOptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userId = useSelector(getUserId);
  const [newBike] = useMutation(CREATE_BIKE);
  const focusedBikeData = useSelector(getBike);
  const mode = location.pathname.split("/")[2];
  const [options, setOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filterString, setFilterString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { addFocusedBikeData } = bikeActions;
  const { getCurrentUserSuccess } = authActions;
  const dealerInState = useSelector(dealerInfo);
  const [getUserQuery] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });

  const [getManufacturers] = useLazyQuery(GET_BIKE_MANUFACTURERS, {
    variables: {
      filter: {
        bikeType: focusedBikeData?.type,
      },
    },
  });

  const [getModels] = useLazyQuery(GET_BIKE_SHRINK_MODELS_WITH_IMAGES, {
    variables: {
      manufactureName: focusedBikeData?.manufacturer,
      filter: {
        bikeType: focusedBikeData.type,
      },
    },
    skip: !focusedBikeData?.manufacturer,
  });

  const [getBikeTemplate] = useLazyQuery(GET_BIKE_SHRINK_TEMPLATE);

  const onBack = () => {
    setOptions([]);
    navigate(-1);
  };

  const onSubmitClick = async () => {
    setOptions([]);
    setSearchValue("");
    if (mode === "manufacturer") {
      dispatch(
        addFocusedBikeData({
          manufacturer: selectedOption,
        })
      );
      navigate(`${BIKE_OPTIONS}${MODEL}`);
    } else {
      const bikeTemplate = await getBikeTemplate({
        variables: {
          in: {
            manufacturer: focusedBikeData?.manufacturer,
            model: selectedOption.name,
            bikeType: focusedBikeData?.type,
          },
        },
      });
      dispatch(
        addFocusedBikeData({
          ...bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel,
          bikeData: JSON.stringify({
            ...JSON.parse(
              bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel
                .bikeData
            ),
            year: null,
            color: null,
            frame_size: null,
          }),
        })
      );
      if (userId) {
        const bikeDocument = JSON.parse(
          bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel.bikeData
        );
        bikeDocument.is_used =
          bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel.isUsed;
        bikeDocument.price = null;
        bikeDocument.year = null;
        bikeDocument.color = null;
        bikeDocument.frame_size = null;
        if (bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel.id) {
          bikeDocument.bike_id =
            bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel.id;
        }
        if (!bikeDocument.manufacturer) {
          bikeDocument.manufacturer =
            bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel.manufacturer;
        }
        if (!bikeDocument.model_name) {
          bikeDocument.model_name =
            bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel.model;
        }
        if (!bikeDocument.boughtAt) {
          bikeDocument.boughtAt = normalizeDateForBE(
            bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel
              ?.boughtAt?.value
          );
        }
        const bike = {
          data: JSON.stringify(bikeDocument),
        };

        if (bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel.id) {
          bike.template_id =
            bikeTemplate.data.bikeTemplateByManufacturerAndShrinkModel.id;
        }
        if (dealerInState.id) {
          bike.dealerID = dealerInState.id;
        }
        newBike({
          variables: { bike },
        })
          .then((createdBikeRes) => {
            return getUserQuery({
              variables: { id: userId },
            }).then((res) => {
              dispatch(
                addFocusedBikeData({
                  ...createdBikeRes.data.createBike,
                })
              );
              dispatch(getCurrentUserSuccess(res.data.user));
            });
          })
          .then(() => {
            navigate(ONBOARDING_PRICE);
          });
      } else {
        navigate(`${CREATE_PROFILE}`);
      }
    }
    setSelectedOption("");
  };

  const onNotListedClick = () => {
    if (mode === "manufacturer") {
      navigate(`${BIKE_OPTIONS}${MANUFACTURER}${ADD_OPTION}`);
    } else {
      navigate(`${BIKE_OPTIONS}${MODEL}${ADD_OPTION}`);
    }
  };

  useEffect(() => {
    const setCurrentOptions = async () => {
      if (mode === "manufacturer") {
        setIsLoading(true);
        const newOptions = await getManufacturers();
        setIsLoading(false);
        setOptions(sortStringsByFirstLetter(newOptions.data.bikeManufacturers));
      } else if (mode === "model") {
        setIsLoading(true);
        const newOptions = await getModels();
        setIsLoading(false);
        setOptions(
          sortStringsByFirstLetter(
            newOptions.data.bikeManufacturerShrinkModelsWithImages
          )
        );
      }
    };
    setCurrentOptions();
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilterString(searchValue);
    }, 450);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const filteredOptions = filterSortedByString(options, filterString);

  return (
    <DesktopView>
      <Helmet>
        <title>{t("bikeOptionsPageTitle")}</title>
        <meta name="theme-color" content="#F3F3F3" />
      </Helmet>
      <div className={styles.bikeOptions}>
        <div className={styles.header}>
          <div className={styles.backButton} onClick={onBack}></div>
          <div className={styles.pageTitle}>
            {t(`onboarding.bikeOptions.${mode}.pageTitle`)}
          </div>
        </div>
        <div className={styles.searchBar}>
          <TextInput
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
            placeholder={t(`onboarding.bikeOptions.${mode}.searchPlaceholder`)}
          />
        </div>

        {mode === "manufacturer" && (
          <div className={styles.popular}>
            <div className={styles.sectionTitle}>
              {t(`onboarding.bikeOptions.${mode}.popular`)}
            </div>
            <div className={styles.popularList}>
              <div className={styles.popularListContainer}>
                {popularBrandsList.map((brand, i) =>
                  brand.type === focusedBikeData.type ||
                  brand.type === "both" ? (
                    <div
                      className={`${styles.popularElem} ${
                        brand.value === selectedOption
                          ? styles.selectedPopularElem
                          : ""
                      }`}
                      key={brand.value}
                      onClick={() => {
                        setSelectedOption(brand.value);
                      }}
                    >
                      <div
                        className={styles.brandIcon}
                        style={{
                          backgroundPositionX: `${-i * 56}px`,
                        }}
                      />
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>
        )}
        <div className={styles.options}>
          <div className={styles.sectionTitle}>
            {t(`onboarding.bikeOptions.${mode}.optionsTitle`)}
          </div>
          <ExpandableSection className={styles.optionsBody}>
            <div className={`${styles.optionsHeader}`}>
              <div
                className={styles.optionsHeaderElem}
                onClick={onNotListedClick}
              >
                {t(`onboarding.bikeOptions.${mode}.notListed`)}
              </div>
            </div>
            {isLoading && <Loader className={styles.loader} />}
            {filteredOptions?.map((optionsChunk) => (
              <div className={styles.optionsChunk} key={optionsChunk.title}>
                <div className={styles.optionsChunkHeader}>
                  {optionsChunk.title}
                </div>
                {optionsChunk?.items?.map((option) => (
                  <div
                    className={styles.option}
                    key={option.name}
                    onClick={() => {
                      setSelectedOption(option);
                    }}
                  >
                    <div
                      className={`${styles.optionText} ${
                        selectedOption === option
                          ? styles.selectedOptionText
                          : ""
                      }`}
                    >
                      {option?.name || option}
                    </div>
                    {mode === "model" && (
                      <div
                        className={styles.optionImage}
                        style={{
                          backgroundImage: `url(${option.image.thumbnailReadUrl}`,
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </ExpandableSection>
        </div>
      </div>
      <div className={styles.footerFiller} />
      {!!selectedOption && (
        <div className={styles.footer}>
          <div className={styles.submitButton} onClick={onSubmitClick}>
            <div className={styles.submitButtonText}>
              {t(`onboarding.bikeOptions.continue`)}
            </div>
            <div className={styles.submitButtonIcon} />
          </div>
        </div>
      )}
    </DesktopView>
  );
};

export default BikeOptions;
