import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GET_ALL_CHECKBOOK } from "../../services/query";
import { normalizedDate } from "../../utils/normalizedDate";

import styles from "./Checkbook.module.css";
import { CHECK_BOOK, MY_BIKE } from "../../constants/routes";

export default function ListEvents({ bikeId, limit = 10 }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_ALL_CHECKBOOK, {
    variables: { bike_id: bikeId, limit },
    fetchPolicy: "network-only",
  });

  const checkBookList = data?.checkBooksForBike || [];

  const goToViewEvent = (eventType, id) => {
    navigate(`${MY_BIKE}/${bikeId}${CHECK_BOOK}/${eventType}/${id}`);
  };

  if (loading)
    return (
      <div className={styles.LoadingContainer}>
        <p className={styles.LoadingText}>Loading...</p>
      </div>
    );
  if (error) return null;

  return checkBookList.length ? (
    <div className={styles.listEvents}>
      <hr className={styles.hr} />
      <p className={styles.nextInspection}>{t("checkbook.previousEvent")}</p>
      {checkBookList.map((event) => (
        <div
          key={event.id}
          onClick={() => goToViewEvent(event.eventType, event.id)}
          className={styles.card}
        >
          <div key={event.id} className={styles.cardHeader}>
            <p className={styles.cardHeaderText}>
              {event.eventType === "repair"
                ? t("checkbook.repairCardHeader")
                : t("checkbook.inspectionCardHeader")}
            </p>
            <span className={styles.completedLabel}>
              {t("checkbook.completedLabel")}
            </span>
          </div>
          <p className={styles.dateEntry}>
            {normalizedDate(new Date(event.date))}
          </p>
        </div>
      ))}
    </div>
  ) : null;
}
