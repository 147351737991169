import CustomLabelCheckbox from "../CustomLabelCheckbox/CustomLabelCheckbox";

import styles from "./CustomCheckbox.module.css";

const CustomCheckbox = ({
  id,
  type,
  value,
  children,
  onClick,
  error = false,
}) => {
  return (
    <div className={styles.wrapperCheckbox}>
      <input id={id} type={type} className={styles.customCheckbox} />
      <CustomLabelCheckbox
        onClick={onClick}
        htmlFor={id}
        className={error ? styles.checkboxTextError : styles.checkboxText}
        value={value}
      />
      {children}
    </div>
  );
};
export default CustomCheckbox;
