import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import CollapsibleSection from "../../components/CollapsibleSection";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import Map from "../../components/Map";
import { GARAGE } from "../../constants/routes";
import { getUser } from "../../redux/auth/authSelectors";

import styles from "./Dealer.module.css";

const Dealer = () => {
  const user = useSelector(getUser) || {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bikeId = useLocation().pathname.split("/")[2];
  const bikeRaw = user?.bikes?.find((bike) => bike.id === bikeId);
  const bike = { ...bikeRaw, data: { ...JSON.parse(bikeRaw?.data || null) } };
  const dealer = bike?.dealer;
  const socialMedia = dealer?.socialMedia && JSON.parse(dealer.socialMedia)[0];
  const openingHours = JSON.parse(dealer?.openingHours || null);

  const getPhones = () => {
    const phones = Object.keys(dealer?.phone);
    if (phones.length === 0) {
      return;
    } else if (phones.length === 1) {
      return (
        <>
          <div className={styles.subTitle}>{t("dealer.phoneNumbers")}</div>
          <a
            className={`${styles.phoneNumber} ${styles.link}`}
            href={`tel:${dealer?.phone[phones[0]]}`}
          >
            {dealer?.phone[phones[0]]}
          </a>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.subTitle}>{t("dealer.phoneNumbers")}</div>
          <a
            className={`${styles.phoneNumber} ${styles.link}`}
            h
            href={`tel:${dealer?.phone[phones[1]]}`}
          >
            {dealer?.phone[phones[0]]}
          </a>
          <div className={styles.phoneNumberSubtitle}>{t("dealer.sales")}</div>
          <a
            className={`${styles.phoneNumber} ${styles.link}`}
            href={`tel:${dealer?.phone[phones[1]]}`}
          >
            {dealer?.phone[phones[1]]}
          </a>
          <div className={styles.phoneNumberSubtitle}>{t("dealer.repair")}</div>
        </>
      );
    }
  };

  return (
    <DesktopViewWithHeader>
      {!!dealer ? (
        <div className={styles.dealer}>
          <div className={styles.header}>
            <div
              className={styles.backButton}
              onClick={() => {
                navigate(GARAGE);
              }}
            ></div>
            <div className={styles.pageTitle}>{t("dealer.pageTitle")}</div>
          </div>
          <CollapsibleSection className={styles.section}>
            <div className={styles.bikeSection}>
              <img
                className={styles.bikeImage}
                src={bike?.coverImage?.thumbnailReadUrl}
                alt={bike?.extendedModel || "cover image"}
              ></img>
              <div className={styles.bikeData}>
                <div className={styles.brandName}>
                  {bike?.data.manufacturer}
                </div>
                <div className={styles.modelName}>{bike?.data.model_name}</div>
              </div>
            </div>
          </CollapsibleSection>
          <CollapsibleSection className={styles.section}>
            <div className={styles.dealerName}>{dealer?.name}</div>
            {(dealer?.address?.housenumber || dealer?.address?.streetname) && (
              <>
                <div className={styles.address}>
                  {dealer?.address?.streetname} {dealer?.address?.housenumber}
                </div>
              </>
            )}
            {(dealer?.address?.postcode || dealer?.address?.city) && (
              <>
                <div className={styles.zip}>
                  {dealer?.address?.postcode}, {dealer?.address?.city}
                </div>
              </>
            )}
            {
              (dealer.location?.lat && dealer.location?.long) && (
                <div className={styles.map}>
                  <Map
                    url={`https://www.google.com/maps/d/embed?mid=1woHeNiQaXvtsjXIeJNxba13T3-bSTGwM&ehbc=2E312F&z=14&ll=${dealer.location.lat}%2C${dealer.location.long}`}
                  />
              </div>
              )
            }
            {
              dealer.mapsLink && (
                <div
                  className={`${styles.openMaps} ${styles.link}`}
                  onClick={() => {
                    window.open(
                      dealer.mapsLink,
                      "_blank"
                    );
                  }}
                >
                  {t("dealer.openGoogleMaps")}
                </div>
              )
            }
              
            {getPhones()}
            {dealer.website && (
              <>
                <div className={styles.subTitle}>{t("dealer.website")}</div>
              <div
                className={`${styles.website} ${styles.link}`}
                onClick={() => {
                  window.open(
                    dealer.website,
                    "_blank"
                  );
                }}
              >
                {dealer.website}
              </div>
              </>
            )}
            <div className={styles.subTitle}>{t("dealer.eMail")}</div>
            <div
              className={`${styles.email} ${styles.link}`}
              onClick={(e) => {
                window.location.href = `mailto: ${dealer?.email}`;
                e.preventDefault();
              }}
            >
              {dealer?.email}
            </div>
            {(socialMedia?.instagram ||
              socialMedia?.facebook) && (
              <>
                <div className={styles.subTitle}>
                  {t("dealer.socialNetwork")}
                </div>
                {socialMedia?.instagram && (<a
                  className={`${styles.instagram} ${styles.link}`}
                  href={socialMedia?.instagram}
                  alt=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>)}
                {socialMedia?.facebook && (<a
                  className={`${styles.facebook} ${styles.link}`}
                  href={socialMedia?.facebook}
                  alt=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>)}
              </>
            )}

            {openingHours && openingHours.length && (
              <>
                <div className={styles.subTitle}>
                  {t("dealer.openingHours")}
                </div>
                {openingHours.map((openHourPair, i) => (
                  <div className={styles.openHoursBlock} key={i}>
                    <div className={styles.dayName}>{Object.keys(openHourPair)[0]}</div>
                    <div className={styles.openHoursData}>
                      <div className={styles.openHoursDataPiece}>
                        {
                          openHourPair[Object.keys(openHourPair)[0]].split(' ').map((openHOurInterval) => (
                            <div key={openHOurInterval}>{openHOurInterval}</div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </CollapsibleSection>
        </div>
      ) : (
        <h3 className={styles.loading}>Loading...</h3>
      )}
    </DesktopViewWithHeader>
  );
};

export default Dealer;
