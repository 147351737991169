/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, matchPath, Navigate } from "react-router-dom";
import Cookies from 'js-cookie';

import { publicRoutes,
  privateRoutes,
  onboardingRoutes,
  insuranceRoutes,
  GARAGE,
  LOGIN,
  WELCOME,
  MY_BIKE
} from "../../../constants/routes";
import { getIsSignedPreviously } from "../../../redux/auth/authSelectors";

const AccessRouteWrapper = ({children}) => {
  const [isValid, setIsValid] = useState(true);
  const [redirectRoute, setRedirectRoute] = useState('/');
  const [redirectState, serRedirectState] = useState({});
  const isSignedPreviously = useSelector(getIsSignedPreviously);
  const location = useLocation();
  const commercetoolsCookie = Cookies.get('CTAT');
  useEffect(()=> {
    const isPublicRoute = publicRoutes.reduce((res, publicRoute) => res || !!matchPath({path: publicRoute, end: true}, location.pathname), false);
    const isPrivateRoute = privateRoutes.reduce((res, privateRoute) => res || !!matchPath({path: privateRoute, end: true}, location.pathname), false);
    const isOnboardingRoute = onboardingRoutes.reduce((res, onboardingRoute) => res || !!matchPath({path: onboardingRoute, end: true}, location.pathname), false);
    const isInsuranceRoutes = insuranceRoutes.reduce((res, insuranceRoute) => res || !!matchPath({path: insuranceRoute, end: true}, location.pathname), false);
    if (!!matchPath({path: `${MY_BIKE}/:id`, end: true}, location.pathname) && !commercetoolsCookie && isSignedPreviously) {
      setIsValid(false);
      setRedirectRoute(LOGIN);
    } else if(isOnboardingRoute && !commercetoolsCookie && isSignedPreviously) {
      setIsValid(false);
      setRedirectRoute(LOGIN);
    } else if(isInsuranceRoutes && !commercetoolsCookie && isSignedPreviously) {
      setIsValid(false);
      setRedirectRoute(LOGIN);
    } else if(isPublicRoute) {
      if(!!commercetoolsCookie) {
        setIsValid(false);
        setRedirectRoute(GARAGE);
      } else {
        setIsValid(true);
      }
    } else if (isPrivateRoute) {
      if(!!commercetoolsCookie) {
        setIsValid(true);
      } else if(!!commercetoolsCookie) {
        setIsValid(false);
        setRedirectRoute(LOGIN);
        serRedirectState({from: location.pathname});
      } else {
        setIsValid(false);
        setRedirectRoute(WELCOME);
      }
    } else {
      setIsValid(true);
    }
  }, [location]);
  return isValid
    ? children
    : <Navigate replace to={redirectRoute} state={redirectState}/>;
}

export default AccessRouteWrapper;
