import styles from "./Text.module.css";

const Text = ({
  value,
  onChange,
  placeholder,
  type,
  className,
  inputRef,
  onKeyDown,
}) => {
  return (
    <input
      ref={inputRef}
      type={type || "text"}
      className={`${styles.text} ${className}`}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
    />
  );
};

export default Text;
