import React from "react";

import styles from "./RadioInput.module.css";

const RadioInput = React.forwardRef(
  ({ children, id, name, value, label, onChange, t, ...props }, ref) => {
    return (
      <div className={styles.container}>
        <label>
          <input
            className={styles.radio}
            ref={ref}
            id={id}
            name={name}
            type="radio"
            value={value}
            onChange={onChange}
            {...props}
          />
          {label}
        </label>
      </div>
    );
  }
);

export default RadioInput;
