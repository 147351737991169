import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GET_CHECKBOOK, GET_FILE } from "../../services/query";
import { normalizedDate } from "../../utils/normalizedDate";
import Banner from "../../components/Banner";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import { CHECK_BOOK, MY_BIKE } from "../../constants/routes";

import styles from "./Inspection.module.css";

const getMessages = (t) => {
  return {
    repair: {
      pageTitle: t("checkbook.repairCardHeader"),
    },
    inspection: {
      pageTitle: t("checkbook.inspectionCardHeader"),
    },
  };
};

export default function Inspection() {
  const params = useParams();
  const { checkBookId, bikeId, eventType } = params;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const labels = getMessages(t);
  const messages = labels[params.eventType];
  const { data, loading, error } = useQuery(GET_CHECKBOOK, {
    variables: { id: checkBookId },
  });
  const checkBook = data?.checkBook;
  const { data: fileData } = useQuery(GET_FILE, {
    variables: { id: checkBook?.documentID },
    skip: !checkBook?.documentID,
    fetchPolicy: "network-only",
  });
  const fileUrl = fileData?.file.readUrl;

  const repairBy = checkBook?.notes
    ? checkBook.notes.includes(" - ")
      ? checkBook.notes.split(" - ")[0]
      : ""
    : "";
  const descriptionText = checkBook?.notes
    ? checkBook.notes.includes(" - ")
      ? checkBook.notes.split(" - ")[1]
      : checkBook.notes
    : "";
  const goToCheckbook = () => {
    navigate(`${MY_BIKE}/${bikeId}${CHECK_BOOK}`);
  };

  const goToEditPage = () => {
    navigate("edit", { state: checkBook });
  };

  if (loading)
    return (
      <div className={styles.LoadingContainer}>
        <p className={styles.LoadingText}>Loading...</p>
      </div>
    );
  if (error)
    return (
      <div className={styles.LoadingContainer}>
        <p className={styles.LoadingText}>
          Sorry, we cannot find the page, Please try again.
        </p>
      </div>
    );
  return (
    <>
      <Helmet>
        <title>View Event</title>
        <meta name="description" content="Checkbook - Velio" />
      </Helmet>
      <DesktopViewWithHeader>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <div className={styles.backButton} onClick={goToCheckbook}></div>
            <div className={styles.pageTitle}>{messages.pageTitle}</div>
            <div></div>
          </div>
          <Banner />
          <p className={styles.viewHeader}>{t("checkbook.dateOfRepair")}</p>
          <p className={styles.viewValue}>
            {normalizedDate(new Date(checkBook.date))}
          </p>

          {checkBook.eventType === "repair" && (
            <>
              <p className={styles.viewHeader}>{t("checkbook.repairBy")}</p>
              <p className={styles.viewValue}>{repairBy}</p>
            </>
          )}
          <p className={styles.viewHeader}>{t("checkbook.description")}</p>
          <p className={styles.viewValue}>{descriptionText}</p>

          {checkBook?.documentID ? (
            <div>
              <p className={styles.viewHeader}>
                {t("checkbook.invoiceAndEvidence")}
              </p>
              <a target="_blank" rel="noreferrer" href={fileUrl} download>
                <div className={`${styles.card} ${styles.cursor}`}>
                  <div className={styles.viewDocument}>
                    <div className={styles.bigFileIcon}></div>
                    <div>
                      <p className={styles.documentText}>
                        {t("checkbook.viewFile")}
                      </p>
                      <p className={styles.documentSubtitle}>
                        {t("checkbook.documentUploaded")}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ) : null}

          <div
            onClick={goToEditPage}
            className={`${styles.card} ${styles.round}`}
          >
            <div className={styles.document}>
              <p className={`${styles.documentText} ${styles.viewDocument}`}>
                {eventType === "repair"
                  ? t("checkbook.editRepair")
                  : t("checkbook.editInspection")}
              </p>
              <div className={styles.pencilIcon}></div>
            </div>
          </div>
        </div>
      </DesktopViewWithHeader>
    </>
  );
}
