import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import styles from "../DashboardPage/DashboardPage.module.css";

const DashboardPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{t("dashboardPageTitle")}</title>
        <meta name="description" content="Dashboard. Velio | User Dashboard." />
      </Helmet>
      <div className={styles.productCard}>
        <div className={styles.productCardHeader}>
          <span className={styles.productCardLogo}></span>
          <span className={styles.productCardLabel}>Versichert</span>
        </div>
        <div className={styles.dashboaedBikeImageWrapper}>
          <span className={styles.dashboaedBikeImage}></span>
        </div>
        <div className={styles.productCardFooter}>
          <p className={styles.productCardFooterTitle}>Cube</p>
          <div className={styles.productCardFooterDescr}>
            <p className={styles.productName}>Cross X23</p>
            <div className={styles.productCardDetails}>
              <p className={styles.productCardDetailsTitle}>Details</p>
              <span className={styles.productCardDetailsIcon}></span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.dashboardPageIconsWrapper}>
        <div className={styles.iconsContentWrapper}>
          <span className={styles.iconsWrapper}>
            <span className={styles.firstIcon}></span>
          </span>
          <p className={styles.dashboardIconDescr}>Teilen</p>
        </div>

        <div className={styles.iconsContentWrapper}>
          <span className={styles.iconsWrapper}>
            <span className={styles.secondIcon}></span>
          </span>
          <p className={styles.dashboardIconDescr}>Foto</p>
        </div>

        <div className={styles.iconsContentWrapper}>
          <span className={styles.iconsWrapper}>
            <span className={styles.thirdIcon}></span>
          </span>
          <p className={styles.dashboardIconDescr}>Dokument</p>
        </div>

        <div className={styles.iconsContentWrapper}>
          <span className={styles.iconsWrapper}>
            <span className={styles.fourthIcon}></span>
          </span>
          <p className={styles.dashboardIconDescr}>Schaden</p>
        </div>

        <div className={styles.iconsContentWrapper}>
          <span className={styles.iconsWrapper}>
            <span className={styles.fifthIcon}></span>
          </span>
          <p className={styles.dashboardIconDescr}>Diebstahl</p>
        </div>
      </div>

      <div className={styles.dashboardDetailsCardWrapper}>
        <div className={styles.dashboardDetailsCard}>
          <div className={styles.dashboardDetailsCardFirst}>
            <div>
              <p className={styles.dashboardDetailsCardTitle}>Fahrradpass</p>
              <p className={styles.dashboardDetailsCardSubtitleFirst}>
                Fertigstellen
              </p>
            </div>
            <span className={styles.progressCircleIcon}></span>
          </div>
        </div>
        <div className={styles.dashboardDetailsCard}>
          <p className={styles.dashboardDetailsCardTitle}>Checkbook</p>
          <p className={styles.dashboardDetailsCardSubtitle}>3 Inspektionen</p>
        </div>
        <div className={styles.dashboardDetailsCard}>
          <p className={styles.dashboardDetailsCardTitle}>Händler</p>
          <p className={styles.dashboardDetailsCardSubtitle}>Fahrrad Schmitt</p>
        </div>
        <div className={styles.dashboardDetailsCard}>
          <p className={styles.dashboardDetailsCardTitle}>Dokumente</p>
          <p className={styles.dashboardDetailsCardSubtitle}>7 Files</p>
        </div>
      </div>
      <div className={styles.mainMapWrapper}>
        <div className={styles.mapWrapper}>
          <div className={styles.mapTitleWrapper}>
            <p className={styles.mapTitle}>Ladestation</p>
            <span className={styles.arrowIcon}></span>
          </div>
          <span className={styles.mapImage}></span>
        </div>
      </div>
      <div className={styles.mainContactWrapper}>
        <div className={styles.contactWrapper}>
          <span className={styles.contactItem}>Kontakt</span>
          <span className={styles.contactItem}>Freunde einladen</span>
          <span className={styles.instagramIconWrapper}>
            <span className={styles.instagramIcon}></span>
          </span>
        </div>

        <div className={styles.mailwrapper}>
          <span className={styles.mailIcon}></span>
          <span className={styles.mailtext}>Newsletter Anmeldung</span>
        </div>
      </div>
      <div className={styles.dashboardPageFooter}>
        <div className={styles.footerIconsWrapper}>
          <span className={styles.bikeIcon}></span>
          <p className={styles.footerIconTextFirst}>My Bikes</p>
        </div>
        <div className={styles.footerIconsWrapper}>
          <span className={styles.trailingIconDone}></span>
          <p className={styles.footerIconText}>Versicherung</p>
        </div>
        <div className={styles.footerIconsWrapper}>
          <span className={styles.userIcon}></span>
          <p className={styles.footerIconText}>Account</p>
        </div>
      </div>
      <div className={styles.dashboardPageInfoWrapper}>
        <div className={styles.dashboardPageInfo}>
          <span className={styles.letterIcon}></span>
          <div className={styles.bottomWrapper}>
            <span className="icon-small-lock"></span>
            <span className={styles.text}>velio.de</span>
          </div>
          <span className={styles.returnArrow}></span>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
