import { createListenerMiddleware } from "@reduxjs/toolkit";
import authActions from "./authActions";

const { registrationSuccess, signInSuccess, signOutSuccess } = authActions;

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: registrationSuccess,
  effect: (action) => {
    localStorage.setItem("isSignedPreviously", true);
  },
});

listenerMiddleware.startListening({
  actionCreator: signInSuccess,
  effect: (action) => {
    localStorage.setItem("isSignedPreviously", true);
  },
});


listenerMiddleware.startListening({
  actionCreator: signOutSuccess,
  effect: () => {
    localStorage.removeItem("isSignedPreviously");
  },
});

export default listenerMiddleware;
