import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import bikeActions from "./bikeActions";

import { loadState } from "../storeSerializer";

const previousData = loadState("bike");

const initialBikeData = {
  id: "",
  manufacturer: "",
  category: "",
  model: "",
  description: "",
  image: "",
  weight: 0,
  frame_size: 0,
  color: "",
  gender: "",
  price: { value: "", error: "" },
  dealer: "",
  year: 0,
  type: "E_BIKE",
  boughtAt: { value: "", error: "" },
  frameNumber: { value: "", error: "" },
  isUsed: false,
};

const initialState = {
  items: { ...initialBikeData },
  dealerInfo: {},
  onboarding: {
    firstStepComplete: false,
    secondStepComplete: false,
    thirdStepComplete: false,
  },
  insurance: {
    firstStepComplete: false,
    secondStepComplete: false,
    thirdStepComplete: false,
    fourthStepComplete: false,
  },
  isLoading: false,
  error: "",
  ...previousData,
};

const items = createReducer(initialState.items, {
  [bikeActions.getBikeSuccess]: (_, { payload }) => payload,
  [bikeActions.addFocusedBikeData]: (
    state = initialState.items,
    { payload }
  ) => {
    return { ...state, ...payload };
  },
  [bikeActions.resetFocusedBikeData]: () => ({ ...initialBikeData }),
});

const onboarding = createReducer(initialState.onboarding, {
  [bikeActions.addStepOnboarding]: (state, { payload }) => {
    return { ...state, ...payload };
  },
});

const dealerInfo = createReducer(initialState.dealerInfo, {
  [bikeActions.setDealerData]: (_, { payload }) => payload,
});

const insurance = createReducer(initialState.insurance, {
  [bikeActions.addStepInsurance]: (state, { payload }) => {
    return { ...state, ...payload };
  },
});

const isLoading = createReducer(initialState.isLoading, {
  [bikeActions.getBikeRequest]: () => true,
  [bikeActions.getBikeSuccess]: () => false,
  [bikeActions.getBikeError]: () => false,
  [bikeActions.addFocusedBikeDataRequest]: () => true,
  [bikeActions.addFocusedBikeDataSuccess]: () => false,
  [bikeActions.addFocusedBikeDataError]: () => false,
});

const error = createReducer(initialState.error, {
  [bikeActions.getBikeError]: (_, { payload }) => payload,
  [bikeActions.addFocusedBikeDataError]: (_, { payload }) => payload,
  [bikeActions.addFocusedBikeDataError]: (state, { payload }) => {
    return { ...state, ...payload };
  },
});

export default combineReducers({
  items,
  dealerInfo,
  error,
  isLoading,
  onboarding,
  insurance,
});
