import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";

import { getBikes, getUserId } from "../../../redux/auth/authSelectors";
import Fields from "./Fields";
import Buttons from "./Buttons";
import getBikeDocument from "../utils/getBikeDocument";
import { UPDATE_BIKE } from "../../../services/mutation";
import { GET_USER } from "../../../services/query";
import getStateFromConfig from "../utils/getStateFromConfig";
import useAllConfigs from "../hooks/useAllConfigs";
import authActions from "../../../redux/auth/authActions";
import DesktopViewWithHeader from "../../../components/DesktopViewWithHeader";
import { useWindowWidth } from "../../../hooks/useWindowWidth";
import { GARAGE } from "../../../constants/routes";

import styles from "./EditBike.module.css";

const EditBike = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const [updateBike] = useMutation(UPDATE_BIKE);
  const navigate = useNavigate();
  const userBikes = useSelector(getBikes);
  const [getUserQuery] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });
  const pathname = useLocation().pathname;
  const bikeId = pathname.split("/")[2];
  const bike = userBikes?.find((bike) => bike?.id === bikeId);
  const [state, setState] = useState({});
  const prevPathname = pathname.split("/").slice(0, -1).join("/");
  const { getCurrentUserSuccess } = authActions;
  const specsConfig = useAllConfigs(bike, t, {
    chosenCategory: state.category,
  }).find((config) => config.editPageName === pathname.split("/")[3]).data;
  const isDesktop = useWindowWidth() > 1280;

  useEffect(() => {
    if (!bike) {
      navigate(GARAGE);
    }
    setState(getStateFromConfig(specsConfig, bike));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBikes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DesktopViewWithHeader
      footer={
        <Buttons
          onCancel={() => {
            navigate(prevPathname);
          }}
          onSave={() => {
            updateBike({
              variables: {
                id: bike.id,
                bike: {
                  data: JSON.stringify(
                    getBikeDocument(bike, specsConfig, state)
                  ),
                  level: "FULL_FILLED",
                },
              },
            })
              .then(() => {
                getUserQuery({
                  variables: { id: userId },
                }).then((res) => {
                  dispatch(getCurrentUserSuccess(res.data.user));
                  navigate(prevPathname);
                });
              })
              .catch((err) => {
                throw err;
              });
          }}
        />
      }
    >
      <div className={styles.routesWrapper}>
        <div className={styles.main}>
          <div className={styles.title}>{t("myBike.mainSpecs")}</div>
          <Fields specsConfig={specsConfig} state={state} setState={setState} />
          {!isDesktop && (
            <Buttons
              onCancel={() => {
                navigate(prevPathname);
              }}
              onSave={() => {
                updateBike({
                  variables: {
                    id: bike.id,
                    bike: {
                      data: JSON.stringify(
                        getBikeDocument(bike, specsConfig, state)
                      ),
                      level: "FULL_FILLED",
                    },
                  },
                })
                  .then(() => {
                    getUserQuery({
                      variables: { id: userId },
                    }).then((res) => {
                      dispatch(getCurrentUserSuccess(res.data.user));
                      navigate(prevPathname);
                    });
                  })
                  .catch((err) => {
                    throw err;
                  });
              }}
            />
          )}
        </div>
      </div>
    </DesktopViewWithHeader>
  );
};

export default EditBike;
