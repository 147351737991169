import CollapsibleSection from "../../../components/CollapsibleSection";
import SectionParameter from "./SectionParameter";
import getFieldValue from "../utils/getFieldValue";

import styles from "./Section.module.css";

const Section = ({
  bikeData,
  isCollapsible,
  isCollapsedByDefault,
  sectionConfig,
  onEdit,
}) => (
  <CollapsibleSection
    className={styles.section}
    title={sectionConfig.title}
    isCollapsible={isCollapsible}
    isCollapsedByDefault={isCollapsedByDefault}
    onEdit={onEdit}
  >
    <div className={styles.sectionContent}>
      {sectionConfig.data?.map((item) => {
        return item.isSubtitle ? (
          <div key={item.title} className={styles.subtitle}>
            {item.title}
          </div>
        ) : (
          item.shouldRender && (
            <div className={styles.sectionParameter}>
              <SectionParameter
                title={item.title}
                value={
                  typeof getFieldValue(item.value, bikeData) === "object"
                    ? null
                    : getFieldValue(item.value, bikeData)
                }
                key={item.fieldName}
              />
            </div>
          )
        );
      })}
    </div>
  </CollapsibleSection>
);

export default Section;
