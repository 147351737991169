import { useSelector } from "react-redux";
import { getBike } from "../../redux/bike/bikeSelectors";

import styles from "./BikeInfo.module.css";

const BikeInfo = () => {
  const focusedBikeData = useSelector(getBike);

  const bikeImage =
    focusedBikeData?.coverImage?.thumbnailReadUrl ||
    focusedBikeData?.images?.thumbnailReadUrl ||
    focusedBikeData?.images?.[1]?.thumbnailReadUrl ||
    focusedBikeData.image;

  return (
    <div className={styles.bikeCard}>
      <img
        className={styles.iconBike}
        src={bikeImage}
        alt={focusedBikeData.extendedModel}
      />
      <div className={styles.wrapper}>
        <div className={styles.priceWrapper}>
          <p className={styles.text}>{focusedBikeData?.manufacturer}</p>
        </div>
        <p className={styles.modelText}>{focusedBikeData?.model}</p>
      </div>
    </div>
  );
};

export default BikeInfo;
