import { createAction } from "@reduxjs/toolkit";

const setToast = createAction("general/setToast", (payload) => ({
  payload,
}));

const unsetToast = createAction("general/unsetToast");

const generalActions = {
  setToast,
  unsetToast,
};

export default generalActions;
