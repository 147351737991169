import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "../../components/Button";
import Banner from "../../components/Banner";
import ListEvents from "./ListEvents";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import {
  ADD_CHECK,
  GARAGE,
  MY_BIKE,
  REPAIR,
  INSPECTION,
} from "../../constants/routes";
import { getUser } from "../../redux/auth/authSelectors";

import styles from "./Checkbook.module.css";

export default function Checkbook() {
  const params = useParams();
  const navigate = useNavigate();
  const isDesktop = useWindowWidth() > 1280;
  const { t } = useTranslation();
  const user = useSelector(getUser) || {};
  const dueDate = user?.bikes?.find(
    (bike) => bike.id === params.bikeId
  )?.nextCheckBookDueDate;

  const goToGarage = () => {
    navigate(GARAGE);
  };

  const goToAddRepair = () => {
    navigate(`${MY_BIKE}/${params.bikeId}${REPAIR}${ADD_CHECK}`);
  };

  const goToAddInspection = () => {
    navigate(`${MY_BIKE}/${params.bikeId}${INSPECTION}${ADD_CHECK}`);
  };

  return (
    <DesktopViewWithHeader
      footer={
        <Button onClick={goToAddRepair} className={styles.btn}>
          <span>{t("checkbook.addRepair")}</span>
          <span className="icon-Arrow"></span>
        </Button>
      }
    >
      <div className={styles.checkbook}>
        <Helmet>
          <title>Checkbook</title>
          <meta name="description" content="Checkbook - Velio" />
        </Helmet>
        <div className={styles.header}>
          <div className={styles.backButton} onClick={goToGarage}></div>
          <div className={styles.pageTitle}>{t("checkbook.inspection")}</div>
          <div></div>
        </div>
        <Banner />
        <p className={styles.description}>
          {t("checkbook.checkbookDescription")}
        </p>
        <p className={styles.nextInspection}>{t("checkbook.nextInspection")}</p>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <p className={styles.cardHeaderText}>
              {t("checkbook.inspectionCardHeader")}
            </p>
            <span className={styles.plannedLabel}>
              {t("checkbook.planned")}
            </span>
          </div>
          <div className={styles.cardHeader}>
            <p className={styles.dateEntry}>{dueDate}</p>
            <p className={`${styles.dateEntry} ${styles.smallText}`}>
              nach 1.000 km oder 12 Monaten
            </p>
          </div>
          <Button onClick={goToAddInspection} className={styles.primaryButton}>
            <span>{t("checkbook.markAsDone")}</span>
            <span className={styles.moreDocument}></span>
          </Button>
        </div>
        <ListEvents bikeId={params.bikeId} />
        {!isDesktop && (
          <div className={styles.actionWrapper}>
            <Button onClick={goToAddRepair} className={styles.btn}>
              <span>{t("checkbook.addRepair")}</span>
              <span className="icon-Arrow"></span>
            </Button>
          </div>
        )}
      </div>
    </DesktopViewWithHeader>
  );
}
