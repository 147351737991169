import DatePicker from "react-datepicker";

import styles from "./Date.module.css";

const Date = ({ selectedDate, setSelectedDate, placeholder }) => {
  return (
    <div className={styles.container}>
      <div className={styles.calendarIcon}></div>
      <DatePicker
        selected={!!selectedDate && new window.Date(selectedDate)}
        onChange={(date) => setSelectedDate(date)}
        className={styles.datePicker}
        dateFormat="dd.MM.yyyy"
        placeholderText={placeholder}
        onFocus={(e) => e.target.blur()}
        withPortal
        maxDate={new window.Date()}
        minDate={new window.Date().setDate(new window.Date().getDate() - 1825)}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
};

export default Date;
