import styles from "./SectionParameter.module.css";

const SectionParameter = ({ title, value }) => {
  return (
    <>
      {title && <div className={styles.sectionParameterTitle}>{title}</div>}
      <div className={styles.sectionParameterValue}>{value || "–"}</div>
    </>
  );
};

export default SectionParameter;
