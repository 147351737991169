import { createAction } from "@reduxjs/toolkit";

const addInsuranceData = createAction(
  "insurance/addInsuranceData",
  (payload) => ({
    payload,
  })
);

const insuranceActions = {
  addInsuranceData,
};

export default insuranceActions;
