import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import DesktopView from "../../../components/DesktopView";
import { LOGIN, BIKE_OPTIONS, MANUFACTURER } from "../../../constants/routes";
import { dealerInfo } from "../../../redux/bike/bikeSelectors";
import { getUserId } from "../../../redux/auth/authSelectors";
import bikeActions from "../../../redux/bike/bikeActions";
import WelcomeBG from "../../../assets/images/WelcomeBGDesktop.png";
import DescriptionFeature from "./DescriptionFeature";

import styles from "./Welcome.module.css";

const Welcome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dealer = useSelector(dealerInfo);
  const userId = useSelector(getUserId);
  const { addFocusedBikeData } = bikeActions;

  const onBack = () => {
    navigate(-1);
  };

  const onLoginClick = () => {
    navigate(LOGIN);
  };

  const onEBikeClick = () => {
    dispatch(
      addFocusedBikeData({
        type: "E_BIKE",
      })
    );
    navigate(`${BIKE_OPTIONS}${MANUFACTURER}`);
  };

  const onBikeClick = () => {
    dispatch(
      addFocusedBikeData({
        type: "BIKE",
      })
    );
    navigate(`${BIKE_OPTIONS}${MANUFACTURER}`);
  };

  return (
    <DesktopView img={WelcomeBG} asideClassName={styles.desktopAsideImg}>
      <Helmet>
        <title>{t("welcomePageTitle")}</title>
        <meta name="theme-color" content="#F3F3F3" />
      </Helmet>
      <div className={styles.welcome}>
        <div className={styles.headerSection}>
          {!!userId ? (
            <div className={styles.newBikeHeader}>
              <div className={styles.backButton} onClick={onBack}></div>
              <div className={styles.pageTitle}>
                {t(`onboarding.welcome.addBike`)}
              </div>
            </div>
          ) : (
            <div className={styles.header}>
              <div className={styles.logo}></div>
              {!!dealer?.name && (
                <>
                  <div className={styles.closure}></div>
                  <div className={styles.dealer}>{dealer.name}</div>
                </>
              )}
            </div>
          )}
          <div className={styles.backgroundText}>
            {t("onboarding.welcome.backgroundText")}
          </div>
        </div>
        <div className={styles.descriptionSection}>
          <div className={styles.pageTitle}>
            {t("onboarding.welcome.digital")}{" "}
            <span className={styles.bold}>
              {t("onboarding.welcome.bikeManager")}
            </span>{" "}
            {t("onboarding.welcome.withInsurance")}
          </div>
          <div className={styles.descriptionFeatures}>
            <DescriptionFeature
              title={t("onboarding.welcome.bikePass")}
              text={t("onboarding.welcome.bikePassText")}
            />
            <DescriptionFeature
              title={t("onboarding.welcome.interactiveMap")}
              text={t("onboarding.welcome.interactiveMapText")}
            />
            <DescriptionFeature
              title={t("onboarding.welcome.checkbook")}
              text={t("onboarding.welcome.checkbookText")}
            />
            {/* <DescriptionFeature
              title={t("onboarding.welcome.insurance")}
              text={t("onboarding.welcome.insuranceText")}
            /> */}
          </div>
          <div className={styles.footerFiller} />
          <div className={styles.footer}>
            <div className={styles.footerTitle}>
              {t("onboarding.welcome.footerTitle")}
            </div>
            <div className={styles.footerButtons}>
              <div
                className={`${styles.footerButton} ${styles.footerButtonBlue}`}
                onClick={onEBikeClick}
              >
                ⚡️ {t("onboarding.welcome.eBike")}
              </div>
              <div
                className={`${styles.footerButton} ${styles.footerButtonGreen}`}
                onClick={onBikeClick}
              >
                🌱 {t("onboarding.welcome.bike")}
              </div>
            </div>
            {!userId && (
              <div className={styles.loginHint}>
                {t("onboarding.welcome.loginHint")}{" "}
                <span className={styles.loginLink} onClick={onLoginClick}>
                  {t("onboarding.welcome.login")}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </DesktopView>
  );
};

export default Welcome;
