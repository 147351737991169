import { useState, useEffect, memo, useMemo } from "react";
import ImagesCarousel from "../../../components/Images/ImagesCarousel";

import styles from "./BikeImages.module.css";

const BikeImages = ({ images, coverImage, isSharing }) => {
  const [curImgId, setCurImgId] = useState(coverImage?.documentId);
  const [openedPhotoId, setOpenedPhoto] = useState(undefined);

  useEffect(() => {
    setCurImgId(coverImage?.documentId);
  }, [coverImage?.documentId]);

  const sortedImages = useMemo(
    () =>
      images?.slice()?.sort((prev, next) => {
        if (prev.documentId === coverImage?.documentId) {
          return -1;
        } else if (next.documentId === coverImage?.documentId) {
          return 1;
        }
        return 0;
      }),
    [images, coverImage]
  );

  return (
    <>
      {openedPhotoId && (
        <ImagesCarousel
          images={sortedImages}
          imitialPhotoId={openedPhotoId}
          onClose={() => setOpenedPhoto(undefined)}
        />
      )}
      <div
        className={`${styles.photos} ${isSharing ? "" : styles.photosPadding}`}
      >
        <img
          onClick={() => setOpenedPhoto(curImgId)}
          src={
            images?.find((image) => curImgId === image.documentId)?.webReadUrl
          }
          alt={"main bike"}
          className={styles.mainPhoto}
        />
        <div className={styles.secondaryPhotos}>
          {sortedImages?.map((image) => (
            <img
              src={image?.thumbnailReadUrl}
              className={`${styles.secondaryPhoto} ${
                curImgId === image.documentId ? styles.chosen : ""
              }`}
              onClick={() => {
                setCurImgId(image.documentId);
              }}
              key={image.documentId}
              alt="bike"
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default memo(BikeImages);
