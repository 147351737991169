import styles from "./Tooltip.module.css";

const Tooltip = ({ text, direction = "bottom", className }) => {
  return (
    <>
      <span
        className={`${styles.tooltip} ${className}`}
        data-rh={text}
        data-rh-at={direction}
      />
    </>
  );
};

export default Tooltip;
