import Select from "../../../../components/Inputs/Select";

import styles from "./SelectInput.module.css";

const SelectInput = ({ title, value, onChange, options }) => (
  <div className={styles.parameter}>
    {title && <div className={styles.parameterTitle}>{title}</div>}
    <Select value={value} onChange={onChange} options={options} />
  </div>
);

export default SelectInput;
