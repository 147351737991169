import styles from "./Textarea.module.css";

const Textarea = ({ value, onChange, placeholder, className }) => {
  return (
    <textarea
      className={`${styles.textarea} ${className}`}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder={placeholder}
    />
  );
};

export default Textarea;
