import Button from "../Button/Button";

import styles from "./ButtonWithoutProgressBar.module.css";

const ButtonWithoutProgressBar = ({ value, isCenter, ...props }) => {
  return (
    <div
      className={`${styles.buttonWrapper} ${
        isCenter ? styles.centeredButtonWrapper : ""
      }`}
    >
      <div className={styles.actionSection}>
        <div className={styles.stub} />
        <Button className={styles.btn} {...props}>
          <span>{value}</span>
          <span className="icon-Arrow"></span>
        </Button>
        <div className={styles.stub} />
      </div>
    </div>
  );
};

export default ButtonWithoutProgressBar;
