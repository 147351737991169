import styles from "./Cash.module.css";

const Cash = ({ value, onChange, placeholder, className, onKeyDown }) => {
  const onInputChange = (e) => {
    const newValue = e.target.value;
    const splitValue = newValue.split(",");
    if (
      isNaN(+splitValue.join("")) ||
      splitValue[1]?.length > 2 ||
      splitValue.length > 2 ||
      newValue.split(".").length > 1
    ) {
      return;
    }
    onChange(newValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.euroIcon}></div>
      <input
        type="text"
        className={styles.cash}
        value={value}
        onChange={onInputChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default Cash;
