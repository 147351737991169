import { createAction } from "@reduxjs/toolkit";

const addFocusedBikeData = createAction(
  "bike/addFocusedBikeDataRequest",
  (payload) => ({
    payload,
  })
);

const addFocusedBikeDataSuccess = createAction(
  "bike/addFocusedBikeDataSuccess"
);
const addFocusedBikeDataError = createAction(
  "bike/addFocusedBikeDataError",
  (payload) => ({
    payload,
  })
);
const resetFocusedBikeData = createAction("bike/resetFocusedBikeData");

const setDealerData = createAction("bike/setDealerData");

const addStepOnboarding = createAction("bike/addStepOnboarding", (payload) => ({
  payload,
}));
const addStepInsurance = createAction("bike/addStepInsurance", (payload) => ({
  payload,
}));
const getBikeRequest = createAction("bike/getBikeRequest");
const getBikeSuccess = createAction("bike/getBikeSuccess");
const getBikeError = createAction("bike/getBikeError");

const bikeActions = {
  addFocusedBikeData,
  addFocusedBikeDataSuccess,
  addFocusedBikeDataError,
  setDealerData,
  getBikeRequest,
  getBikeSuccess,
  getBikeError,
  addStepOnboarding,
  addStepInsurance,
  resetFocusedBikeData,
};

export default bikeActions;
