export const GARAGE = "/garage";
export const ACTIVATE_BIKE = "/activate-bike";
export const MY_ACCOUNT = "/my-account";
export const EDIT_ACCOUNT = "/edit";
export const MY_BIKE = "/my-bike";
export const PHOTOS = "/photos";
export const INVITE_FRIEND = "/invite-friend";
export const WELCOME = "/welcome";
export const BIKE_OPTIONS = "/bike-options";
export const MANUFACTURER = "/manufacturer";
export const MODEL = "/model";
export const ADD_OPTION = "/add-new";
export const CREATE_PROFILE = "/create-account";
export const ONBOARDING_PRICE = "/onboarding-price";
export const INSURANCE_CHOOSE = "/insurance-choose";
export const INSURANCE_BIKE_DETAILS = "/insurance-bike-details";
export const INSURANCE_PROFILE = "/insurance-profile";
export const INSURANCE_PAYMENT = "/insurance-payment";
export const INSURANCE_OVERVIEW = "/insurance-overview";
export const INSURANCE_SUCCESS = "/insurance-success";
export const PROFILE = "/profile";
export const INSURANCE_GARAGE = "/insurance-garage";
export const DASHBOARD = "/dashboard";
export const BIKE_PASS = "/bike-pass";
export const BIKE_PASS_EDIT = "/edit";
export const LOGIN = "/login";
// export const RESET_PASSWORD = "/reset-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const CHECK_BOOK = "/check-book";
export const ADD_CHECK = "/add";
export const EDIT_CHECK = "/edit";
export const REPAIR = "/repair";
export const INSPECTION = "/inspection";
export const DEALER_ALIAS = "/d";
export const DEALER = "/dealer";

export const publicRoutes = [LOGIN, FORGOT_PASSWORD];

export const privateRoutes = [
  GARAGE,
  ACTIVATE_BIKE,
  MY_ACCOUNT,
  INSURANCE_GARAGE,
  `${MY_ACCOUNT}${EDIT_ACCOUNT}`,
  `${DEALER}/:id`,
  INSURANCE_PROFILE,
  INSURANCE_PAYMENT,
  INSURANCE_OVERVIEW,
  INSURANCE_SUCCESS,
  `${MY_BIKE}/:bikeId${PHOTOS}`,
  `${MY_BIKE}/:bikeId${CHECK_BOOK}`,
  `${MY_BIKE}/:bikeId${CHECK_BOOK}/:eventType/:checkBookId`,
  `${MY_BIKE}/:bikeId${CHECK_BOOK}/:eventType/:checkBookId${EDIT_CHECK}`,
  `${BIKE_PASS}/:id`,
  `${BIKE_PASS}/:id${BIKE_PASS_EDIT}`,
];

export const onboardingRoutes = [
  WELCOME,
  `${WELCOME}${MANUFACTURER}`,
  `${WELCOME}${MANUFACTURER}${ADD_OPTION}`,
  `${WELCOME}${MODEL}`,
  `${WELCOME}${MODEL}${ADD_OPTION}`,
  ONBOARDING_PRICE,
  INSURANCE_CHOOSE,
  PROFILE,
  CREATE_PROFILE,
];

export const insuranceRoutes = [
  INSURANCE_PROFILE,
  INSURANCE_PAYMENT,
  INSURANCE_OVERVIEW,
  INSURANCE_SUCCESS,
];
