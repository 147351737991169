const getBikeDocument = (bike, specsConfig, state) => {
  const newBikeDocument = bike;
  specsConfig.forEach((field) => {
    if (field.isSubtitle) {
      return;
    }
    const routeArray = field.value.split(".");
    routeArray.reduce((obj, route, i) => {
      if (i !== routeArray.length - 1) {
        if (typeof obj === "string") {
          obj = {};
        }
      } else {
        obj[route] = state[field.fieldName];
        return obj;
      }
      if (!obj[route]) {
        obj[route] = {};
      }
      return obj[route];
    }, newBikeDocument);
  });
  return newBikeDocument;
};

export default getBikeDocument;
