const user = {
  type: "",
  manufacturer: "",
  model: "",
  price: "",
  store: "",
  insurance: "",
  protection: true,
};

const credentials = {
  name: "",
  surname: "",
  birthday: "",
  street: "",
  apartment: "",
  postalCode: "",
  city: "",
  email: "",
  password: "",
};

const registerStatus = {
  isRegistered: false,
};

export const addType = (data) => {
  user.type = data;
  return localStorage.setItem("User", JSON.stringify(user));
};
export const addManufacturer = (data) => {
  user.manufacturer = data;
  return localStorage.setItem("User", JSON.stringify(user));
};
export const addModel = (data) => {
  user.model = data;
  return localStorage.setItem("User", JSON.stringify(user));
};
export const addPrice = (data) => {
  user.price = data;
  return localStorage.setItem("User", JSON.stringify(user));
};
export const addStore = (data) => {
  user.store = data;
  return localStorage.setItem("User", JSON.stringify(user));
};
export const addInsurance = (data) => {
  user.insurance = data;
  return localStorage.setItem("User", JSON.stringify(user));
};
export const addProtection = (data) => {
  user.protection = data;
  return localStorage.setItem("User", JSON.stringify(user));
};

export const addName = (data) => {
  credentials.name = data;
  return localStorage.setItem("Credentials", JSON.stringify(credentials));
};
export const addSurname = (data) => {
  credentials.surname = data;
  return localStorage.setItem("Credentials", JSON.stringify(credentials));
};
export const addBirthday = (data) => {
  credentials.birthday = data;
  return localStorage.setItem("Credentials", JSON.stringify(credentials));
};
export const addStreet = (data) => {
  credentials.street = data;
  return localStorage.setItem("Credentials", JSON.stringify(credentials));
};
export const addApartment = (data) => {
  credentials.apartment = data;
  return localStorage.setItem("Credentials", JSON.stringify(credentials));
};
export const addPostalCode = (data) => {
  credentials.postalCode = data;
  return localStorage.setItem("Credentials", JSON.stringify(credentials));
};
export const addCity = (data) => {
  credentials.city = data;
  return localStorage.setItem("Credentials", JSON.stringify(credentials));
};
export const addEmail = (data) => {
  credentials.email = data;
  return localStorage.setItem("Credentials", JSON.stringify(credentials));
};
export const addPassword = (data) => {
  credentials.password = data;
  return localStorage.setItem("Credentials", JSON.stringify(credentials));
};

export const setRegisterStatus = (data) => {
  registerStatus.isRegistered = data;
  return localStorage.setItem("RegisterStatus", JSON.stringify(registerStatus));
};
export const getRegisterStatus = () => {
  return JSON.parse(localStorage.getItem("RegisterStatus"));
};
