import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";

import { useWindowWidth } from "../../hooks/useWindowWidth";

import styles from "./Sharing.module.css";

const Sharing = ({
  containerClassName,
  buttonClassName,
  title,
  text,
  url,
  children,
}) => {
  const { t } = useTranslation();
  const isDesktop = useWindowWidth() > 1280;
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (buttonRef.current && !buttonRef.current.contains(e.target)) {
        setIsModalOpen(false);
        setTimeout(() => {
          setIsDisplayed(false);
        }, 100);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [buttonRef]);

  return (
    <div className={`${styles.sharingContainer} ${containerClassName}`}>
      <div
        ref={buttonRef}
        className={buttonClassName}
        onClick={() => {
          if (isDesktop) {
            setIsDisplayed(true);
            setTimeout(() => {
              setIsModalOpen(true);
            }, 0);
          } else {
            navigator.share({
              title,
              text,
              url,
            });
          }
        }}
      >
        {children}
      </div>
      <div
        className={`${styles.popup} ${isDisplayed ? styles.displayed : ""} ${
          isModalOpen ? styles.open : ""
        }`}
      >
        <div className={styles.popupItemContainer}>
          <TwitterShareButton
            className={`${styles.popupItem} ${styles.twitter}`}
            url={url}
            title={title}
            description={text}
          >
            Twitter
          </TwitterShareButton>
        </div>
        <div className={styles.popupItemContainer}>
          <FacebookShareButton
            className={`${styles.popupItem} ${styles.facebook}`}
            url={url}
            quote={title}
            description={text}
          >
            Facebook
          </FacebookShareButton>
        </div>
        <div className={styles.popupItemContainer}>
          <EmailShareButton
            className={`${styles.popupItem} ${styles.mail}`}
            url={url}
            subject={title}
            body={text}
            openShareDialogOnClick
          >
            Mail
          </EmailShareButton>
        </div>
        <div className={styles.popupItemContainer}>
          <WhatsappShareButton
            className={`${styles.popupItem} ${styles.whatsapp}`}
            url={url}
            title={title}
          >
            Whatsapp
          </WhatsappShareButton>
        </div>
        <div className={styles.popupItemContainer}>
          <div
            className={`${styles.popupItem} ${styles.copyLink}`}
            onClick={() => {
              navigator.clipboard.writeText(url);
            }}
          >
            {t("inviteFriend.copyLink")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sharing;
