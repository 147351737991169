import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { GET_BIKE } from "../../services/query";

import styles from "./Banner.module.css";
import { useMemo } from "react";

export default function Banner() {
  const params = useParams();
  const { data: bike } = useQuery(GET_BIKE, {
    variables: { id: params.bikeId },
  });

  const parseBikeData = useMemo(() => {
    if (bike?.bike.data) {
      return JSON.parse(bike.bike.data);
    } else {
      return null;
    }
  }, [bike]);

  return parseBikeData ? (
    <div className={styles.card}>
      <div className={styles.bikeContainer}>
        <img
          alt="bike"
          src={bike?.bike?.coverImage?.thumbnailReadUrl}
          className={styles.iconBike}
        />
        <div className={styles.wrapper}>
          <p className={styles.text}>{parseBikeData.manufacturer}</p>
          <p className={styles.modelText}>{parseBikeData.model_name}</p>
        </div>
      </div>
    </div>
  ) : null;
}
