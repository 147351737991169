import Textarea from "../../../../components/Inputs/Textarea";

import styles from "./TextareaInput.module.css";

const TextareaInput = ({ title, value, onChange }) => (
  <div className={styles.parameter}>
    {title && <div className={styles.parameterTitle}>{title}</div>}
    <Textarea value={value} onChange={onChange} />
  </div>
);

export default TextareaInput;
