import Switcher from "../../../../components/Switcher";
import SelectInput from "../SelectInput";
import TextareaInput from "../TextareaInput";
import TextInput from "../TextInput";

import styles from "./Fields.module.css";

const Fields = ({ specsConfig, state, setState }) => (
  <>
    {specsConfig.map((field) => {
      return field.isSubtitle ? (
        <div key={field.title + " subtitle"} className={styles.subtitle}>
          {field.title}
        </div>
      ) : field.inputType === "switch" ? (
        <Switcher
          key={field.fieldName}
          title={field.title}
          options={field.options}
          chosenOptionId={state[field.fieldName] || ""}
          onChange={(value) => {
            setState({
              ...state,
              [field.fieldName]: value,
              ...field.nullifiedFieldsOnChange?.reduce(
                (obj, nullifiedFieldName) => ({
                  ...obj,
                  [nullifiedFieldName]: null,
                }),
                {}
              ),
            });
          }}
          chosenClassName={styles.chosenSwitch}
        />
      ) : field.inputType === "select" ? (
        <SelectInput
          key={field.fieldName}
          title={field.title}
          value={{
            label: state[field.fieldName] || "",
            value: state[field.fieldName] || "",
          }}
          onChange={(option) => {
            setState({
              ...state,
              [field.fieldName]: option.value,
              ...field.nullifiedFieldsOnChange?.reduce(
                (obj, nullifiedFieldName) => ({
                  ...obj,
                  [nullifiedFieldName]: null,
                }),
                {}
              ),
            });
          }}
          options={field.options}
        />
      ) : field.inputType === "textarea" ? (
        <TextareaInput
          key={field.fieldName}
          title={field.title}
          value={state[field.fieldName] || ""}
          onChange={(value) => {
            setState({
              ...state,
              [field.fieldName]: value,
              ...field.nullifiedFieldsOnChange?.reduce(
                (obj, nullifiedFieldName) => ({
                  ...obj,
                  [nullifiedFieldName]: null,
                }),
                {}
              ),
            });
          }}
        />
      ) : (
        <TextInput
          key={field.fieldName}
          title={field.title}
          value={state[field.fieldName] || ""}
          onChange={(value) => {
            setState({
              ...state,
              [field.fieldName]: value,
              ...field.nullifiedFieldsOnChange?.reduce(
                (obj, nullifiedFieldName) => ({
                  ...obj,
                  [nullifiedFieldName]: null,
                }),
                {}
              ),
            });
          }}
        />
      );
    })}
  </>
);

export default Fields;
