import {useTranslation} from 'react-i18next';

import BasicButton from '../BasicButton';

import styles from './CancelButton.module.css';

const CancelButton = ({onClick, buttonClassName}) => {
  const {t} = useTranslation();

  return (
    <BasicButton
      text={t('cancel')}
      onClick={onClick}
      buttonClassName={buttonClassName}
      textClassName={styles.text}
      iconClassName={styles.icon}
    />
  );
};
export default CancelButton;
