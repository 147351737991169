import {useTranslation} from 'react-i18next';

import styles from './SectionFooter.module.css';

const SectionFooter = ({onClick, editText, className}) => {
  const {t} = useTranslation();

  return (
    <div className={`${className} ${styles.sectionFooter}`} onClick={onClick}>
      <div className={styles.editText}>{editText || t('myBike.edit')}</div>
      <div className={styles.editSymbol}></div>
    </div>
  );
};

export default SectionFooter;
