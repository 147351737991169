import { useState, useEffect, useRef } from "react";
import SectionFooter from "./SectionFooter";

import styles from "./ExpandableSection.module.css";

const ExpandableSection = ({
  children,
  className,
  onClick,
  title,
  isExpandedByDefault = false,
  expandableContent,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  const [height, setHeight] = useState();
  const ref = useRef(null);

  useEffect(() => {
    if (isExpanded) {
      setHeight(ref.current?.getBoundingClientRect().height);
    } else {
      setHeight(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const onToggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className={`${className} ${styles.section}`} onClick={onClick}>
      <div className={styles.content}>
        {children}
        {expandableContent && (
          <>
            <div className={styles.expandableContent} style={{ height }}>
              <div ref={ref}>{expandableContent}</div>
            </div>
            <SectionFooter onClick={onToggleExpanded} isExpanded={isExpanded} />
          </>
        )}
      </div>
    </section>
  );
};

export default ExpandableSection;
