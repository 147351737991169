import styles from "./BikeParameter.module.css";

const BikeParameter = ({ title, value, className }) => {
  return (
    <div className={`${styles.bikeParameter} ${className}`}>
      <div className={styles.bikeParameterHeader}>{title}</div>
      <div className={styles.bikeParameterValue}>
        {typeof value === "string" || typeof value === "number" ? value || "–" : "-"}
      </div>
    </div>
  );
};
export default BikeParameter;
