const normalizeCost = (data) => {
  let int = ((data / 100) | 0).toString();
  let remainder = (data / 100).toString().split(".")[1];
  if (remainder?.length === 1) {
    remainder = remainder + "0";
  }
  return `${int}${remainder ? "," + remainder : ""}`;
};

export default normalizeCost;
