import RadioInput from "../RadioInput";

import styles from './Selector.module.css';

export default function Selector({ register, onChange, t }) {
  const specialistRegister = register("dealerID", {
    required: true,
  });

  const privateRegister = register("dealerID", {
    required: true,
  });
  return (
    <div>
      <RadioInput
        {...privateRegister}
        onChange={(e) => {
          privateRegister.onChange(e);
          onChange(e);
        }}
        id="Privat"
        value="Privat"
        label={t("checkbook.private")}
      />
      <hr className={styles.hr} />
      <RadioInput
        {...specialistRegister}
        onChange={(e) => {
          specialistRegister.onChange(e);
          onChange(e);
        }}
        id="Pro"
        value="Fahrradfachgeschäft"
        label={t("checkbook.professional")}
      />
    </div>
  );
}
