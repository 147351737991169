import { configureStore } from "@reduxjs/toolkit";
import bikeReducer from "./bike/bikeReducer";
import authReducer from "./auth/authReducer";
import insuranceReducer from "./insurance/insuranceReducer";
import generalReducer from "./general/generalReducer";
import listenerMiddleware from "./auth/authOperations";
import { saveState } from "./storeSerializer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    bike: bikeReducer,
    insurance: insuranceReducer,
    general: generalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV === "development",
});

store.subscribe(() => {
  saveState(store.getState(), ["bike", "insurance", "general"]);
});
