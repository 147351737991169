/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import ReactTooltip from "react-tooltip";
import CollapsibleSection from "../../components/CollapsibleSection";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import ImagesCarousel from "../../components/Images";
import Spinner from "../../components/Spinner/Spinner";
import { GET_BIKE, GET_USER } from "../../services/query";
import {
  UPDATE_BIKE,
  UPLOAD_IMAGE,
  REMOVE_IMAGE,
} from "../../services/mutation";
import { getUser } from "../../redux/auth/authSelectors";
import authActions from "../../redux/auth/authActions";
import { MY_BIKE } from "../../constants/routes";
import PhotoPlaceholder from "../../assets/images/PhotoPlaceholder.svg";
import { useWindowWidth } from "../../hooks/useWindowWidth";

import styles from "./BikePhotos.module.css";

const BikeImages = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch();
  const user = useSelector(getUser) || {};
  const { getCurrentUserSuccess } = authActions;
  const userBikesData = user?.bikes;
  const { id: bikeId } = useParams();
  const { data: bikeData } = useQuery(GET_BIKE, {
    variables: { id: bikeId },
    skip: !bikeId,
  });
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const [updateBike] = useMutation(UPDATE_BIKE);
  const [removeImage] = useMutation(REMOVE_IMAGE);
  const [getUserQuery] = useLazyQuery(GET_USER);
  const [bike, setBike] = useState(null);
  const [openedPhotoId, setOpenedPhoto] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = useWindowWidth() > 1280;

  useEffect(() => {
    const userBikes = userBikesData?.map((bikeOfUser) => ({
      ...bikeOfUser,
      data: {
        ...JSON.parse(bikeOfUser?.data || ""),
      },
    }));
    const userBike = userBikes?.find((bikeOfUser) => bikeOfUser?.id === bikeId);
    if (!userBike) {
      setBike({
        ...bikeData?.bike,
        data: JSON.parse(bikeData?.bike?.data ? bikeData?.bike.data : null),
      });
    } else {
      setBike(userBike);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bikeId, userBikesData, bikeData]);

  const sortedImages = useMemo(
    () =>
      bike?.images?.slice()?.sort((prev, next) => {
        if (prev.documentId === bike?.coverImage?.documentId) {
          return -1;
        } else if (next.documentId === bike?.coverImage?.documentId) {
          return 1;
        }
        return 0;
      }),
    [bike?.images, bike?.coverImage]
  );

  const additionalImages = bike?.images?.filter((image) => {
    return image.documentId !== bike?.coverImage?.documentId;
  });

  const handleSelectImage = useCallback(
    async (e) => {
      try {
        setIsLoading(true);
        const oldCoverId = bike.coverImage.documentId;
        const file = e.target.files[0];
        const uploadResult = await uploadImage({
          variables: {
            id: bike.id,
            uploadFile: file,
          },
        });
        const newImagesIds = uploadResult?.data?.addBikeImage?.images?.map(
          (newImage) => newImage.documentId
        );
        const oldImagesIds = bike.images.map((oldImage) => oldImage.documentId);
        const createdIds = newImagesIds.reduce((acc, newImageId) => {
          return oldImagesIds.includes(newImageId)
            ? [...acc]
            : [...acc, newImageId];
        }, []);
        if (createdIds && createdIds.length === 1) {
          await updateBike({
            variables: {
              id: uploadResult.data.addBikeImage.id,
              bike: {
                data: JSON.stringify(bike.data),
                coverImageDocumentId: createdIds[0],
                level: "FULL_FILLED",
              },
            },
          });
          await removeImage({
            variables: {
              id: oldCoverId,
              bikeId: bike.id,
            },
          });
          const updatedUser = await getUserQuery({
            variables: {
              id: user.id,
            },
          });
          const userData = updatedUser.data.user;
          dispatch(getCurrentUserSuccess(userData));
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log("Image upload failed", e);
      }
    },
    [
      bike,
      uploadImage,
      updateBike,
      removeImage,
      dispatch,
      getUserQuery,
      user.id,
      getCurrentUserSuccess,
    ]
  );

  const handleSelectRegularImage = useCallback(
    async (e) => {
      try {
        setIsLoading(true);
        const file = e.target.files[0];
        await uploadImage({
          variables: {
            id: bike.id,
            uploadFile: file,
          },
        });
        const updatedUser = await getUserQuery({
          variables: {
            id: user.id,
          },
        });
        const userData = updatedUser.data.user;
        dispatch(getCurrentUserSuccess(userData));
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.log("Image upload failed", e);
      }
    },
    [bike, uploadImage, getUserQuery, dispatch, user.id, getCurrentUserSuccess]
  );

  const handleRemoveImage = async (imageId) => {
    try {
      setIsLoading(true);
      await removeImage({
        variables: {
          id: imageId,
          bikeId: bike.id,
        },
      });
      const updatedUser = await getUserQuery({
        variables: {
          id: user.id,
        },
      });
      const userData = updatedUser.data.user;
      dispatch(getCurrentUserSuccess(userData));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("Image remove failed", e);
    }
  };

  return (
    <>
      {openedPhotoId && sortedImages && (
        <ImagesCarousel
          images={sortedImages}
          imitialPhotoId={openedPhotoId}
          onClose={() => setOpenedPhoto(undefined)}
        />
      )}
      <DesktopViewWithHeader
        footer={
          <label for="regularPhotoInput" className={styles.bottomButtons}>
            <span>{t("myBike.addPhoto")}</span>
            <span className={styles.buttonPhotoIcon}></span>
            <input
              type="file"
              id="regularPhotoInput"
              accept="image/png, image/jpg"
              onChange={handleSelectRegularImage}
              hidden
            />
          </label>
        }
      >
        <div className={styles.photos}>
          <Helmet>
            <title>My Bike</title>
            <meta
              name="description"
              content="My Bike. Velio | My bike photos."
            />
          </Helmet>
          {isLoading && <Spinner color="#02f596" />}
          <div className={styles.header}>
            <div
              className={styles.backButton}
              onClick={() => {
                navigate(`${MY_BIKE}/${bikeId}`);
              }}
            ></div>
            <div className={styles.pageTitle}>
              {t("myBike.photosPageTitle")}
            </div>
          </div>
          <div className={styles.subheader}>{t("myBike.photosSubheader")}</div>
          <CollapsibleSection
            className={styles.section}
            constentClassName={styles.sectionContent}
          >
            <div className={styles.coverPhoto}>
              <img
                onClick={() => setOpenedPhoto(bike?.coverImage?.documentId)}
                src={bike?.coverImage?.webReadUrl || PhotoPlaceholder}
                alt={"main bike"}
                className={styles.coverPhoto}
              />
              <label
                className={
                  bike?.coverImage?.webReadUrl
                    ? styles.photoInput
                    : styles.newPhotoInput
                }
                for="photoInput"
              >
                <input
                  type="file"
                  id="photoInput"
                  accept="image/png, image/jpg"
                  onChange={handleSelectImage}
                  hidden
                />
              </label>
            </div>
          </CollapsibleSection>
          <div className={styles.previewSection}>
            {additionalImages &&
              additionalImages.map((image) => (
                <div
                  className={styles.regularPhotoWrapper}
                  key={image.documentId}
                >
                  <div className={styles.regularPhoto}>
                    <img
                      src={image.webReadUrl}
                      alt={"Photo Preview"}
                      onClick={() => setOpenedPhoto(image.documentId)}
                    />
                  </div>
                  <div
                    className={styles.regularPhotoInput}
                    data-tip
                    data-for={image.documentId}
                    data-event="click"
                    data-offset="{'top': 5, 'left': 30}"
                  />
                  <ReactTooltip
                    effect="solid"
                    id={image.documentId}
                    place="bottom"
                    type="light"
                    arrowColor="transparent"
                    backgroundColor="#FFF"
                    globalEventOff="click"
                    clickable
                    className={styles.tooltipStyle}
                  >
                    <div
                      onClick={() => handleRemoveImage(image.documentId)}
                      className={styles.dropdown}
                    >
                      {t("myBike.removeImage")}
                    </div>
                  </ReactTooltip>
                </div>
              ))}
          </div>
          {!isDesktop && (
            <div className={styles.actionWrapper}>
              <label for="regularPhotoInput" className={styles.bottomButtons}>
                <span>{t("myBike.addPhoto")}</span>
                <span className={styles.buttonPhotoIcon}></span>

                <input
                  type="file"
                  id="regularPhotoInput"
                  accept="image/png, image/jpg"
                  onChange={handleSelectRegularImage}
                  hidden
                />
              </label>
            </div>
          )}
        </div>
      </DesktopViewWithHeader>
    </>
  );
};

export default BikeImages;
