import React from "react";
import { Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

import { GET_USER } from "./services/query";
import Routes from "./features/Routes";
import authActions from "./redux/auth/authActions";
import Toast from "./features/Toast";
import ReactHintFactory from "react-hint";

import "react-hint/css/index.css";
import styles from "./App.module.css";

import de from "./assets/translate/de.json";
import en from "./assets/translate/en.json";

const ReactHint = ReactHintFactory(React);

const ONE_HOUR = 60 * 60 * 1000;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    lng: "de",
    fallbackLng: "en",
    supportedLngs: ["en", "de"],
    ns: ["translation"],
    defaultNS: "translation",
    detection: {
      order: ["cookie", "localStorage", "htmlTag", "path", "subdomain"],
      caches: ["cookie"],
    },
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
    },
    react: {
      useSuspense: true,
    },
    compatibilityJSON: "v3",
  });

function App() {
  const dispatch = useDispatch();
  const { signOutSuccess, getCurrentUserSuccess } = authActions;
  const [timerId, setTimerId] = useState("");
  const commercetoolsCookie = Cookies.get("CTAT");
  const hasCommercetoolsCookie = !!commercetoolsCookie;
  const decoded = useMemo(() => {
    if (hasCommercetoolsCookie) {
      return JSON.parse(commercetoolsCookie);
    } else {
      return null;
    }
  }, [hasCommercetoolsCookie, commercetoolsCookie]);
  const [getUserQuery] = useLazyQuery(GET_USER, {
    variables: { id: decoded?.user_id },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (commercetoolsCookie) {
      getUserQuery()
        .then((res) => {
          dispatch(getCurrentUserSuccess(res.data?.user));
          i18n.changeLanguage(res.data?.user.locale);
        })
        .catch((e) => {
          dispatch(signOutSuccess());
        });
    }
    const timer = setTimeout(() => {
      setTimerId(timer);
    }, ONE_HOUR);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commercetoolsCookie, timerId]);

  return (
    <Suspense fallback={null}>
      <Helmet defaultTitle="velio | Everything for your bike" />
      <ReactHint
        autoPosition
        events={{
          hover: true,
          click: true,
        }}
        delay={{ show: 100, hide: 100 }}
      />
      <ReactHint
        persist
        autoPosition
        attribute="data-custom"
        events={{
          hover: true,
          click: true,
        }}
        ref={(ref) => ref}
      />
      <div className={styles.app}>
        <Routes />
        <Toast />
      </div>
    </Suspense>
  );
}

export default App;
