import React from "react";
import ReactDOM from "react-dom";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./redux/store";
import App from "./App";
import Footer from "./features/Footer";
import { DataProvider } from "./services/DataContext";
import { MixpanelProvider } from "./utils/mixpanel";

import "react-datepicker/dist/react-datepicker.css";
import "modern-normalize/modern-normalize.css";
import "./index.css";
import "./slick.css";
import "./assets/icons.css";

const httpLink = createHttpLink({
  uri: getURIFromHost(),
});

const uploadLink = createUploadLink({
  uri: getURIFromHost(),
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'Cookie': document.cookie,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink).concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <React.StrictMode>
        <DataProvider>
          <MixpanelProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
            <Footer />
          </MixpanelProvider>
        </DataProvider>
      </React.StrictMode>
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);

function getURIFromHost() {
  let protocol = "https";
  let apiUrl = document.location.host;
  if (process.env.REACT_APP_PROTOCOL) {
    protocol = process.env.REACT_APP_PROTOCOL;
  }
  if (process.env.REACT_APP_API_URL) {
    apiUrl = process.env.REACT_APP_API_URL;
  } else if (document.URL.includes("localhost")) {
    return `${window.location.origin}/garage/api/query`;
  }
  return `${protocol}://${apiUrl}/garage/api/query`;
}
