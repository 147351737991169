import { useTranslation } from "react-i18next";

import useBikeCategories from "../hooks/useBikeCategories";

const useMainSpecsConfig = (bikeData, additional) => {
  const { t } = useTranslation();
  const bikeCategories = useBikeCategories();

  return {
    title: t("myBike.mainSpecs"),
    editPageName: "edit-main-specs",
    data: [
      {
        fieldName: "weight",
        title: t("myBike.weight"),
        value: "bike_weight",
        shouldRender: true,
      },
      {
        fieldName: "bicycleType",
        title: t("myBike.bicycleType"),
        value: "bike_type",
        shouldRender: true,
        inputType: "switch",
        options: [
          { id: "e-bike", title: t("garage.eBike") },
          { id: "bike", title: t("garage.bicycle") },
        ],
      },
      {
        fieldName: "size",
        title: t("myBike.size"),
        value: "frame_size",
        shouldRender: true,
      },
      {
        fieldName: "frameMaterial",
        title: t("myBike.frameMaterial"),
        value: "frame_material",
        shouldRender: true,
      },
      {
        fieldName: "category",
        title: t("myBike.category"),
        value: "category",
        shouldRender: true,
        inputType: "select",
        options: bikeCategories.map((category) => ({
          value: category.value,
          label: category.label,
        })),
        nullifiedFieldsOnChange: ["subCategory"],
      },
      {
        fieldName: "subCategory",
        title: t("myBike.subCategory"),
        value: "subcategory",
        shouldRender: true,
        inputType: "select",
        options: bikeCategories.find(
          (item) => item.value === additional?.chosenCategory
        )?.subcategories,
      },
    ],
  };
};

export default useMainSpecsConfig;
