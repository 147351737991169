import styles from "./Map.module.css";

const Map = ({ url }) => {
  return (
    <div className={styles.mapContainer}>
      <div className={styles.overlay}></div>
      <iframe title="map" className={styles.map} src={url} />
    </div>
  );
};

export default Map;
