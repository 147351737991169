import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import { getUser } from "../../redux/auth/authSelectors";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import TextInput from "../../components/Inputs/Text";
import { ADD_EMAIL_CIRCULAR_NEWSLETTER } from "../../services/mutation";
import generalActions from "../../redux/general/generalActions";
import LanguageWidget from "./LanguageWidget";

import styles from "./Footer.module.css";

const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUser) || {};
  const isDesktop = useWindowWidth() > 1280;
  const [addEmail] = useMutation(ADD_EMAIL_CIRCULAR_NEWSLETTER);
  const { setToast } = generalActions;
  const [emailValue, setEmailValue] = useState("");

  const onNewsletterSubmit = async () => {
    try {
      const res = await addEmail({
        variables: {
          email: emailValue,
        },
      });
      if (res.data.addEmailToCircularNewsletter) {
        dispatch(
          setToast({
            header: t("footer.newsletter.successHeader"),
            body: t("footer.newsletter.successBody"),
            shouldRender: true,
          })
        );
      } else throw new Error();
    } catch {
      dispatch(
        setToast({
          header: t("footer.newsletter.errorHeader"),
          body: t("footer.newsletter.errorBody"),
          shouldRender: true,
        })
      );
    }
  };

  const LanguageAndCopyright = () => (
    <>
      <LanguageWidget className={styles.languageWidget} />
      <div className={styles.copyright}>{t("footer.copyright")}</div>
    </>
  );

  return (
    <div className={styles.footer}>
      <div className={styles.newsletter}>
        <div className={styles.newsletterMain}>
          <div className={styles.newsletterTitle}>
            {t("footer.newsletter.title")}
          </div>
          <div className={styles.newsletterSubTitle}>
            {t("footer.newsletter.subTitle")}
          </div>
          <div className={styles.newsletterInputContainer}>
            <TextInput
              type="email"
              className={styles.newsletterInput}
              placeholder={t("footer.newsletter.placeholder")}
              value={emailValue}
              onChange={setEmailValue}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onNewsletterSubmit();
                }
              }}
            />
            <div
              className={styles.submitNewsletterButton}
              onClick={onNewsletterSubmit}
            >
              {t("footer.newsletter.submit")}
            </div>
          </div>
        </div>
        {isDesktop && <div className={styles.newsletterBanner} />}
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.description}>
          <div className={styles.logo}></div>
          <div className={styles.descriptionTitle}>
            {t("footer.descriptionTitle")}
          </div>
          <div className={styles.descriptionText}>
            {t("footer.descriptionText")}
          </div>
          {isDesktop && <LanguageAndCopyright />}
        </div>
        <div className={styles.links}>
          <div className={styles.linkColumn}>
            <a className={styles.link} href="https://velio.de/impact">
              {t("footer.sustainability")}
            </a>
            <a className={styles.link} href="https://velio.trengohelp.com/de">
              {t("footer.customerService")}
            </a>
            <a className={styles.link} href="https://velio.de/impressum">
              {t("footer.press")}
            </a>
            <a className={styles.link} href="https://velio.de/dataprivacy">
              {t("footer.dataPrivacy")}
            </a>
            <a
              className={styles.link}
              href="https://velio.de/termsandconditions"
            >
              {t("footer.termsAndConditions")}
            </a>
            <a className={styles.link} href="https://velio.jobs.personio.de/">
              {t("footer.jobs")}
            </a>
          </div>
          <div className={styles.linkColumn}>
            {user?.bikes?.some((bike) => bike?.insurance) && (
              <a className={styles.link} href="https://velio.de/protect">
                {t("footer.protect")}
              </a>
            )}
            <a className={styles.link} href="https://velio.de/services">
              {t("footer.bikeManager")}
            </a>
            <a className={styles.link} href="https://velio.de/sell">
              {t("footer.sellMyBike")}
            </a>
            <a className={styles.link} href="https://velio.de/business">
              {t("footer.sellForCompanies")}
            </a>
          </div>
        </div>
        <div className={styles.social}>
          <div className={styles.socialTitle}>{t("footer.socialTitle")}</div>
          <div
            className={styles.email}
            onClick={(e) => {
              window.location.href = `mailto: hallo@velio.de`;
              e.preventDefault();
            }}
          >
            {t("footer.email")}
          </div>
          <div className={styles.socialIcons}>
            <a
              className={`${styles.socialIcon} ${styles.facebookIcon}`}
              href="https://www.facebook.com/veliomobility"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            <a
              className={`${styles.socialIcon} ${styles.twitterIcon}`}
              href="https://velio.de/shop"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              className={`${styles.socialIcon} ${styles.linkedInIcon}`}
              href="https://de.linkedin.com/company/veliomobility"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
            <a
              className={`${styles.socialIcon} ${styles.instagramIcon}`}
              href="https://www.instagram.com/velio_mobility"
              target="_blank"
              without
              rel="noreferrer"
            >
              Instagram
            </a>
          </div>
          {!isDesktop && <LanguageAndCopyright />}
        </div>
      </div>
    </div>
  );
};

export default Footer;
