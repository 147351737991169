import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ACTIVATE_BIKE,
  BIKE_PASS,
  CHECK_BOOK,
  DEALER,
  MY_BIKE,
} from "../../../constants/routes";
import CollapsibleSection from "../../../components/CollapsibleSection";

import styles from "./StatusesPanel.module.css";

const StatusesPanel = ({ bikeData, numberOfInspections, isBikeActivated }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getBikePassButton = () => {
    if (
      bikeData?.coverImage?.isUserUploaded &&
      bikeData?.bikePassInvoice &&
      (bikeData?.data.bike_type?.toLowerCase() === "bike" || bikeData?.batteryNumber) &&
      bikeData?.frameNumber
    ) {
      return (
        <>
          <div className={styles.description}>{t("garage.viewBikePass")}</div>
          <div
            className={`${styles.completionProgress} ${styles.greenCompletionProgress}`}
          />
        </>
      );
    } else if (
      bikeData?.coverImage?.isUserUploaded ||
      bikeData?.bikePassInvoice ||
      (bikeData?.data.bike_type?.toLowerCase() === "e-bike" && bikeData?.batteryNumber) ||
      bikeData?.frameNumber
    ) {
      return (
        <>
          <div className={styles.blueDescription}>
            {t("garage.finalizeBikePass")}
          </div>
          <div
            className={`${styles.completionProgress} ${styles.orangeCompletionProgress}`}
          />
        </>
      );
    } else {
      return (
        <>
          <div className={styles.blueDescription}>
            {t("garage.createBikePass")}
          </div>
          <div
            className={`${styles.completionProgress} ${styles.redCompletionProgress}`}
          />
        </>
      );
    }
  };

  return (
    <div className={styles.statusesPanel}>
      {!isBikeActivated && (
        <CollapsibleSection
          className={styles.statusElement}
          onClick={() => {
            navigate(`${ACTIVATE_BIKE}/${bikeData.id}`);
          }}
        >
          <div className={`${styles.title} ${styles.highlightedTitle}`}>
            {t("garage.bikeInactiveTitle")}
          </div>
          <div className={styles.description}>
            {t("garage.bikeInactiveText")}
          </div>
        </CollapsibleSection>
      )}
      {isBikeActivated && (
        <CollapsibleSection
          className={styles.statusElement}
          onClick={() => {
            navigate(`${BIKE_PASS}/${bikeData.id}`);
          }}
        >
          <div className={styles.title}>{t("garage.bikePass")}</div>
          {getBikePassButton()}
        </CollapsibleSection>
      )}
      {bikeData?.dealer && (
        <CollapsibleSection
          flex
          className={styles.statusElement}
          onClick={() => {
            navigate(`${DEALER}/${bikeData.id}`);
          }}
        >
          <div className={styles.title}>{t("garage.dealer")}</div>
          <div className={styles.description}>{bikeData?.dealer.name}</div>
        </CollapsibleSection>
      )}
      <CollapsibleSection
        className={styles.statusElement}
        onClick={() => {
          navigate(`${MY_BIKE}/${bikeData.id}${CHECK_BOOK}`);
        }}
      >
        <div className={styles.title}>{t("garage.inspections")}</div>
        {numberOfInspections ? (
          <div className={styles.description}>
            {numberOfInspections} {t("garage.entries")}
          </div>
        ) : (
          <div className={styles.description}>
            {t("garage.away")} {bikeData?.nextCheckBookDueDate}
          </div>
        )}
      </CollapsibleSection>
      <CollapsibleSection
        className={styles.statusElement}
        onClick={() => {
          window.open(
            "https://www.google.com/maps/d/embed?mid=1woHeNiQaXvtsjXIeJNxba13T3-bSTGwM&ehbc=2E312F&z=9",
            "_blank"
          );
        }}
      >
        <div className={styles.title}>{t("garage.chargingStation")}</div>
        <div className={styles.description}>{t("garage.mapText")}</div>
      </CollapsibleSection>
    </div>
  );
};

export default StatusesPanel;
