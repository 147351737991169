import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WELCOME } from "../../../constants/routes";
import bikeActions from "../../../redux/bike/bikeActions";

import styles from "./AddBikeElemWhenEmpty.module.css";

const AddBikeElemWhenEmpty = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onAddBike = () => {
    dispatch(bikeActions.resetFocusedBikeData());
    navigate(WELCOME);
  };

  return (
    <div className={styles.addBike}>
      <div className={styles.addSection} onClick={onAddBike}></div>
      <div className={styles.infoBlock}>
        <div className={styles.header}>{t("garage.velioBikeManager")}</div>
        <div className={styles.description}>
          {t("garage.velioBikeManagerDescription")}
        </div>
        <div className={styles.addButton} onClick={onAddBike}>
          {t("garage.addButton")}
        </div>
      </div>
    </div>
  );
};

export default AddBikeElemWhenEmpty;
