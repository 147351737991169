import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WELCOME } from "../../../constants/routes";
import bikeActions from "../../../redux/bike/bikeActions";

import styles from "./AddBikeElem.module.css";

const AddBikeElem = ({ shouldTransition, noText }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onAddBike = () => {
    dispatch(bikeActions.resetFocusedBikeData());
    navigate(WELCOME);
  };

  return (
    <div className={styles.addBike}>
      <div
        className={`${styles.addSection} ${
          shouldTransition ? styles.transitionedAddSection : ""
        }`}
        onClick={onAddBike}
      >
        <div
          className={`${styles.addIcon} ${
            shouldTransition ? styles.transitionedAddIcon : ""
          }`}
        ></div>
      </div>
      {!noText && (
        <div
          className={`${styles.infoBlock} ${
            shouldTransition ? styles.opacity0 : ""
          }`}
        >
          <div className={styles.header}>{t("garage.addBikeHeader")}</div>
          <div className={styles.description}>
            {t("garage.addBikeDescription")}
          </div>
          <div className={styles.addButton} onClick={onAddBike}>
            {t("garage.addButton")}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBikeElem;
