import { Helmet } from "react-helmet";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { UPDATE_USER } from "../../services/mutation";
import CollapsibleSection from "../../components/CollapsibleSection";
import authActions from "../../redux/auth/authActions";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import { EDIT_ACCOUNT, LOGIN, MY_ACCOUNT } from "../../constants/routes";
import { getUser } from "../../redux/auth/authSelectors";

import styles from "./MyAccount.module.css";

const MyAccount = () => {
  const user = useSelector(getUser) || {};
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateUser] = useMutation(UPDATE_USER);
  const { signOutSuccess } = authActions;

  const logOut = () => {
    dispatch(signOutSuccess());
    navigate(LOGIN);
  };

  const changeLanguage = async (locale) => {
    await updateUser({
      variables: {
        id: user?.id,
        user: {
          locale,
        },
      },
    }).then((res) => {
      i18n.changeLanguage(locale);
    });
  };

  const streetname = user.address?.streetname,
    housenumber = user.address?.housenumber,
    postcode = user.address?.postcode,
    city = user.address?.city;

  const addressLine =
    streetname || housenumber || postcode || city
      ? `${streetname} ${housenumber} ${postcode} ${city}`
      : "";

  return (
    <DesktopViewWithHeader>
      <Helmet>
        <title>My Account</title>
        <meta content="My Account. Velio | My account details." />
      </Helmet>
      <div className={styles.myAccountContent}>
        <div className={styles.header}>{t("myAccount.myAccount")}</div>
        <CollapsibleSection
          className={styles.section}
          onEdit={() => {
            navigate(`${MY_ACCOUNT}${EDIT_ACCOUNT}`);
          }}
        >
          <div className={styles.sectionHeader}>
            {user.firstName} {user.lastName}
          </div>
          <div className={styles.birthday}>
            {user.date_of_birth
              ? new Date(user.date_of_birth).toLocaleDateString()
              : "–"}
          </div>
          <div className={styles.email}>{user.email}</div>
          <div className={styles.contact}>{t("myAccount.contact")}</div>
          <div className={styles.address}>{addressLine || "–"}</div>
          <div className={styles.phone}>{user.phone ? user.phone : "–"}</div>
        </CollapsibleSection>
        <CollapsibleSection className={styles.section}>
          <div className={styles.sectionHeader}>{t("myAccount.language")}</div>
          <div
            className={`${styles.langElem} ${styles.marginTop} ${
              i18n.language === "de" ? styles.chosenLang : ""
            }`}
            onClick={() => {
              changeLanguage("de");
            }}
          >
            Deutsch
          </div>
          <div
            className={`${styles.langElem} ${
              i18n.language !== "de" ? styles.chosenLang : ""
            }`}
            onClick={() => {
              changeLanguage("en");
            }}
          >
            English
          </div>
        </CollapsibleSection>
        <CollapsibleSection className={styles.section}>
          <div className={styles.sectionHeader}>{t("myAccount.legal")}</div>
          <a
            className={`${styles.legalElem} ${styles.marginTop}`}
            href="https://www.velio.de/impressum"
            alt=""
            target="_blank"
            rel="noreferrer"
          >
            {t("myAccount.imprint")}
          </a>
          <a
            className={styles.legalElem}
            href="https://www.velio.de/datenschutz"
            alt=""
            target="_blank"
            rel="noreferrer"
          >
            {t("myAccount.dataPrivacy")}
          </a>
          <a
            className={styles.legalElem}
            href="https://www.velio.de/terms"
            alt=""
            target="_blank"
            rel="noreferrer"
          >
            {t("myAccount.termsOfUse")}
          </a>
        </CollapsibleSection>
        <CollapsibleSection className={styles.section} onClick={logOut}>
          <div className={styles.logOutButton}>{t("myAccount.logOut")}</div>
        </CollapsibleSection>
      </div>
    </DesktopViewWithHeader>
  );
};

export default MyAccount;
