import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";

import { UPDATE_USER } from "../../../services/mutation";
import { getUser } from "../../../redux/auth/authSelectors";
import authActions from "../../../redux/auth/authActions";

import styles from "./LanguageWidget.module.css";

const LanguageWidget = ({ className }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser) || {};
  const [updateUser] = useMutation(UPDATE_USER);
  const { getCurrentUserSuccess } = authActions;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const changeLanguage = async (locale) => {
    await updateUser({
      variables: {
        id: user?.id,
        user: {
          locale,
        },
      },
    }).then((res) => {
      dispatch(getCurrentUserSuccess(res.data.updateUser));
      i18n.changeLanguage(locale);
    });
  };

  const onToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const language =
    user.locale === "de" ? "Deutsch" : "en" ? "English" : "Unset";

  return user?.id ? (
    <div
      className={`${styles.languageWidget} ${className}`}
      onClick={onToggleDropdown}
    >
      <div
        className={`${styles.languageIcon} ${
          language === "Deutsch"
            ? styles.deutschIcon
            : language === "English"
            ? styles.englishIcon
            : styles.unsetIcon
        }`}
      />
      <div className={styles.languageName}>{language}</div>
      <div className={styles.switcherIcon} />
      {isDropdownOpen && (
        <div className={styles.dropdown}>
          <div
            className={styles.dropdownElement}
            onClick={() => changeLanguage("de")}
          >
            Deutsch
          </div>
          <div
            className={styles.dropdownElement}
            onClick={() => changeLanguage("en")}
          >
            English
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default LanguageWidget;
