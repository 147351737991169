import styles from "./InsuranceInterface.module.css";

const pagesStyles = {
  bike: [
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
    styles.line,
    styles.circle,
    styles.line,
    styles.circle,
    styles.line,
    styles.circle,
  ],
  profile: [
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
    styles.line,
    styles.circle,
    styles.line,
    styles.circle,
  ],
  payment: [
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
    styles.line,
    styles.circle,
  ],
  overview: [
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
    styles.activeLine,
    styles.activeCircle,
  ],
};

export default pagesStyles;
