import React, { createContext, useContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { useQuery } from "@apollo/client";
import { GET_ANALYTICS_TOKEN } from "../services/query";

function initializeMixpanel(token) {
  mixpanel.init(token);
  mixpanel.register({
    browser_language: window.navigator.languages
      ? window.navigator.languages[0]
      : window.navigator.language ||
        window.navigator.browserLanguage ||
        window.navigator.userLanguage,
  });
  campaignParams(mixpanel);
  return {
    identify: (id) => {
      mixpanel.identify(id);
    },
    alias: (id) => {
      mixpanel.alias(id);
    },
    register: (id, value) => {
      mixpanel.register({ id: value });
    },
    track: (name, props) => {
      mixpanel.track(name, props);
    },
    track_links: (query, name) => {
      mixpanel.track_links(query, name, {
        referrer: document.referrer,
      });
    },
    people: {
      set: (props) => {
        mixpanel.people.set(props);
      },
    },
  };
}

export const MixpanelContext = createContext({});

export const useMixpanel = () => {
  const contextValue = useContext(MixpanelContext);
  if (!contextValue) {
    throw new Error("Please call useMixpanel within a MixpanelProvider");
  }
  return contextValue;
};

export const MixpanelProvider = ({ children }) => {
  const [mixpanel, setMixpanel] = useState({});
  const { data } = useQuery(GET_ANALYTICS_TOKEN);

  useEffect(() => {
    const initMixpanel = () => {
      const initializedMixpanel = initializeMixpanel(data.analyticsToken);
      !mixpanel.track && setMixpanel(initializedMixpanel);
    };
    if (data) {
      initMixpanel();
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {children}
    </MixpanelContext.Provider>
  );
};

function getQueryParam(url, param) {
  // Expects a raw URL
  // blatantly copied from: https://community.mixpanel.com/data-management-10/utm-parameters-as-people-properties-4953
  // and our linter doesn't like their code, so...
  /* eslint-disable */
  param = param.replace(/[[]/, "[").replace(/[]]/, "]");
  var regexS = "[?&]" + param + "=([^&#]*)",
    regex = new RegExp(regexS),
    results = regex.exec(url);
  if (
    results === null ||
    (results && typeof results[1] !== "string" && results[1].length)
  ) {
    return "";
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, " ");
  }
  /* eslint-enable */
}
function campaignParams(mixpanel) {
  var campaign_keywords =
      "utm_source utm_medium utm_campaign utm_content utm_term".split(" "),
    kw = "",
    params = {},
    first_params = {};
  var index;
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      params[campaign_keywords[index] + " [last touch]"] = kw;
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      first_params[campaign_keywords[index] + " [first touch]"] = kw;
    }
  }
  mixpanel.people.set(params);
  mixpanel.people.set_once(first_params);
  mixpanel.register(params);
  mixpanel.identify();
}

export const mixpanelGender = (localeGender) => {
  switch (("" + localeGender).toLowerCase()) {
    case "herr":
    case "male":
    case "h":
    case "m":
      return "male";
    case "frau":
    case "female":
    case "f":
      return "female";
    default:
      return "unknown";
  }
};
