import { useEffect } from "react";
import { Routes as ReactRoutes, Route, useLocation } from "react-router-dom";

import { useMixpanel } from "../../utils/mixpanel";
import useMyBikeConfigs from "../../pages/MyBike/hooks/useAllConfigs";
import Home from "../../pages/Home";
import Garage from "../../pages/Garage";
import ActivateBike from "../../pages/ActivateBike";
import MyBike from "../../pages/MyBike";
import BikePhotos from "../../pages/BikePhotos";
import MyBikeEdit from "../../pages/MyBike/EditPages";
import Dealer from "../../pages/Dealer";
import Welcome from "../../pages/Onboarding/Welcome";
import BikeOptions from "../../pages/Onboarding/BikeOptions";
import AddBikeOption from "../../pages/Onboarding/AddBikeOption";
import CreateProfile from "../../pages/Onboarding/CreateProfile";
import OnboardingPrice from "../../pages/Onboarding/Price";
// import InsuranceChoose from "../../pages/Onboarding/InsuranceChoose";
import InsuranceBikeDetails from "../../pages/Onboarding/InsuranceBikeDetails";
import InsuranceProfile from "../../pages/Onboarding/InsuranceProfile";
import InsurancePayment from "../../pages/Onboarding/InsurancePayment";
import InsuranceOverview from "../../pages/Onboarding/InsuranceOverview";
import InsuranceSuccess from "../../pages/Onboarding/InsuranceSuccess";
import DashboardPage from "../../pages/DashboardPage/DashboardPage";
import BikePass from "../../pages/BikePass";
import BikePassEdit from "../../pages/BikePassEdit";
import Login from "../../pages/Login/Login";
import ResetPassword from "../../pages/ResetPassword/ResetPassword";
import Checkbook from "../../pages/Checkbook/Checkbook";
import Inspection from "../../pages/Inspection/Inspection";
import InspectionForm from "../../pages/Inspection/InspectionForm";
import MyAccount from "../../pages/MyAccount";
import MyAccountEdit from "../../pages/MyAccountEdit";
import EditInspectionForm from "../../pages/Inspection/EditInspectionForm";
import InviteFriend from "../../pages/InviteFriend";
import DealerAlias from "../../pages/DealerAlias";
import Insurance from "../../pages/Insurance";
import AccessRoute from "./AccessRoute";

import {
  ADD_CHECK,
  GARAGE,
  ACTIVATE_BIKE,
  LOGIN,
  MY_ACCOUNT,
  MY_BIKE,
  DASHBOARD,
  DEALER,
  DEALER_ALIAS,
  // INSURANCE_CHOOSE,
  INSURANCE_BIKE_DETAILS,
  INSURANCE_PROFILE,
  INSURANCE_PAYMENT,
  INSURANCE_OVERVIEW,
  INSURANCE_SUCCESS,
  INSURANCE_GARAGE,
  EDIT_ACCOUNT,
  INVITE_FRIEND,
  WELCOME,
  BIKE_OPTIONS,
  MANUFACTURER,
  MODEL,
  ADD_OPTION,
  CREATE_PROFILE,
  ONBOARDING_PRICE,
  BIKE_PASS,
  BIKE_PASS_EDIT,
  FORGOT_PASSWORD,
  CHECK_BOOK,
  EDIT_CHECK,
  PHOTOS,
} from "../../constants/routes";

const Routes = () => {
  const myBikeConfigs = useMyBikeConfigs();
  const mixpanel = useMixpanel();
  const pathname = useLocation().pathname;

  useEffect(() => {
    if (typeof mixpanel.track === "function") {
      mixpanel.track("page_viewed", {
        url: window.location.origin + pathname,
        platform: "mybike frontend",
      });
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <ReactRoutes>
      <Route path="/" element={<Home />} />
      <Route
        path={GARAGE}
        element={
          <AccessRoute>
            <Garage />
          </AccessRoute>
        }
      />
      <Route
        path={`${ACTIVATE_BIKE}/:id`}
        element={
          <AccessRoute>
            <ActivateBike />
          </AccessRoute>
        }
      />
      <Route
        path={MY_ACCOUNT}
        element={
          <AccessRoute>
            <MyAccount />
          </AccessRoute>
        }
      />
      <Route
        path={INSURANCE_GARAGE}
        element={
          <AccessRoute>
            <Insurance />
          </AccessRoute>
        }
      />
      <Route
        path={`${MY_ACCOUNT}${EDIT_ACCOUNT}`}
        element={
          <AccessRoute>
            <MyAccountEdit />
          </AccessRoute>
        }
      />
      <Route path={`${MY_BIKE}/:id`} element={<MyBike />} />
      <Route
        path={`${MY_BIKE}/:id${PHOTOS}`}
        element={
          <AccessRoute>
            <BikePhotos />
          </AccessRoute>
        }
      />
      {myBikeConfigs.map((config) => (
        <Route
          exact
          path={`${MY_BIKE}/:id/${config.editPageName}`}
          element={
            <AccessRoute>
              <MyBikeEdit />
            </AccessRoute>
          }
          key={config.editPageName}
        />
      ))}
      <Route path={INVITE_FRIEND} element={<InviteFriend />} />
      <Route
        path={`${DEALER}/:id`}
        element={
          <AccessRoute>
            <Dealer />
          </AccessRoute>
        }
      />
      <Route
        path={WELCOME}
        element={
          <AccessRoute>
            <Welcome />
          </AccessRoute>
        }
      />
      <Route
        path={`${BIKE_OPTIONS}${MANUFACTURER}`}
        element={
          <AccessRoute>
            <BikeOptions />
          </AccessRoute>
        }
      />
      <Route
        path={`${BIKE_OPTIONS}${MODEL}`}
        element={
          <AccessRoute>
            <BikeOptions />
          </AccessRoute>
        }
      />
      <Route
        path={`${BIKE_OPTIONS}${MANUFACTURER}${ADD_OPTION}`}
        element={
          <AccessRoute>
            <AddBikeOption />
          </AccessRoute>
        }
      />
      <Route
        path={`${BIKE_OPTIONS}${MODEL}${ADD_OPTION}`}
        element={
          <AccessRoute>
            <AddBikeOption />
          </AccessRoute>
        }
      />
      <Route
        path={CREATE_PROFILE}
        element={
          <AccessRoute>
            <CreateProfile />
          </AccessRoute>
        }
      />
      <Route
        path={ONBOARDING_PRICE}
        element={
          <AccessRoute>
            <OnboardingPrice />
          </AccessRoute>
        }
      />
      {/* <Route path={INSURANCE_CHOOSE} element={<InsuranceChoose />} /> */}
      <Route path={INSURANCE_BIKE_DETAILS} element={<InsuranceBikeDetails />} />
      <Route
        path={INSURANCE_PROFILE}
        element={
          <AccessRoute>
            <InsuranceProfile />
          </AccessRoute>
        }
      />
      <Route
        path={INSURANCE_PAYMENT}
        element={
          <AccessRoute>
            <InsurancePayment />
          </AccessRoute>
        }
      />
      <Route
        path={INSURANCE_OVERVIEW}
        element={
          <AccessRoute>
            <InsuranceOverview />
          </AccessRoute>
        }
      />
      <Route
        path={INSURANCE_SUCCESS}
        element={
          <AccessRoute>
            <InsuranceSuccess />
          </AccessRoute>
        }
      />
      <Route
        path={DASHBOARD}
        element={
          <AccessRoute>
            <DashboardPage />
          </AccessRoute>
        }
      />
      <Route
        path={`${BIKE_PASS}/:id`}
        element={
          <AccessRoute>
            <BikePass />
          </AccessRoute>
        }
      />
      <Route
        path={`${BIKE_PASS}/:id${BIKE_PASS_EDIT}`}
        element={
          <AccessRoute>
            <BikePassEdit />
          </AccessRoute>
        }
      />
      <Route
        path={LOGIN}
        element={
          <AccessRoute>
            <Login />
          </AccessRoute>
        }
      />
      <Route
        path={FORGOT_PASSWORD}
        element={
          <AccessRoute>
            <ResetPassword />
          </AccessRoute>
        }
      />
      {/* Checkbook */}
      <Route
        path={`${MY_BIKE}/:bikeId${CHECK_BOOK}`}
        element={
          <AccessRoute>
            <Checkbook />
          </AccessRoute>
        }
      />
      <Route
        path={`${MY_BIKE}/:bikeId/:eventType${ADD_CHECK}`}
        element={
          <AccessRoute>
            <InspectionForm />
          </AccessRoute>
        }
      />
      <Route
        path={`${MY_BIKE}/:bikeId${CHECK_BOOK}/:eventType/:checkBookId`}
        element={
          <AccessRoute>
            <Inspection />
          </AccessRoute>
        }
      />
      <Route
        path={`${MY_BIKE}/:bikeId${CHECK_BOOK}/:eventType/:checkBookId${EDIT_CHECK}`}
        element={
          <AccessRoute>
            <EditInspectionForm />
          </AccessRoute>
        }
      />
      <Route
        path={`${DEALER_ALIAS}/:dealerAlias`}
        element={
          <AccessRoute>
            <DealerAlias />
          </AccessRoute>
        }
      />
    </ReactRoutes>
  );
};

export default Routes;
