import { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "../../components/Button";
import FormLabel from "../../components/FormLabel/FormLabel";
import Selector from "../../components/Selector";
import Banner from "../../components/Banner";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { CREATE_CHECKBOOK } from "../../services/mutation";
import getBikeShedURIFromHost from "../../utils/getBikeShedURIFromHost";
import { normalizedDate } from "../../utils/normalizedDate";
import { CHECK_BOOK, MY_BIKE } from "../../constants/routes";

import styles from "./Inspection.module.css";

const EVENT_TYPE = {
  inspection: "INSPECTION",
  repair: "REPAIR",
};

const getMessages = (t) => {
  return {
    repair: {
      pageTitle: t("checkbook.addRepair"),
    },
    inspection: {
      pageTitle: t("checkbook.addInspection"),
    },
  };
};

const eventSchema = yup.object().shape({
  bikeID: yup.string(),
  eventType: yup.string(),
  date: yup.date(),
  notes: yup.string(),
  documentID: yup.string(),
  dealerID: yup.string(),
  nextDue: yup.string(),
});

async function postData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: data,
  });
  return response.json();
}

async function uploadDocument(file) {
  const API = getBikeShedURIFromHost();
  return await postData(API, file);
}

export default function InspectionForm() {
  const [dateOfRepair, setDateOfRepair] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const isDesktop = useWindowWidth() > 1280;
  const labels = getMessages(t);
  const messages = labels[params.eventType];
  const [createCheckBook] = useMutation(CREATE_CHECKBOOK);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bikeID: "",
      eventType: "",
      date: new Date(),
      notes: "",
      dealerID: "Privat",
    },
    mode: "onBlur",
    resolver: yupResolver(eventSchema),
  });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // We want to support single file upload for now
      setUploadError("");
      const fileToUpload = acceptedFiles[0];
      const data = new FormData();
      data.append("file", fileToUpload);
      uploadDocument(data)
        .then((response) => {
          setUploadedFile(
            Object.assign(fileToUpload, {
              preview: URL.createObjectURL(fileToUpload),
            })
          );
          setValue("documentID", response.id);
        })
        .catch((err) => setUploadError(t("checkbook.uploadError")));
    },
    // eslint-disable-next-line
    [setValue]
  );

  const isRepair = params.eventType === "repair";

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*,application/pdf",
  });

  const goToCheckbook = () => {
    navigate(`${MY_BIKE}/${params.bikeId}${CHECK_BOOK}`);
  };

  const onSubmit = async (data) => {
    await createCheckBook({
      variables: {
        data: {
          bikeID: params.bikeId,
          eventType: EVENT_TYPE[params.eventType],
          date: data.date,
          notes: isRepair ? `${data.dealerID} - ${data.notes}` : data.notes,
          documentID: data?.documentID,
        },
      },
    })
      .then(() => {
        goToCheckbook();
      })
      .catch(() => {
        console.error("Error creating checkbook");
      });
  };

  const handleDateChange = (date, e) => {
    e.preventDefault();
    setDateOfRepair(date);
    setValue("date", date);
  };

  return (
    <>
      <Helmet>
        <title>Add Event</title>
        <meta name="description" content="Checkbook - Velio" />
      </Helmet>
      <DesktopViewWithHeader
        footer={
          <div className={styles.actions}>
            <Button
              onClick={goToCheckbook}
              className={`${styles.btn} ${styles.white}`}
            >
              <span>{t("checkbook.cancel")}</span>
              <span className={styles.CancelIcon}></span>
            </Button>
            <Button onClick={handleSubmit(onSubmit)} className={styles.btn}>
              <span>{t("checkbook.save")}</span>
              <span className={styles.SaveIcon}></span>
            </Button>
          </div>
        }
      >
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <div className={styles.backButton} onClick={goToCheckbook}></div>
            <div className={styles.pageTitle}>{messages.pageTitle}</div>
            <div></div>
          </div>
          <Banner />
          {isRepair ? (
            <p className={styles.description}>
              {t("checkbook.repairDescription")}
            </p>
          ) : null}
          <form
            noValidate
            className={styles.form}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <FormLabel className={styles.labelDatePicker}>
              {t("checkbook.dateOfRepair")}
              <DatePicker
                selected={dateOfRepair}
                onChange={(date, event) => handleDateChange(date, event)}
                className={styles.inputPicker}
                dateFormat="dd.MM.yyyy"
                placeholderText={normalizedDate(new Date())}
                maxDate={new Date()}
                onFocus={(e) => e.target.blur()}
              />
              {errors?.date && (
                <p className={styles.errorMessage}>Please select a date.</p>
              )}
            </FormLabel>
            {isRepair ? (
              <>
                <FormLabel>
                  <p className={styles.formLabel}>{t("checkbook.repairBy")}</p>
                  <div className={`${styles.card} ${styles.spacer}`}>
                    <Selector
                      t={t}
                      register={register}
                      onChange={(e) => setValue("dealerID", e.target.value)}
                    />
                  </div>
                  {errors?.dealerID && (
                    <p className={styles.errorMessage}>
                      Please choose an option
                    </p>
                  )}
                </FormLabel>
                <p className={styles.repairDescription}>
                  {t("checkbook.typeOfRepair")}
                </p>
              </>
            ) : null}
            <FormLabel>
              <p className={styles.formLabel}>{t("checkbook.description")}</p>
              <div className={`${styles.card} ${styles.spacer}`}>
                <textarea
                  className={styles.textarea}
                  rows={5}
                  placeholder={t("checkbook.descriptionPlaceholder")}
                  id="notes"
                  name="notes"
                  {...register("notes")}
                />
              </div>
              {errors?.notes && (
                <p className={styles.errorMessage}>{errors.notes.message}</p>
              )}
            </FormLabel>
            <p className={`${styles.formLabel} ${styles.evidence}`}>
              {t("checkbook.invoiceAndEvidence")}
            </p>
            <p className={styles.repairDescription}>
              {t("checkbook.addInvoice")}
            </p>

            {uploadedFile ? (
              <>
                <div
                  {...getRootProps()}
                  className={`${styles.card} ${styles.round}`}
                >
                  <div className={styles.document}>
                    <p className={styles.documentText}>
                      {t("checkbook.replaceDocument")}
                    </p>
                    <div className={styles.replaceIcon}></div>
                  </div>
                </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={URL.createObjectURL(uploadedFile)}
                >
                  <div className={`${styles.card} ${styles.cursor}`}>
                    <div className={styles.viewDocument}>
                      <div className={styles.bigFileIcon}></div>
                      <div>
                        <p className={styles.documentText}>
                          {t("checkbook.viewFile")}
                        </p>
                        <p className={styles.documentSubtitle}>
                          {t("checkbook.documentUploaded")}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </>
            ) : (
              <div
                {...getRootProps()}
                className={`${styles.card} ${styles.round}`}
              >
                <div className={styles.document}>
                  <input {...getInputProps()} />
                  <p className={styles.documentText}>
                    {t("checkbook.documentPlaceholder")}
                  </p>
                  <div className={styles.documentIcon}></div>
                </div>
              </div>
            )}
            {!!uploadError && (
              <div className={styles.errorMessage}>{uploadError}</div>
            )}
          </form>
          {!isDesktop && (
            <div className={styles.actionWrapper}>
              <div className={styles.actions}>
                <Button
                  onClick={goToCheckbook}
                  className={`${styles.btn} ${styles.white}`}
                >
                  <span>{t("checkbook.cancel")}</span>
                  <span className={styles.CancelIcon}></span>
                </Button>
                <Button onClick={handleSubmit(onSubmit)} className={styles.btn}>
                  <span>{t("checkbook.save")}</span>
                  <span className={styles.SaveIcon}></span>
                </Button>
              </div>
            </div>
          )}
        </div>
      </DesktopViewWithHeader>
    </>
  );
}
