import styles from './BasicButton.module.css';

const BasicButton = ({text, onClick, buttonClassName, textClassName, iconClassName}) => {
  return (
    <div className={`${styles.button} ${buttonClassName}`} onClick={onClick}>
      <div className={`${styles.buttonText} ${textClassName}`}>{text}</div>
      <div className={`${styles.buttonIcon} ${iconClassName}`}></div>
    </div>
  );
};
export default BasicButton;
