import { useTranslation } from "react-i18next";

import styles from "./InsuranceStatus.module.css";

const InsuranceStatus = ({ isInsured, className }) => {
  const { t } = useTranslation();

  return isInsured ? (
    <div
      className={`${styles.insuranceStatus} ${className} ${
        isInsured ? styles.insured : styles.notInsured
      }`}
    >
      {isInsured ? t("garage.insured") : t("garage.notInsured")}
    </div>
  ) : null;
};

export default InsuranceStatus;
