import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import authActions from "../../redux/auth/authActions";
import FormLabel from "../../components/FormLabel/FormLabel";
import Button from "../../components/Button";
import DesktopView from "../../components/DesktopView";
import { RESET_PASSWORD } from "../../services/mutation";
import { GARAGE } from "../../constants/routes";

import styles from "./ResetPassword.module.css";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "At least 8 characters required")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const { t } = useTranslation();
  const { signInSuccess } = authActions;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    await resetPassword({
      variables: {
        newPassword: data.newPassword,
        linkId: searchParams.get("link_id") || "",
      },
    })
      .then((response) => {
        const data = response.data.forgotPassword;
        setResetPasswordError("");
        dispatch(signInSuccess({ user: data.user }));
        navigate(GARAGE);
      })
      .catch(() => {
        setResetPasswordError(t("auth.submitEmailError"));
      });
  };

  return (
    <DesktopView>
      <Helmet>
        <title>{t("resetPasswordPageTitle")}</title>
        <meta name="theme-color" content="#F3F3F3" />
      </Helmet>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <div className={styles.pageTitle}>{t("auth.newPassword")}</div>
        </div>
        <div>
          <p className={styles.description}>{t("auth.newPasswordDesc1")}</p>
        </div>
        <form
          noValidate
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <FormLabel className={styles.label}>
            {t("auth.enterNewPassword")}
            <input
              className={styles.input}
              type="password"
              name="newPassword"
              id="newPassword"
              placeholder={"********"}
              {...register("newPassword")}
            />
          </FormLabel>
          {errors?.newPassword && (
            <p className={styles.errorMessage}>{errors.newPassword.message}</p>
          )}
          <FormLabel className={styles.label}>
            {t("auth.confirmNewPassword")}
            <input
              className={styles.input}
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder={"********"}
              {...register("confirmPassword")}
            />
          </FormLabel>
          {errors?.confirmPassword && (
            <p className={styles.errorMessage}>
              {errors.confirmPassword.message}
            </p>
          )}
          {resetPasswordError && (
            <p className={styles.errorMessage}>{resetPasswordError}</p>
          )}
          <Button className={styles.btn}>
            <span>{t("auth.saveNewPassword")}</span>
          </Button>
        </form>
      </div>
    </DesktopView>
  );
}
