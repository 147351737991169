import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import Buttons from "./Buttons";
import DateInput from "../../components/Inputs/Date";
import TextInput from "../../components/Inputs/Text";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import {
  CREATE_ADDRESS,
  SET_USER_ADDRESS,
  UPDATE_ADDRESS,
  UPDATE_USER,
} from "../../services/mutation";
import authActions from "../../redux/auth/authActions";
import { getUser } from "../../redux/auth/authSelectors";
import { useWindowWidth } from "../../hooks/useWindowWidth";

import styles from "./MyAccountEdit.module.css";

const MyAccountEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const isDesktop = useWindowWidth() > 1280;
  const user = useSelector(getUser) || {};
  const { getCurrentUserSuccess } = authActions;
  const [createAddress] = useMutation(CREATE_ADDRESS);
  const [setUserAddress] = useMutation(SET_USER_ADDRESS);
  const [updateAddress] = useMutation(UPDATE_ADDRESS);
  const [updateUser] = useMutation(UPDATE_USER);
  const [selectedDate, setSelectedDate] = useState(user?.date_of_birth || null);
  const [street, setStreet] = useState(user?.address?.streetname || "");
  const [houseNum, setHouseNum] = useState(user?.address?.housenumber || "");
  const [zip, setZip] = useState(user?.address?.postcode || "");
  const [city, setCity] = useState(user?.address?.city || "");
  const [phone, setPhone] = useState(user?.phone || "");

  const prevPathname = pathname.split("/").slice(0, -1).join("/");

  const getButtons = () => (
    <Buttons
      onCancel={() => {
        navigate(prevPathname);
      }}
      onSave={async () => {
        if (user?.address) {
          await updateAddress({
            variables: {
              id: user?.address.id,
              in: {
                streetname: street,
                housenumber: houseNum,
                postcode: zip,
                city,
                country: user?.address.country,
              },
            },
          });
        } else {
          const newAddress = await createAddress({
            variables: {
              in: {
                streetname: street,
                housenumber: houseNum,
                postcode: zip,
                city,
                country: "germany",
              },
            },
          });
          await setUserAddress({
            variables: {
              user_id: user?.id,
              address_id: newAddress.data.createAddress.id,
            },
          });
        }
        await updateUser({
          variables: {
            id: user?.id,
            user: {
              dateOfBirth: selectedDate || null,
              phone,
            },
          },
        }).then((res) => {
          dispatch(getCurrentUserSuccess(res.data.updateUser));
        });
        navigate(prevPathname);
      }}
    />
  );

  return (
    <DesktopViewWithHeader footer={getButtons()}>
      <Helmet>
        <title>My Account Edit</title>
        <meta content="My Account Edit. Velio | My account details edit." />
      </Helmet>
      <div className={styles.myAccountEdit}>
        <div className={styles.header}>{t("myAccount.myAccount")}</div>
        {!user?.date_of_birth && (
          <>
            <div className={styles.label}>{t("myAccount.birthday")}</div>
            <div className={styles.inputContainer}>
              <DateInput
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>
          </>
        )}
        <div className={styles.doubleInputs}>
          <div className={styles.halfSizeInput}>
            <div className={styles.label}>{t("myAccount.street")}</div>
            <div className={styles.inputContainer}>
              <TextInput value={street} onChange={setStreet} />
            </div>
          </div>
          <div className={styles.halfSizeInput}>
            <div className={styles.label}>{t("myAccount.houseNum")}</div>
            <div className={styles.inputContainer}>
              <TextInput value={houseNum} onChange={setHouseNum} />
            </div>
          </div>
        </div>
        <div className={styles.doubleInputs}>
          <div className={styles.halfSizeInput}>
            <div className={styles.label}>{t("myAccount.zipCode")}</div>
            <div className={styles.inputContainer}>
              <TextInput
                value={zip}
                onChange={(value) => {
                  if (value.length <= 5) {
                    setZip(value);
                  }
                }}
                type="number"
              />
            </div>
          </div>
          <div className={styles.halfSizeInput}>
            <div className={styles.label}>{t("myAccount.city")}</div>
            <div className={styles.inputContainer}>
              <TextInput
                value={city}
                onChange={(value) => {
                  if (value.split("").every((char) => isNaN(+char))) {
                    setCity(value);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.label}>{t("myAccount.mobile")}</div>
        <div className={styles.inputContainer}>
          <TextInput value={phone} onChange={setPhone} type="number" />
        </div>
        {!isDesktop && getButtons()}
      </div>
    </DesktopViewWithHeader>
  );
};

export default MyAccountEdit;
