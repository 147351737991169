import useMainSpecsConfig from "./useMainSpecsConfig";
import useSpecialSpecsConfig from "./useSpecialSpecsConfig";
import useDescriptionConfig from "./useDescriptionConfig";
import useFullSpecsConfigsSet from "./useFullSpecsConfigsSet";

const useAllConfigs = (bikeData, additional) => [
  useMainSpecsConfig(bikeData, additional),
  useSpecialSpecsConfig(bikeData, additional),
  useDescriptionConfig(bikeData, additional),
  ...useFullSpecsConfigsSet(bikeData, additional),
];

export default useAllConfigs;
