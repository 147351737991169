import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";

import { useWindowWidth } from "../../../hooks/useWindowWidth";
import { EXPORT_BIKE_PASS_PDF } from "../../../services/mutation";
import { BIKE_PASS, BIKE_PASS_EDIT } from "../../../constants/routes";

import styles from "./FinalizeButton.module.css";

const FinalizeButton = ({ isCompleted, bike, user }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [exportBikePassPDF] = useMutation(EXPORT_BIKE_PASS_PDF);
  const navigate = useNavigate();
  const isDesktop = useWindowWidth() > 1280;
  const bikeId = bike?.id;
  const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);

  const onFinalize = () => {
    if (isCompleted) {
      exportBikePassPDF({
        variables: {
          in: {
            bikeId: bike.id,
            manufacturer: bike?.data?.manufacturer,
            modelName: bike?.data?.model_name,
            bikeType:
              bike?.data?.bike_type?.toLowerCase() === "e-bike"
                ? "E_BIKE"
                : bike?.data?.bike_type?.toLowerCase() === "bike"
                ? "BIKE"
                : bike?.data?.bike_type,
            category: bike.data?.category || "-",
            buyingPrice: `${bike?.data?.price}`,
            buyingDate: new Date(bike?.data.boughtAt)?.toLocaleDateString(
              "en"
            ),
            frameNumber: bike?.frameNumber,
            batteryNumber: bike?.batteryNumber,
            gearType: bike?.data?.gear_type || "-",
            rearDerailleur: bike?.data?.rear_derailleur || "-",
            numberOfGears: bike?.data?.number_of_gears || "-",
            color: bike?.data?.color,
            wheelSize: bike?.data?.wheel_size || "-",
            gender: bike?.data?.gender || "-",
            identificationCode: bike?.data.user_id,
            privateDescription: bike?.data?.user_description || "-",
            locale:
              user?.locale === "DE"
                ? "de"
                : user?.locale === "EN"
                ? "en"
                : user?.locale,
          },
        },
      })
        .then((res) => {
          fetch(
            `data:application/pdf;base64, ${encodeURI(
              res.data.exportBikePassPDF.Content
            )}`
          )
            .then((res) => res.blob())
            .then((res) => {
              if (isDesktop || isIOS) {
                saveAs(res, "BikePass.pdf");
              } else {
                setTimeout(() => {
                  window.open(URL.createObjectURL(res));
                }, 4);
              }
            });

          setErrorMessage("");
        })
        .catch((err) => {
          setErrorMessage(t("bikePass.exportError"));
          throw err;
        });
    } else {
      navigate(`${BIKE_PASS}/${bikeId}${BIKE_PASS_EDIT}`);
    }
  };

  return (
    <>
      <div className={styles.footerFiller}></div>
      <div className={styles.finalizeButton} onClick={onFinalize}>
        <div className={styles.finalizeButtonText}>
          {errorMessage
            ? errorMessage
            : isCompleted
            ? t("bikePass.export")
            : t("bikePass.finalize")}
        </div>
        <div
          className={isCompleted ? styles.exportIcon : styles.toRightIcon}
        ></div>
      </div>
    </>
  );
};
export default FinalizeButton;
