export function errorHandlerBE(value, toast, t) {
  // eslint-disable-next-line
  value?.errors?.map(({ errorCode, fieldName }) => {
    switch (errorCode) {
      case 100:
        toast(
          t("onboarding.legacy.InsuranceErrorFieldTooBig", {
            FieldName: fieldName,
          })
        );
        break;
      case 101:
        toast(
          t("onboarding.legacy.InsuranceErrorFieldTooSmall", {
            FieldName: fieldName,
          })
        );
        break;
      case 102:
        toast(
          t("onboarding.legacy.insuranceErrorFieldInvalidValue", {
            FieldName: fieldName,
          })
        );
        break;
      case 103:
        toast(
          t("onboarding.legacy.InsuranceErrorMissingField", {
            FieldName: fieldName,
          })
        );
        break;
      case 104:
        toast(
          t("onboarding.legacy.InsuranceErrorNotSupported", {
            FieldName: fieldName,
          })
        );
        break;
      case 200:
        toast(t("onboarding.legacy.InsuranceErrorInternalProblemRetry"));
        break;
      case 201:
        toast(t("onboarding.legacy.InsuranceErrorInternalProblemAbort"));
        break;

      default:
        break;
    }
  });
}
