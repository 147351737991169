import { useState, useEffect } from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import { GET_ALL_CHECKBOOK } from "../../services/query";
import { getUser } from "../../redux/auth/authSelectors";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import AddBikeElem from "./AddBikeElem";
import SocialPanel from "./SocialPanel";
import ActionsPanel from "./ActionsPanel";
import StatusesPanel from "./StatusesPanel";
import Bike from "./Bike";
import AddBikeElemWhenEmpty from "./AddBikeElemWhenEmpty";
import generalActions from "../../redux/general/generalActions";

import styles from "./Garage.module.css";

const Garage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUser) || {};
  const { setToast } = generalActions;
  const [chosenElementId, setChosenElementId] = useState(0);
  const [isTransparent, setIsTransparent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const isDesktop = useWindowWidth() > 1280;
  const userBikes = user?.bikes?.map((bike) => ({
    ...bike,
    data: { ...JSON.parse(bike.data) },
  }));
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.search
        .split("?")
        .find((searchParam) => searchParam === "bike_bought=true")
    ) {
      dispatch(
        setToast({
          header: t("garage.circularRedirectHeader"),
          body: t("garage.circularRedirectBody"),
          shouldRender: true,
        })
      );

      navigate("..");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useQuery(GET_ALL_CHECKBOOK, {
    variables: {
      bike_id: userBikes ? userBikes[chosenElementId]?.id : "",
      limit: 30,
    },
    fetchPolicy: "network-only",
    skip: !userBikes,
  });
  const checkBookList = data?.checkBooksForBike || [];

  const getDotSize = (id) => {
    if (id === chosenElementId) {
      return "largeDot";
    } else if (id + 2 >= chosenElementId && id - 2 <= chosenElementId) {
      return "mediumDot";
    } else if (id === chosenElementId + 3 || id === chosenElementId - 3) {
      return "smallDot";
    } else {
      return "tinyDot";
    }
  };

  const getTransitionFactor = (id, isPreLast) => {
    if (chosenElementId < id) {
      return "-16px";
    } else if (chosenElementId === id) {
      return "0px";
    } else if (chosenElementId > id) {
      if (isPreLast) {
        return "48px";
      }
      return "16px";
    }
  };

  const getIsParsedBikeActivated = (bike) => {
    return !!bike?.data?.frame_size;
  };

  useEffect(() => {
    if (chosenElementId >= userBikes?.length) {
      setIsTransparent(true);
      setTimeout(() => {
        setIsDisabled(true);
      }, 50);
    } else {
      setIsDisabled(false);
      setTimeout(() => {
        setIsTransparent(false);
      }, 50);
    }
    // eslint-disable-next-line
  }, [chosenElementId]);

  return userBikes ? (
    <div className={styles.routesWrapper}>
      <DesktopViewWithHeader>
        <div className={styles.garage}>
          <Helmet>
            <title>{t("garagePageTitle")}</title>
          </Helmet>
          {!isDesktop && (
            <>
              <div className={`dotSlider ${styles.dotSlider}`}>
                <Slider
                  infinite={false}
                  arrows={false}
                  slidesToShow={6}
                  dotsClass={styles.dotContainer}
                >
                  {userBikes?.map((bike, index) => (
                    <div
                      className={`${styles.dot} ${styles[getDotSize(index)]}`}
                      key={bike.id}
                    ></div>
                  ))}
                  <div
                    className={`${styles.dot} ${
                      styles[getDotSize(userBikes?.length)]
                    }`}
                  ></div>
                </Slider>
              </div>
              {!!userBikes?.length ? (
                <>
                  <Slider
                    centerPadding="60px"
                    slidesToShow={1}
                    swipeToSlide
                    infinite={false}
                    arrows={false}
                    beforeChange={(oldId, newId) => {
                      setChosenElementId(newId);
                    }}
                  >
                    {userBikes?.map((bike, index) => (
                      <Bike
                        transitionFactor={getTransitionFactor(
                          index,
                          userBikes.length === index + 1
                        )}
                        bike={bike}
                        key={bike.id}
                        isBikeActivated={getIsParsedBikeActivated(bike)}
                      />
                    ))}
                    <AddBikeElem
                      shouldTransition={chosenElementId < userBikes?.length}
                    />
                  </Slider>
                  <div
                    className={`${styles.bikeActionsSectionMobile} ${
                      isTransparent ? styles.opacity0 : ""
                    } ${isDisabled ? styles.disabled : ""}`}
                  >
                    {getIsParsedBikeActivated(userBikes[chosenElementId]) && (
                      <ActionsPanel bike={userBikes[chosenElementId]} />
                    )}
                    <StatusesPanel
                      bikeData={userBikes[chosenElementId]}
                      numberOfInspections={checkBookList.length}
                      isBikeActivated={getIsParsedBikeActivated(
                        userBikes[chosenElementId]
                      )}
                    />
                    <SocialPanel />
                  </div>
                </>
              ) : (
                <AddBikeElemWhenEmpty />
              )}
            </>
          )}
          {isDesktop &&
            (!!userBikes?.length ? (
              <>
                <div className={styles.desktopSlider}>
                  <div className={styles.sliderWrapper}>
                    {userBikes?.map((bike, index) => (
                      <Bike
                        bike={bike}
                        key={bike.id}
                        isChosen={chosenElementId === index}
                        setIsChosen={() => {
                          setChosenElementId(index);
                        }}
                        isBikeActivated={getIsParsedBikeActivated(bike)}
                      />
                    ))}
                    <AddBikeElem noText />
                  </div>
                </div>
                {getIsParsedBikeActivated(userBikes[chosenElementId]) && (
                  <ActionsPanel bike={userBikes[chosenElementId]} />
                )}
                <StatusesPanel
                  bikeData={userBikes[chosenElementId]}
                  numberOfInspections={checkBookList.length}
                  isBikeActivated={getIsParsedBikeActivated(
                    userBikes[chosenElementId]
                  )}
                />
                <SocialPanel />
              </>
            ) : (
              <AddBikeElemWhenEmpty />
            ))}
        </div>
      </DesktopViewWithHeader>
    </div>
  ) : (
    <h3 className={styles.loading}>Loading...</h3>
  );
};

export default Garage;
