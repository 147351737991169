import Navigation from "../../components/Navigation/Navigation";
import { useWindowWidth } from "../../hooks/useWindowWidth";

import styles from "./DesktopViewWithHeader.module.css";

export default function DesktopViewWithHeader({
  children,
  footer,
  isDisabled,
}) {
  const isDesktop = useWindowWidth() > 1280;

  return (
    <div className={styles.desktop}>
      {!isDisabled && <Navigation />}
      <div className={styles.page}>
        <div className={styles.wrapper}>{children}</div>
      </div>
      {isDesktop && footer && (
        <div className={styles.footer}>
          <div className={styles.footerContainer}>{footer}</div>
        </div>
      )}
    </div>
  );
}
