import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useMutation, useLazyQuery } from "@apollo/client";

import DesktopView from "../../../components/DesktopView";
import FormLabel from "../../../components/FormLabel/FormLabel";
import { getBike } from "../../../redux/bike/bikeSelectors";
import bikeActions from "../../../redux/bike/bikeActions";
import ButtonWithoutProgressBar from "../../../components/ButtonWithoutProgressBar/ButtonWithoutProgressBar";
import InsuranceInterface from "../components/InsuranceInterface";
import { INSURANCE_PROFILE } from "../../../constants/routes";
import Tooltip from "../../../components/Tooltip";
import { UPDATE_BIKE } from "../../../services/mutation";
import { GET_USER } from "../../../services/query";
import { getUserId } from "../../../redux/auth/authSelectors";
import authActions from "../../../redux/auth/authActions";

import styles from "./InsuranceBikeDetails.module.css";

const InsuranceBikeDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const focusedBikeData = useSelector(getBike);
  const { getCurrentUserSuccess } = authActions;
  const [updateBike] = useMutation(UPDATE_BIKE);
  const [getUserQuery] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });

  const onDateChange = (date, e) => {
    e.preventDefault();
    dispatch(
      bikeActions.addFocusedBikeData({
        boughtAt: { value: date, error: "" },
      })
    );
  };

  const onFrameNumberChange = (e) => {
    e.preventDefault();
    dispatch(
      bikeActions.addFocusedBikeData({
        frameNumber: { value: e.target.value, error: "" },
      })
    );
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      validation();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const validation = async () => {
    if (!focusedBikeData?.boughtAt.value) {
      dispatch(
        bikeActions.addFocusedBikeData({
          boughtAt: {
            value: "",
            error: t("onboarding.insuranceBikeDetails.requiredPurchaseValue"),
          },
        })
      );
    } else if (!focusedBikeData?.frameNumber.value) {
      dispatch(
        bikeActions.addFocusedBikeData({
          frameNumber: {
            value: "",
            error: t("onboarding.insuranceBikeDetails.requiredBikeNumberValue"),
          },
        })
      );
    } else {
      dispatch(
        bikeActions.addStepInsurance({
          firstStepComplete: true,
        })
      );
      await updateBike({
        variables: {
          id: focusedBikeData?.id,
          bike: {
            data: JSON.stringify({
              ...JSON.parse(focusedBikeData.bikeData),
              boughtAt: focusedBikeData.boughtAt.value,
            }),
            frameNumber: focusedBikeData.frameNumber.value,
            level: "FULL_FILLED",
          },
        },
      })
        .then(() => {
          getUserQuery({
            variables: { id: userId },
          }).then((res) => {
            dispatch(getCurrentUserSuccess(res.data.user));
          });
        })
        .catch((err) => {
          throw err;
        });
      navigate(INSURANCE_PROFILE);
    }
  };

  return (
    <DesktopView>
      <div className={styles.pageWrapper}>
        <InsuranceInterface isBackButton isTags page="bike" />
        <form
          noValidate
          className={styles.form}
          onSubmit={onSubmit}
          autoComplete="off"
        >
          <FormLabel className={styles.dateLabel}>
            <div className={styles.labelTitle}>
              {t("onboarding.insuranceBikeDetails.labelDate")}
              <Tooltip
                text={t(`onboarding.insuranceBikeDetails.tooltipDate`)}
                direction="right"
                className={styles.tooltip}
              />
            </div>
            <DatePicker
              selected={
                focusedBikeData?.boughtAt?.value &&
                new Date(focusedBikeData?.boughtAt?.value)
              }
              onChange={onDateChange}
              className={styles.inputPicker}
              dateFormat="dd.MM.yyyy"
              placeholderText={t(
                "onboarding.insuranceBikeDetails.datePlaceholder"
              )}
              withPortal
              maxDate={new Date()}
              minDate={new Date().setDate(new Date().getDate() - 1825)}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onFocus={(e) => e.target.blur()}
            />
          </FormLabel>
          {focusedBikeData?.boughtAt?.error && (
            <p className={styles.errorMessage}>
              {focusedBikeData?.boughtAt?.error}
            </p>
          )}
          <FormLabel className={styles.label}>
            <div className={styles.labelTitle}>
              {t("onboarding.insuranceBikeDetails.bikeFrameNumber")}
              <Tooltip
                text={t(`onboarding.insuranceBikeDetails.tooltipFrameNumber`)}
                direction="right"
                className={styles.tooltip}
              />
            </div>
            <input
              className={styles.input}
              type="text"
              value={focusedBikeData?.frameNumber?.value}
              onChange={onFrameNumberChange}
              onKeyDown={onKeyDown}
            />
            {focusedBikeData?.frameNumber?.error && (
              <p className={styles.errorMessage}>
                {focusedBikeData?.frameNumber?.error}
              </p>
            )}
          </FormLabel>
        </form>
        <ButtonWithoutProgressBar
          type="button"
          value={t("onboarding.insuranceBikeDetails.continue")}
          onClick={validation}
        />
      </div>
    </DesktopView>
  );
};

export default InsuranceBikeDetails;
