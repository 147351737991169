import styles from "./DescriptionFeature.module.css";

const DescriptionFeature = ({ title, text }) => {
  return (
    <div className={styles.descriptionFeature}>
      <div className={styles.descriptionFeatureTitle}>{title}</div>
      <div className={styles.descriptionFeatureText}>{text}</div>
    </div>
  );
};

export default DescriptionFeature;
