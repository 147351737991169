import styles from "./Switcher.module.css";

const Switcher = ({
  title,
  options,
  chosenOptionId,
  onChange,
  chosenClassName,
}) => {
  return (
    <>
      {title && <div className={styles.subtitle}>{title}</div>}
      <div className={styles.switcher}>
        <div
          className={`${styles.option} ${
            chosenOptionId === options[0].id
              ? `${styles.chosen} ${chosenClassName}`
              : ""
          }`}
          onClick={() => {
            onChange(options[0].id);
          }}
        >
          {options[0].title}
        </div>
        <div
          className={`${styles.option} ${
            chosenOptionId === options[1].id
              ? `${styles.chosen} ${chosenClassName}`
              : ""
          }`}
          onClick={() => {
            onChange(options[1].id);
          }}
        >
          {options[1].title}
        </div>
      </div>
    </>
  );
};

export default Switcher;
