import React, { useEffect, useState } from "react";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DescriptionTitle from "../../../components/DescriptionTitle/DescriptionTitle";
import ButtonWithoutProgressBar from "../../../components/ButtonWithoutProgressBar/ButtonWithoutProgressBar";
import { useSelector, useDispatch } from "react-redux";
import { getInsuranceData } from "../../../redux/insurance/insuranceSelectors";
import {
  getBike,
  getStepInsurance,
  getStepOnboarding,
} from "../../../redux/bike/bikeSelectors";
import { getUserEmail, getUser } from "../../../redux/auth/authSelectors";
import DesktopView from "../../../components/DesktopView";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import { createPolicy } from "../../../services/apiRequest";
import normalizeCost from "../../../assets/helpers/normalizeCost";
import fixItCustomerInformation from "../../../assets/documents/Insurance_infodocs/FixIt/Kundeninformationsblatt.pdf";
import premiumCustomerInformation from "../../../assets/documents/Insurance_infodocs/Premium/Kundeninformationsblatt.pdf";
import fixItInsuranceCondition from "../../../assets/documents/Insurance_infodocs/FixIt/Informationsblatt.pdf";
import premiumInsuranceCondition from "../../../assets/documents/Insurance_infodocs/Premium/Informationsblatt.pdf";
import informationSheetOnDataProcessing from "../../../assets/documents/Insurance_infodocs/Merkblatt zur Datenverarbeitung.pdf";
import rightOfWithdrawal from "../../../assets/documents/Insurance_infodocs/Widerrufsbelehrung.pdf";
import bikeActions from "../../../redux/bike/bikeActions";
import insuranceActions from "../../../redux/insurance/insuranceActions";
import normalizeDateForBE from "../../../assets/helpers/normalizeDateForBE";
import { errorHandlerBE } from "../../../assets/helpers/errorHandlerBE";
import Spinner from "../../../components/Spinner/Spinner";
import { INSURANCE_SUCCESS, WELCOME } from "../../../constants/routes";
import { useMixpanel } from "../../../utils/mixpanel";
import InsuranceInterface from "../components/InsuranceInterface";
import Tooltip from "../../../components/Tooltip";

import styles from "./InsuranceOverview.module.css";

const InsuranceOverview = () => {
  const [showProfile, setShowProfile] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const {
    startDateInsurance,
    selectedInsuranceRate,
    birthDate,
    street,
    houseNumber,
    postalCode,
    city,
    IBAN,
    mobileNumber,
    accountOwner,
    firstName,
    lastName,
  } = useSelector(getInsuranceData);
  const user = useSelector(getUser);
  const [firstCheckbox, setFirstCheckbox] = useState(false);
  const [secondCheckbox, setSecondCheckbox] = useState(false);
  const [thirdCheckbox, setThirdCheckbox] = useState(false);
  const { manufacturer, model, type, isUsed, boughtAt, frameNumber } =
    useSelector(getBike);
  const userEmail = useSelector(getUserEmail);
  const [firstCheckboxError, setFirstCheckboxError] = useState("");
  const [secondCheckboxError, setSecondCheckboxError] = useState("");
  const [thirdCheckboxError, setThirdCheckboxError] = useState("");
  const { t } = useTranslation();
  const [customerInformation, setCustomerInformation] = useState();
  const [insuranceCondition, setInsuranceCondition] = useState();
  const { thirdStepComplete } = useSelector(getStepInsurance);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { firstStepComplete } = useSelector(getStepOnboarding);
  const mixpanel = useMixpanel();

  useEffect(() => {
    !thirdStepComplete && navigate(WELCOME);
  });

  const nextPage = () => {
    toast.dismiss();
    dispatch(
      bikeActions.addStepInsurance({
        fourthStepComplete: true,
      })
    );
    dispatch(bikeActions.resetFocusedBikeData());
    toast.dismiss();
    navigate(INSURANCE_SUCCESS);
  };

  const insuranceDate = new Date(startDateInsurance)
    .toLocaleDateString()
    .split("/")
    .join(".");

  const normalizeBirthDate = new Date(birthDate)
    .toLocaleDateString()
    .split("/")
    .join(".");

  const firstCheckboxChecked = () => {
    setFirstCheckbox(!firstCheckbox);
    setFirstCheckboxError("");
  };
  const secondCheckboxChecked = () => {
    setSecondCheckbox(!secondCheckbox);
    setSecondCheckboxError("");
  };
  const thirdCheckboxChecked = () => {
    setThirdCheckbox(!thirdCheckbox);
    setThirdCheckboxError("");
  };
  const toggleProfile = () => setShowProfile(!showProfile);
  const togglePayment = () => setShowPayment(!showPayment);
  const checkboxesChosen = firstCheckbox && secondCheckbox && thirdCheckbox;

  const nextScreen = async () => {
    if (checkboxesChosen) {
      setLoading(true);
      await createPolicy({
        quote_id: selectedInsuranceRate?.QuoteID,
        start_date: normalizeDateForBE(startDateInsurance),
        bike_manufacturer: manufacturer,
        bike_model: model,
        original_sale_date: firstStepComplete
          ? normalizeDateForBE(boughtAt?.value)
          : boughtAt?.value,
        serial_number: frameNumber?.value,
        is_used: isUsed,
        account_holder: accountOwner,
        IBAN: IBAN,
        bike_id: selectedInsuranceRate?.BikeID,
        bike_type: type,
        first_name: firstName,
        last_name: lastName,
        email: userEmail,
        phone: mobileNumber,
        street_name: street,
        street_number: houseNumber,
        postcode: postalCode,
        city: city,
        country: "Germany",
        date_of_birth: normalizeDateForBE(birthDate),
        variant: selectedInsuranceRate?.Variant,
      }).then((data) => {
        setLoading(false);
        if (data?.status === 400) {
          data.json().then((value) => {
            errorHandlerBE(value, toast, t);
          });
        }
        if (data.status === 200) {
          data.json().then((value) => {
            dispatch(
              insuranceActions.addInsuranceData({
                policy: value,
                voucherValue: "",
                voucherSuccessMessage: "",
                voucherErrorMessage: "",
              })
            );
          });
          mixpanel.track("onboarding_signup_completed", {
            user_id: user.id,
            user_selected_insurance: true,
            platform: "frontend",
          });
          mixpanel.people.set({
            user_has_bikes_amount: user.bikes?.length || 0,
          });
          mixpanel.people.set({ user_language: user.locale });
          mixpanel.people.set({ user_has_insurance: true });
          nextPage();
        }
      });
    } else {
      if (!firstCheckbox) {
        setFirstCheckboxError(t("onboarding.legacy.firstCheckboxError"));
      }
      if (!secondCheckbox) {
        setSecondCheckboxError(t("onboarding.legacy.secondCheckboxError"));
      }
      if (!thirdCheckbox) {
        setThirdCheckboxError(t("onboarding.legacy.thirdCheckboxError"));
      }
    }
  };
  useEffect(() => {
    switch (selectedInsuranceRate?.Variant) {
      case "Fix It":
        setCustomerInformation(fixItCustomerInformation);
        setInsuranceCondition(fixItInsuranceCondition);
        break;
      case "Premium":
        setCustomerInformation(premiumCustomerInformation);
        setInsuranceCondition(premiumInsuranceCondition);
        break;

      default:
        break;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DesktopView>
      <Helmet>
        <title>{t("insuranceSummaryPageTitle")}</title>
        <meta name="theme-color" content="#F3F3F3" />
      </Helmet>
      <InsuranceInterface page="overview" />
      <div className={styles.pageWrapper}>
        <div className={styles.wrapper}>
          <DescriptionTitle
            className={styles.descriptionTitle}
            value={t(t("onboarding.legacy.summaryTitle"))}
          />
          <span className={styles.offerText}>
            {t("onboarding.legacy.cancellableDaily")}
            <Tooltip
              text={t("onboarding.legacy.cancellableDailyHint")}
              className={styles.tooltip}
            />
          </span>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <p className={styles.text}>{`${manufacturer} ${model}`}</p>
            </li>
            <li className={styles.listItem}>
              <p className={styles.text}>{t("onboarding.legacy.startDate")}:</p>
              <p className={styles.text}>{insuranceDate}</p>
            </li>
            <li className={styles.listItem}>
              <p className={styles.text}>{t("onboarding.legacy.package")}:</p>
              <p className={styles.text}>{selectedInsuranceRate?.Variant}</p>
            </li>
          </ul>
          <div className={styles.priceSection}>
            {selectedInsuranceRate?.oldPaymentInCents && (
              <>
                <li className={styles.listItem}>
                  <p className={styles.text}>
                    {t("onboarding.legacy.originalFee")}:
                  </p>
                  <p className={styles.text}>
                    {normalizeCost(selectedInsuranceRate?.oldPaymentInCents)} €
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.text}>
                    {t("onboarding.legacy.discountFee")}:
                  </p>
                  <p className={styles.text}>
                    {normalizeCost(
                      +selectedInsuranceRate?.PaymentInCents -
                        selectedInsuranceRate?.oldPaymentInCents
                    )}{" "}
                    €
                  </p>
                </li>
              </>
            )}
            <li className={`${styles.listItem} ${styles.boldListItem}`}>
              <p className={styles.text}>{t("onboarding.legacy.yourFee")}:</p>
              <p className={styles.text}>
                {normalizeCost(selectedInsuranceRate?.PaymentInCents)} €
              </p>
            </li>
          </div>
        </div>
        <div className={styles.wrapperCard}>
          <div onClick={toggleProfile} className={styles.wrapperSpan}>
            <span className={styles.spanText}>
              {t("onboarding.legacy.yourProfile")}
            </span>
            <span
              className={
                showProfile ? styles.iconChevronDown : styles.iconChevronUp
              }
            ></span>
          </div>

          {showProfile && (
            <div>
              <div className={styles.wrapperDiv}>
                <div className={styles.wrapperList}>
                  <ul className={styles.list}>
                    <li className={styles.item}>
                      <p className={styles.itemDescription}>
                        {t("onboarding.legacy.firstName")}
                      </p>
                    </li>
                    <li className={styles.item}>
                      <p className={styles.itemDescriptionText}>{firstName}</p>
                    </li>
                  </ul>
                </div>
                <div className={styles.wrapperList}>
                  <ul className={styles.list}>
                    <li className={styles.item}>
                      <p className={styles.itemDescription}>
                        {t("onboarding.legacy.lastName")}
                      </p>
                    </li>
                    <li className={styles.item}>
                      <p className={styles.itemDescriptionText}>{lastName}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <p className={styles.itemDescription}>
                {t("onboarding.legacy.birthday")}
              </p>
              <p className={styles.itemDescriptionText}>{normalizeBirthDate}</p>
              <p className={styles.itemDescription}>
                {t("onboarding.legacy.streetandnumber")}
              </p>
              <p className={styles.itemDescriptionText}>
                {street + " " + houseNumber}
              </p>

              <div className={styles.wrapperBottom}>
                <div className={styles.wrapperList}>
                  <ul className={styles.list}>
                    <li className={styles.itemLi}>
                      <p className={styles.itemDescription}>
                        {t("onboarding.legacy.postalCode")}
                      </p>
                    </li>
                    <li className={styles.itemLi}>
                      <p className={styles.itemDescriptionText}>{postalCode}</p>
                    </li>
                  </ul>
                </div>
                <div className={styles.wrapperList}>
                  <ul className={styles.list}>
                    <li className={styles.item}>
                      <p className={styles.itemDescription}>
                        {t("onboarding.legacy.city")}
                      </p>
                    </li>
                    <li className={styles.item}>
                      <p className={styles.itemDescriptionText}>{city}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.wrapperCard}>
          <div onClick={togglePayment} className={styles.wrapperSpan}>
            <span className={styles.spanText}>
              {t("onboarding.legacy.yourPayment")}
            </span>
            <span
              className={
                showPayment ? styles.iconChevronDown : styles.iconChevronUp
              }
            ></span>
          </div>
          {showPayment && (
            <div className={styles.ibanWrapper}>
              <p className={styles.itemDescription}>IBAN</p>
              <p className={styles.itemDescriptionText}>{IBAN}</p>
            </div>
          )}
        </div>
        <CustomCheckbox
          type="checkbox"
          value="Ich habe die Informationsdokumente heruntergeladen und gelesen."
          id="1"
          onClick={firstCheckboxChecked}
          error={firstCheckboxError}
        />
        {firstCheckboxError && (
          <p className={styles.errorMessage}>{firstCheckboxError}</p>
        )}
        <a className={styles.downloadLink} href={customerInformation} download>
          <span className={styles.span}>
            {t("onboarding.legacy.customerInformationSheet")}
          </span>
          <span className={styles.iconDownload}></span>
        </a>
        <a className={styles.downloadLink} href={insuranceCondition} download>
          <span className={styles.span}>
            {t("onboarding.legacy.insuranceConditions")}
          </span>
          <span className={styles.iconDownload}></span>
        </a>
        <a
          className={styles.downloadLink}
          href={informationSheetOnDataProcessing}
          download
        >
          <span className={styles.span}>
            {t("onboarding.legacy.InformationSheetOnDataProcessing")}
          </span>
          <span className={styles.iconDownload}></span>
        </a>
        <a className={styles.downloadLink} href={rightOfWithdrawal} download>
          <span className={styles.span}>
            {t("onboarding.legacy.rightOfWithdrawal")}
          </span>
          <span className={styles.iconDownload}></span>
        </a>
        <div className={styles.secondCheckbox}>
          <CustomCheckbox
            type="checkbox"
            value="Ich bestätige die vollständige und richtige
          Beantwortung der Fragen zu allen risikoerheblichen
           Umständen und beantrage den Abschluss der Versicherung
           auf Basis der von mir eingegeben Daten."
            id="2"
            onClick={secondCheckboxChecked}
            error={secondCheckboxError}
          />
          {secondCheckboxError && (
            <p className={styles.errorMessage}>{secondCheckboxError}</p>
          )}
        </div>
        <div>
          <CustomCheckbox
            type="checkbox"
            value="Ich bin damit einverstanden, dass mit mir per
             unverschlüsselter E-Mail hinsichtlich meines
             Versicherungsvertrages – z.B. im
             Schadensfall - kommuniziert wird."
            id="3"
            onClick={thirdCheckboxChecked}
            error={thirdCheckboxError}
          />
          {thirdCheckboxError && (
            <p className={styles.errorMessage}>{thirdCheckboxError}</p>
          )}
        </div>
      </div>
      <ButtonWithoutProgressBar
        value="Kostenpflichtig abschließen"
        onClick={nextScreen}
      />
      {loading && <Spinner color="#02f596" />}
      <Toaster
        position="top-center"
        toastOptions={{
          className: styles.toast,
          duration: Infinity,
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ message }) => (
              <>
                {t.type !== "loading" && (
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className={styles.toastButton}
                  ></button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </DesktopView>
  );
};
export default InsuranceOverview;
