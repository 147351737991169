import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useLazyQuery } from "@apollo/client";

import DesktopView from "../../../components/DesktopView";
import CashInput from "../../../components/Inputs/Cash";
import Label from "../../../components/Label/Label";
import BikeInfo from "../../../components/BikeInfo/BikeInfo";
import authActions from "../../../redux/auth/authActions";
import bikeActions from "../../../redux/bike/bikeActions";
// import { INSURANCE_CHOOSE } from "../../../constants/routes";
import { GARAGE } from "../../../constants/routes";
import { getBike } from "../../../redux/bike/bikeSelectors";
import { getUserId } from "../../../redux/auth/authSelectors";
import insuranceActions from "../../../redux/insurance/insuranceActions";
import { getInsurancePrice } from "../../../services/apiRequest";
import Tooltip from "../../../components/Tooltip";
import { UPDATE_BIKE } from "../../../services/mutation";
import { GET_USER } from "../../../services/query";

import styles from "./Price.module.css";

const Price = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [price, setPrice] = useState("");
  const [error, setError] = useState("");
  const focusedBikeData = useSelector(getBike);
  const userId = useSelector(getUserId);
  const { getCurrentUserSuccess } = authActions;
  const { addFocusedBikeData, addStepOnboarding } = bikeActions;
  const { addInsuranceData } = insuranceActions;
  const [updateBike] = useMutation(UPDATE_BIKE);
  const [getUserQuery] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });

  const onBack = () => {
    navigate(-1);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmitClick();
    }
  };

  const onSubmitClick = async () => {
    if (!price) {
      setError(t(`onboarding.price.emptyError`));
    } else if (price < 100) {
      setError(t(`onboarding.price.lowPriceError`));
    } else if (price > 12000) {
      setError(t(`onboarding.price.highPriceError`));
    } else {
      setError("");
      dispatch(
        addFocusedBikeData({
          price: { value: price, error: "" },
        })
      );
      const priceDivisionalLength = price.split(",")?.[1]?.length;
      const priceInCents =
        price?.split(".")?.join("")?.split(",")?.join("") *
        (priceDivisionalLength ? (priceDivisionalLength === 1 ? 10 : 1) : 100);
      const insuranceRate = await getInsurancePrice(
        priceInCents,
        focusedBikeData?.type,
        focusedBikeData?.id
      );
      dispatch(addInsuranceData({ insuranceRate }));
      dispatch(
        addStepOnboarding({
          firstStepComplete: true,
          secondStepComplete: true,
        })
      );
      await updateBike({
        variables: {
          id: focusedBikeData?.id,
          bike: {
            data: JSON.stringify({
              ...JSON.parse(focusedBikeData.bikeData),
              price,
            }),
            level: "FULL_FILLED",
          },
        },
      })
        .then(() => {
          getUserQuery({
            variables: { id: userId },
          }).then((res) => {
            dispatch(getCurrentUserSuccess(res.data.user));
          });
        })
        .catch((err) => {
          throw err;
        });
      navigate(GARAGE);
    }
  };

  const onSkipPrice = async () => {
    dispatch(
      addFocusedBikeData({
        price: { value: "", error: "" },
      })
    );
    dispatch(addInsuranceData({ insuranceRate: [] }));
    dispatch(
      addStepOnboarding({
        firstStepComplete: true,
        secondStepComplete: true,
      })
    );
    navigate(GARAGE);
  };

  return (
    <DesktopView>
      <Helmet>
        <title>{t("createAccountPageTitle")}</title>
        <meta name="theme-color" content="#F3F3F3" />
      </Helmet>
      <div className={styles.pricePage}>
        <div className={styles.header}>
          <div className={styles.backButton} onClick={onBack}></div>
          <div className={styles.pageTitle}>
            {t(`onboarding.price.pageTitle`)}
          </div>
        </div>
        <BikeInfo />
        <Label
          text={
            <>
              {t(`onboarding.price.initialPurchasePrice`)}
              <Tooltip
                text={t(`onboarding.price.tooltipInfo`)}
                direction="right"
                className={styles.tooltip}
              />
            </>
          }
          className={styles.label}
        />
        <div className={styles.mailInputContainer}>
          <CashInput
            value={price}
            onChange={setPrice}
            className={styles.textInput}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={styles.error}>{error}</div>
        <div className={styles.skipPrice} onClick={onSkipPrice}>
          {t(`onboarding.price.skipPrice`)}
        </div>
        <div className={styles.description}>
          {t(`onboarding.price.description`)}
        </div>
        <div className={styles.footerFiller} />
        <div className={styles.footer}>
          <div className={styles.submitButton} onClick={onSubmitClick}>
            <div className={styles.submitButtonText}>
              {t(`onboarding.price.confirmInput`)}
            </div>
            <div className={styles.submitButtonIcon} />
          </div>
        </div>
      </div>
    </DesktopView>
  );
};

export default Price;
