export const loadState = (reducerName) => {
  try {
    const seriazedState = sessionStorage.getItem(reducerName);
    if(!!seriazedState) {
      return JSON.parse(seriazedState);
    }
    return {};
  } catch {
    return undefined;
  }
}

export const saveState = (state, reducerNames) => {
  reducerNames.forEach(reducerName => {
    sessionStorage.setItem(reducerName, JSON.stringify(state[reducerName]));
  });
}