import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useLocation } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import FormLabel from "../../components/FormLabel/FormLabel";
import MainTitle from "../../components/MainTitle/MainTitle";
import ButtonWithoutProgressBar from "../../components/ButtonWithoutProgressBar/ButtonWithoutProgressBar";
import Header from "../../components/Header";
import DesktopView from "../../components/DesktopView";
import { LOGIN } from "../../services/mutation";
import authActions from "../../redux/auth/authActions";
import { setRegisterStatus } from "../../services/api";
import { GARAGE, WELCOME, LOGIN as LOGIN_ROUTE } from "../../constants/routes";
import { useMixpanel } from "../../utils/mixpanel";
import Cookies from "js-cookie";

import styles from "./Login.module.css";

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectLink = location.state?.from;
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState("");
  const [loginUser] = useMutation(LOGIN);
  const { signInSuccess, signOutSuccess } = authActions;
  const mixpanel = useMixpanel();
  const commercetoolsCookie = Cookies.get("CTAT");
  const linkOrigin =
    window.location.origin === "https://my.velio.de"
      ? "https://velio.de/login/forgot"
      : "https://circular.velio.dev/login/forgot";

  useEffect(() => {
    if (commercetoolsCookie) {
      dispatch(signOutSuccess());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const schema = yup.object().shape({
    email: yup.string().email().required(t("auth.emailRequired")),
    password: yup.string().required(t("auth.passwordRequired")),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    await loginUser({
      variables: {
        credentials: {
          email: data.email,
          password: data.password,
        },
      },
    })
      .then((response) => {
        const data = response.data.login;
        setLoginError("");
        setRegisterStatus(true);
        mixpanel.identify(data.user.id);
        mixpanel.alias(data.user.id);
        dispatch(signInSuccess({ user: data.user }));
        if (redirectLink && redirectLink !== LOGIN_ROUTE) {
          navigate(redirectLink);
        } else {
          navigate(GARAGE);
        }
      })
      .catch((err) => {
        setLoginError(t("auth.loginErrorMessage"));
        throw err;
      });
  };

  return (
    <>
      <Helmet>
        <title>{t("loginPageTitle")}</title>
        <meta
          name="description"
          content="Logge dich bei velio ein, um dein Bike zu entdecken."
        />
        <meta name="theme-color" content="#2E3840" />
      </Helmet>
      <DesktopView>
        <Header />
        <div className={styles.contentWrapper}>
          <MainTitle className={styles.title} value={t("auth.login")} />
          <form
            noValidate
            className={styles.form}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <FormLabel className={styles.label}>
              {t("auth.emailAddress")}
              <input
                className={styles.input}
                type="email"
                name="email"
                id="email"
                placeholder={t("auth.emailAddressPlaceholder")}
                {...register("email")}
              />
            </FormLabel>
            {errors?.email && (
              <p className={styles.errorMessage}>{errors.email.message}</p>
            )}
            <FormLabel className={styles.label}>
              {t("auth.password")}
              <input
                className={styles.input}
                type="password"
                name="password"
                id="password"
                placeholder={t("auth.passwordPlaceholder")}
                {...register("password")}
              />
            </FormLabel>
            {errors?.password && (
              <p className={styles.errorMessage}>{errors.password.message}</p>
            )}
            {loginError && <p className={styles.errorMessage}>{loginError}</p>}
            <a href={linkOrigin} className={styles.forgotPassword}>
              {t("auth.forgotPassword")}
            </a>
            <p className={styles.register}>
              {t("auth.noAccount")}{" "}
              <span>
                <Link to={WELCOME} className={styles.highlight}>
                  {t("auth.register")}
                </Link>
              </span>
            </p>
            <ButtonWithoutProgressBar value={t("auth.login")} type="submit" />
          </form>
        </div>
      </DesktopView>
    </>
  );
}
