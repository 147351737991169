import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { Helmet } from "react-helmet";

import { getInsuranceData } from "../../../redux/insurance/insuranceSelectors";
import { getUser } from "../../../redux/auth/authSelectors";
import DescriptionTitle from "../../../components/DescriptionTitle/DescriptionTitle";
import LinkWithArrow from "../../../components/LinkWithArrow/LinkWithArrow";
import DesktopView from "../../../components/DesktopView";
import normalizeCost from "../../../assets/helpers/normalizeCost";
import { getStepInsurance } from "../../../redux/bike/bikeSelectors";
import authActions from "../../../redux/auth/authActions";
import { GET_USER } from "../../../services/query";
import { GARAGE, WELCOME } from "../../../constants/routes";
import InsuranceInterface from "../components/InsuranceInterface";

import styles from "./InsuranceSuccess.module.css";

const InsuranceSuccess = () => {
  const { t } = useTranslation();
  const { startDateInsurance, selectedInsuranceRate, policy } =
    useSelector(getInsuranceData);
  const { fourthStepComplete } = useSelector(getStepInsurance);
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    !fourthStepComplete && navigate(WELCOME);
  });

  const insuranceDate = new Date(startDateInsurance)
    .toLocaleDateString()
    .split("/")
    .join(".");

  const [getUserQuery] = useLazyQuery(GET_USER, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getUserQuery({
      variables: { id: user?.id },
    }).then((res) => {
      const { getCurrentUserSuccess } = authActions;
      dispatch(getCurrentUserSuccess(res.data.user));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DesktopView>
      <Helmet>
        <title>{t("insuranceFinalPageTitle")}</title>
        <meta name="theme-color" content="#F3F3F3" />
      </Helmet>
      <div className={styles.pageWrapper}>
        <InsuranceInterface />
        <div className={styles.wrapper}>
          <span className={styles.checkCircle}></span>
        </div>
        <DescriptionTitle
          className={styles.descriptionTitle}
          value={t("onboarding.legacy.insuranceSuccess")}
        />
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <div className={styles.wrapperText}>
              <p className={styles.itemTitle}>{t("onboarding.legacy.name")}</p>
              <p className={styles.item}>{selectedInsuranceRate?.Variant}</p>
            </div>
          </li>
          <li className={styles.listItem}>
            <div className={styles.wrapperText}>
              <p className={styles.itemTitle}>{t("onboarding.legacy.price")}</p>
              <p className={styles.item}>
                {normalizeCost(selectedInsuranceRate?.PaymentInCents)} €/
                {t("onboarding.legacy.month")}
              </p>
            </div>
          </li>
          <li className={styles.listItem}>
            <div className={styles.wrapperText}>
              <p className={styles.itemTitle}>
                {t("onboarding.legacy.startDate")}
              </p>
              <p className={styles.item}>{insuranceDate}</p>
            </div>
          </li>
        </ul>
        <a
          className={styles.downloadLink}
          href={policy?.document_url}
          target="_blank"
          rel="noreferrer"
        >
          <span className={styles.span}>
            {t("onboarding.legacy.downloadContract")}
          </span>
          <span className={styles.iconDownload}></span>
        </a>
        <div className={styles.minorDescription}>
          {t("onboarding.legacy.successInsuranceDescription")}
        </div>
      </div>
      <LinkWithArrow value={t("onboarding.legacy.discoverBike")} to={GARAGE} />
    </DesktopView>
  );
};
export default InsuranceSuccess;
