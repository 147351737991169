import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";

import CollapsibleSection from "../../components/CollapsibleSection";
import InsuranceStatus from "../../components/InsuranceStatus";
// import BasicButton from "../../components/Buttons/BasicButton";
import DesktopViewWithHeader from "../../components/DesktopViewWithHeader";
// import insuranceActions from "../../redux/insurance/insuranceActions";
// import bikeActions from "../../redux/bike/bikeActions";
import { getUser } from "../../redux/auth/authSelectors";
// import { getInsurancePrice } from "../../services/apiRequest";
import { GET_INSURANCE_DOCUMENTS } from "../../services/query";
// import { INSURANCE_CHOOSE, WELCOME } from "../../constants/routes";

import styles from "./Insurance.module.css";

const Insurance = () => {
  const user = useSelector(getUser) || {};
  const { t } = useTranslation();
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { addInsuranceData } = insuranceActions;
  // const { addFocusedBikeData, addStepOnboarding } = bikeActions;
  const userBikes = user?.bikes?.map((bike) => ({
    ...bike,
    data: { ...JSON.parse(bike.data) },
  }));
  const [getInsuranceDocuments] = useLazyQuery(GET_INSURANCE_DOCUMENTS);

  const onDocumentsLinkClick = async (id) => {
    const res = await getInsuranceDocuments({ variables: { bike_id: id } });
    window.open(res.data.insuranceDocuments[0].docurl, "_blank");
  };

  // const onInsure = async (bike) => {
  //   dispatch(
  //     addFocusedBikeData({
  //       id: bike.id,
  //       bikeType: bike.data.bike_type,
  //       manufacturer: bike.data.manufacturer,
  //       category: bike.data.category,
  //       model: bike.data.model_name,
  //       description: bike.data.bike_description,
  //       image: bike.coverImage.webReadUrl,
  //       weight: bike.data?.bike_weight,
  //       color: bike.data.color,
  //       gender: bike.data.gender,
  //       price: { value: bike.data.price?.toString(), error: "" },
  //       dealer: bike.dealer,
  //       year: bike.data.year,
  //       type: bike.data.bike_type,
  //       boughtAt: { value: bike.data.boughtAt, error: "" },
  //       isUsed: bike.data.is_used,
  //     })
  //   );
  //   dispatch(
  //     addStepOnboarding({
  //       secondStepComplete: true,
  //     })
  //   );
  //   let insuranceRate = [];
  //   if (bike.data.price) {
  //     insuranceRate = await getInsurancePrice(
  //       bike.data.price,
  //       bike.data.bike_type,
  //       bike.id
  //     );
  //   }
  //   dispatch(addInsuranceData({ insuranceRate }));
  //   navigate(INSURANCE_CHOOSE);
  // };

  return (
    <DesktopViewWithHeader>
      <Helmet>
        <title>Insurance</title>
        <meta name="description" content="Insurance. Velio | Insurance." />
      </Helmet>
      <div className={styles.insuranceContent}>
        <div className={styles.title}>{t("insurance.pageTitle")}</div>
        {userBikes
          ?.filter((bike) => !!bike?.insurance)
          .map((bike) => {
            return (
              <CollapsibleSection className={styles.section} key={bike?.id}>
                <div className={styles.sectionHeader}>
                  <img
                    className={styles.bikeImage}
                    src={bike?.coverImage?.webReadUrl}
                    alt={bike?.extendedModel || "bike"}
                  />
                  <div className={styles.bikeDescription}>
                    <div className={styles.manufacturer}>
                      {bike?.data.manufacturer}
                    </div>
                    <div className={styles.model}>{bike?.data.model_name}</div>
                  </div>
                  <InsuranceStatus isInsured={!!bike?.insurance} />
                </div>
                <div className={styles.actions}>
                  <>
                    <div className={styles.package}>
                      <div className={styles.packageTitle}>
                        {t("insurance.packageTitle")}
                      </div>
                      <div className={styles.packageText}>
                        {`${bike?.insurance.provider} ${bike?.insurance.policyName}`}
                      </div>
                    </div>
                    <a
                      className={styles.insuranceLink}
                      href="https://velio.ihr-versicherungsschutz.de/login"
                      alt=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("insurance.claimDamage")}
                    </a>
                    <div
                      className={styles.insuranceLink}
                      href="https://velio.ihr-versicherungsschutz.de/login"
                      alt=""
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        onDocumentsLinkClick(bike?.id);
                      }}
                    >
                      {t("insurance.viewContract")}
                    </div>
                    <div
                      className={`${styles.insuranceLink} ${styles.insuranceMailLink}`}
                      onClick={(e) => {
                        window.location.href = "mailto: hallo@velio.de";
                        e.preventDefault();
                      }}
                    >
                      {t("insurance.cancelContract")}
                    </div>
                  </>
                </div>
              </CollapsibleSection>
            );
          })}
        {/* <BasicButton
          text={t("insurance.insureNewBike")}
          onClick={() => navigate(WELCOME)}
          buttonClassName={styles.blueButton}
          textClassName={styles.blueButtonText}
          iconClassName={styles.blueButtonIcon}
        /> */}
      </div>
    </DesktopViewWithHeader>
  );
};

export default Insurance;
